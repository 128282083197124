import {
  calculatePartialDateDiffenceDuration,
  isPartialDate,
  partialDateFromDate,
  partialDateToValue,
  sortPartialDate,
} from 'neuro-utils';
import { LoadableDocConfig } from '../generalSettings';

export const ninmtDocumentTypes: LoadableDocConfig = [
  {
    name: 'diagnosis',
    subTypes: [
      'diagnosis',
      'ninmtTreatmentPeriod',
      'indicationForTreatment',
      'contraIndicationsToTreatment',
      'symptomsDetails',
      'symptomsAndLocations',
      'referringPhysicianAndUnit',
    ],
    title: 'basicTreatmentInformation',
  },
  {
    name: 'doctorsOrders',
    subTypes: ['doctorsOrders', 'doctorsOrdersAdditional'],
    secondaryDocs: ['ninmtTreatmentPeriod'],
  },
  {
    name: 'notes',
    subTypes: ['notes'],
    secondaryDocs: ['rtms', 'tdcs', 'doctorsOrders', 'ninmtTreatmentPeriod', 'doctorsOrdersAdditional'],
  },
  { name: 'interviewSurveys', subTypes: ['madrs', 'gaf'] },
  { name: 'medication', subTypes: ['medication', 'adverseEffect'] },
  {
    name: 'rtms',
    subTypes: ['rtms'],
    secondaryDocs: ['doctorsOrders', 'ninmtTreatmentPeriod', 'contraIndicationsToTreatment', 'symptomsAndLocations'],
  },
  {
    name: 'tdcs',
    subTypes: ['tdcs'],
    secondaryDocs: ['doctorsOrders', 'ninmtTreatmentPeriod', 'contraIndicationsToTreatment', 'symptomsAndLocations'],
  },
  { name: 'comorbidity', subTypes: ['comorbidity'], title: 'diagnoses' },
  { name: 'background', subTypes: ['background', 'measurement'] },
  {
    name: 'myService',
    title: 'titleShort',
    subTypes: [''],
    secondaryDocs: ['doctorsOrders', 'rtms', 'tdcs', 'symptomsAndLocations', 'ninmtTreatmentPeriod'],
  },
];

export const ninmtGraphDocTypes = [
  'diagnosis',
  'measurement',
  'ninmtTreatmentPeriod',
  'medication',
  'adverseEffect',
  'doctorsOrders',
  'rtms',
  'madrs',
  'gaf',
  'tdcs',
  'symptomsAndLocations',
  'contraIndicationsToTreatment',
  'comorbidity',
];

export const ninmtAlerts = [
  {
    // Notification to the user if the date of the latest treatment decision/period is after the date of the latest doctor's order .
    locale: 'alerts.ninmt.treatmentDecisionDateAfterLatestDoctorsOrderDate',
    severity: 'warning',
    popup: true,
    rule: (docs: document[]): boolean => {
      const treatmentPeriodDocs: ININMTTreatmentPeriod[] = docs.filter((d) => d._type === 'ninmtTreatmentPeriod');
      const latestTreatmentPeriod = treatmentPeriodDocs.sort((a, b) => sortPartialDate(b.date, a.date))[0] || {};
      const latestTreatmentPeriodDate = latestTreatmentPeriod.date;

      const doctorsOrdersDocs: IDoctorsOrder[] = docs.filter((d) => d._type === 'doctorsOrders');
      const latestDoctorsOrder = doctorsOrdersDocs.sort((a, b) => sortPartialDate(b.date, a.date))[0] || {};
      const latestDoctorsOrderDate = latestDoctorsOrder.date;

      if (!isPartialDate(latestTreatmentPeriodDate) || !isPartialDate(latestDoctorsOrderDate)) return false;

      return sortPartialDate(latestTreatmentPeriodDate, latestDoctorsOrderDate) > 0;
    },
  },
  {
    // Notification to the user if 30 days or more have passed since the contraindications were defined.
    locale: 'alerts.ninmt.contraIndicationsMoreThan30DaysPassed',
    severity: 'warning',
    popup: true,
    rule: (docs: document[]): boolean => {
      const contraIndDocs: IContraIndicationToTreatment[] = docs.filter(
        (d) => d._type === 'contraIndicationsToTreatment',
      );
      const latestContraIndication = contraIndDocs.sort((a, b) => sortPartialDate(b.date, a.date))[0] || {};
      const latestContraIndicationDate = latestContraIndication.date;
      if (!isPartialDate(latestContraIndicationDate)) return false;
      const duration = calculatePartialDateDiffenceDuration('days', latestContraIndicationDate);
      return Math.abs(duration.days) >= 30;
    },
  },
  {
    // Notification to the user when patient has filled a new ContraIndicationsInquiry document
    locale: 'alerts.ninmt.contraIndicationsInquiry',
    severity: 'warning',
    popup: true,
    rule: (docs: document[], myAppDocs: document[]): boolean => {
      const contraIndDocs: IContraIndicationToTreatment[] = docs.filter(
        (d) => d._type === 'contraIndicationsToTreatment',
      );
      const latestContraIndication = contraIndDocs.sort((a, b) => sortPartialDate(b.date, a.date))[0] || {};
      const latestContraIndicationDate = latestContraIndication.date;

      const contraIndInquiries = myAppDocs.filter((md) => md._type === 'contraIndicationsInquiry');
      const sortedContraIndInquiries = contraIndInquiries.sort((a, b) => b._docCreateDate - a._docCreateDate);
      return sortedContraIndInquiries.some(
        (cii) =>
          partialDateToValue(partialDateFromDate(new Date(cii._docCreateDate))) >
          partialDateToValue(latestContraIndicationDate),
      );
    },
  },
];
