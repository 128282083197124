/**
 * Checkbox component that can be used when a single checkbox is needed to toggle between true/false values
 */

import { Checkbox, styled } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import * as React from 'react';

import colors from '../../../config/theme/colors';
import { historyValue, styleDocument } from '../../../config/theme/componentTheme';

const StyledDiv = styled('div')`
  margin-top: -0.9rem; // Offset checkbox default padding
  * > &:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  position: relative;
  left: -1rem;
  margin: 0;
`;

const StyledLabel = styled('div')`
  font-size: ${styleDocument.fontSize};
  color: ${(props: { labelColor?: string }): string => (props.labelColor ? props.labelColor : colors.primaryText)};
`;

const isNotUndefined = (n: any): boolean => (n || n === false ? true : false);

const textElements = (value: string, optionFormatter?: (id: string | number) => JSX.Element | string): JSX.Element => {
  return (
    <StyledDiv>
      <StyledDiv>{isNotUndefined(value) && optionFormatter ? optionFormatter(value) : value ? value : '-'}</StyledDiv>
    </StyledDiv>
  );
};

const onChangeCheckbox =
  (name: string, values: string | number, value: string | number, onChange: IInputBasics['onChange']) =>
  (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      if (e.currentTarget.checked) {
        onChange({ [name]: true });
      } else {
        onChange({ [name]: false });
      }
    }
  };

const checkboxElements = (
  value: string,
  name: string,
  option: string,
  onChange: IInputBasics['onChange'],
  optionFormatter?: (name: string | number) => JSX.Element | string,
  showLabel?: boolean,
  labelColor?: string,
): JSX.Element => {
  const checked = option === value;
  return (
    <StyledDiv>
      <FormControlLabel
        style={{ margin: '0' }}
        control={
          <StyledCheckbox
            checked={checked}
            value={option}
            onChange={onChangeCheckbox(name, value, option, onChange)}
            color="primary"
          />
        }
        label={
          showLabel ? (
            <StyledLabel labelColor={labelColor}>{optionFormatter ? optionFormatter(option) : option}</StyledLabel>
          ) : (
            ''
          )
        }
      />
    </StyledDiv>
  );
};
const CheckboxSingle = ({
  editing = false,
  name,
  onChange,
  value,
  option,
  optionFormatter,
  showLabel = true,
  labelColor,
}: IInputBasics & Required<ICheckboxSingle>): JSX.Element => {
  return !editing ? (
    <div style={historyValue}>{textElements(value, optionFormatter)}</div>
  ) : (
    <div>{checkboxElements(value, name, option, onChange, optionFormatter, showLabel, labelColor)}</div>
  );
};

export default CheckboxSingle;
