export type TFieldConfig = {
  name: string;
  type?:
    | 'Radio'
    | 'Select'
    | 'SelectAndAdd'
    | 'Checkbox'
    | 'CheckboxSingle'
    | 'NumberRange'
    | 'NumberField'
    | 'TextField'
    | 'TextArea'
    | 'PartialDate';
  options?: Array<string | number>;
  optionFormatter?: (o: string | number) => string;
  placeholder?: string;
  limit?: number;
};

export type TLocale = {
  fi: {
    [key: string]: string;
  };
  en: {
    [key: string]: string;
  };
  sv: {
    [key: string]: string;
  };
};

export type TSleep = ININMTPreInquiry['sleep'] | ITreatmentMonitoringInquiry['sleep'];

export const numericScaleFrom0To10 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const ninmtSurveyTypes = [
  'bdi',
  'bai',
  'ninmtPreInquiry',
  'contraIndicationsInquiry',
  'treatmentMonitoringInquiry',
  'tdcsReport',
  'supplyShortagesInquiry',
];
export const srSurveyTypes = [
  'ess',
  'isi',
  'copd',
  'dss',
  'sleepApneaFirstVisitInquiry',
  'deviceTherapyControlVisitInquiry',
  'deps',
  'des',
  'eq5d',
  'satisfactionWithCare',
  'background',
];

export const surveysByPlatform: { [platform in Platform]?: Array<string> } = {
  ninmt: ninmtSurveyTypes,
  sleepApnea: srSurveyTypes,
};
