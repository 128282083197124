import { Checkbox, styled } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import InformationIcon from 'Components/InfoIcon';
import { filter, includes } from 'ramda';
import * as React from 'react';

import colors from '../../../config/theme/colors';
import { historyValue, styleDocument } from '../../../config/theme/componentTheme';

const StyledDiv = styled('div')`
  margin-top: -0.9rem; // Offset checkbox default padding
  * > &:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }
  width: ${(props: { twoColumns?: boolean }): string => (props.twoColumns ? '50%' : 'auto')};
  float: ${(props: { twoColumns?: boolean }): string => (props.twoColumns ? 'left' : 'none')};
`;

const StyledCheckbox = styled(Checkbox)`
  position: relative;
  left: -1rem;
  margin: 0;
`;

const StyledLabel = styled('div')`
  display: inline-flex;
  font-size: ${styleDocument.fontSize};
  color: ${(props: { disabled?: number }): string => (props.disabled ? colors.secondaryText : colors.primaryText)};
`;

const StyledSubElement = styled('div')`
  margin: 0 0 2rem 4.2rem;
`;

const isNotUndefined = (n: any): boolean => (n || n === false ? true : false);

const textElements = (
  values: Array<string | number>,
  optionFormatter?: (id: string | number) => JSX.Element | string,
): JSX.Element[] | string => {
  return Array.isArray(values) && values.length > 0
    ? values.map((v) => (
        <StyledDiv key={v}>
          <StyledDiv>{isNotUndefined(v) && optionFormatter ? optionFormatter(v) : v ? v : ''}</StyledDiv>
        </StyledDiv>
      ))
    : '';
};

const onChangeCheckbox =
  (name: string, values: Array<string | number>, value: string | number, onChange: IInputBasics['onChange']) =>
  (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      if (e.currentTarget.checked) {
        values.push(value);
      } else {
        values = filter((v: string | number) => v !== value, values);
      }
      const trueValues = values.map((v: boolean | string | number) => (v === 'true' ? true : v === 'false' ? true : v));
      onChange({ [name]: trueValues.sort() });
    }
  };

const checkboxElements = (
  values: Array<string | number>,
  name: string,
  options: Array<string | number>,
  onChange: IInputBasics['onChange'],
  optionFormatter?: (name: string | number) => JSX.Element | string,
  optionSpecificElements?: Array<{ [name: string]: JSX.Element }>,
  optionSpecificInfo?: Array<{ [name: string | number]: string | JSX.Element }>,
  optionSpecificStyles?: ICheckbox['optionSpecificStyles'],
  disabled?: boolean,
  disabledOptions?: ICheckbox['disabledOptions'],
  twoColumns?: ICheckbox['twoColumns'],
): JSX.Element[] =>
  options.map((o) => {
    const subElement = typeof o === 'string' && optionSpecificElements?.find((e) => Object.keys(e).includes(o));
    const style = typeof o === 'string' && optionSpecificStyles?.find((e) => Object.keys(e).includes(o));
    const info = typeof o === 'string' && optionSpecificInfo?.find((e) => Object.keys(e).includes(o));
    const checked = includes(o, values);

    const thisOptionDisabled = disabledOptions?.includes(o) || disabled ? true : false;
    return (
      <StyledDiv key={o} style={style ? style[o] : undefined} twoColumns={twoColumns}>
        <FormControlLabel
          style={{ margin: '0' }}
          control={
            <StyledCheckbox
              checked={includes(o, values)}
              value={o}
              onChange={onChangeCheckbox(name, values, o, onChange)}
              color="primary"
              disabled={thisOptionDisabled}
            />
          }
          label={
            <StyledLabel disabled={thisOptionDisabled ? 1 : undefined}>
              {optionFormatter ? optionFormatter(o) : o}

              {info && (
                <div style={{ padding: '0 0.5rem 0 0.5rem' }}>
                  <InformationIcon info={info[o]} />
                </div>
              )}
            </StyledLabel>
          }
        />

        {checked && subElement ? <StyledSubElement>{subElement[o]}</StyledSubElement> : null}
      </StyledDiv>
    );
  });

const Checkboxes = ({
  editing = false,
  name,
  onChange,
  value,
  options,
  optionFormatter,
  optionSpecificInfo,
  optionSpecificElements,
  optionSpecificStyles,
  disabled,
  disabledOptions,
  twoColumns,
}: IInputBasics & Required<ICheckbox>): JSX.Element | JSX.Element[] => {
  let values = (value !== '' ? value : []) as Array<string | number>;
  values = Array.isArray(values)
    ? values.map((v: boolean | string | number) => (v === true ? 'true' : v === false ? 'false' : v))
    : [];
  return !editing ? (
    <div style={historyValue}>{values.length > 0 ? textElements(values, optionFormatter) : '-'}</div>
  ) : (
    <div>
      {checkboxElements(
        values,
        name,
        options,
        onChange,
        optionFormatter,
        optionSpecificElements,
        optionSpecificInfo,
        optionSpecificStyles,
        disabled,
        disabledOptions,
        twoColumns,
      )}
    </div>
  );
};

export default Checkboxes;
