import { FormControlLabel, Radio } from '@mui/material';
import * as React from 'react';
import { includes } from 'ramda';

import colors from '../../../config/theme/colors';
import { historyValue, styleDocument } from '../../../config/theme/componentTheme';
import { styled } from '@mui/material';

const validValueToShow = (value?: string | number): boolean => (!!value || value === 0) && value !== '';

const StyledDiv = styled('div')`
  margin-top: ${(props: { editing: boolean }): string =>
    props.editing === false ? '' : '-0.9rem'}; // Offset checkbox default padding
  * > &:not(:last-of-type) {
    margin-bottom: ${(props: { editing: boolean; compact: boolean; twoColumns?: boolean }): string =>
      props.editing === false ? '' : props.compact === true ? '-1.3rem' : '0.5rem'};
  }
  width: ${(props: { twoColumns?: boolean }): string => (props.twoColumns ? '50%' : 'auto')};
  float: ${(props: { twoColumns?: boolean }): string => (props.twoColumns ? 'left' : 'none')};
`;

const StyledRadio = styled(Radio)`
  position: relative;
  left: -1rem;
  margin: 0;
`;

const StyledLabel = styled('div')`
  font-size: ${styleDocument.fontSize};
  color: ${colors.primaryText};
  margin: 1rem 0; // Needed for lengthy option texts to have proper margins from each other
`;

const StyledSubElement = styled('div')`
  margin: 0 0 2rem 4.2rem;
`;

const radioOnChange =
  (onChange: IInputBasics['onChange'], name: string) =>
  (event: { currentTarget: { value: boolean | string } }): void => {
    const value =
      event.currentTarget.value === 'true'
        ? true
        : event.currentTarget.value === 'false'
          ? false
          : event.currentTarget.value;
    onChange && onChange({ [name]: value });
  };

const textElements = (
  value?: string | number,
  optionFormatter?: (id: string | number) => JSX.Element | string,
): JSX.Element => (
  <StyledDiv editing={false} compact={false}>
    {value !== undefined && validValueToShow(value) && optionFormatter ? optionFormatter(value) : value ? value : '-'}
  </StyledDiv>
);

const radioElements = (
  value: IRadio['value'],
  options: IRadio['options'],
  optionFormatter: IRadio['optionFormatter'],
  compact: boolean,
  onChange?: (event: any) => void,
  disabledOptions?: Array<string | number>,
  optionSpecificElements?: IRadio['optionSpecificElements'],
  style?: IInputBasics['style'],
  horizontal?: boolean,
  color?: IRadio['color'],
  twoColumns?: IRadio['twoColumns'],
): JSX.Element => (
  <>
    {Array.isArray(options) &&
      options.map((o) => {
        const subElement = typeof o === 'string' && optionSpecificElements?.find((e) => Object.keys(e).includes(o));
        const checked = o === value;
        return (
          <StyledDiv
            key={o}
            editing={true}
            compact={compact}
            style={{ display: horizontal ? 'inline-block' : undefined }}
            twoColumns={twoColumns}
          >
            <FormControlLabel
              style={
                style && style.backgroundColor && checked
                  ? {
                      backgroundColor: `${style.backgroundColor}`,
                      borderRadius: '0.5rem',
                      margin: 0,
                      paddingRight: '1rem',
                      paddingLeft: 3,
                      marginLeft: -3,
                    }
                  : { marginLeft: 0 }
              }
              control={
                <StyledRadio
                  checked={checked}
                  value={o}
                  onChange={onChange}
                  color={color ? color : 'primary'}
                  disabled={disabledOptions ? includes(o, disabledOptions) : undefined}
                  style={{ left: horizontal ? 0 : undefined }}
                />
              }
              label={
                <StyledLabel style={{ color: style && style.backgroundColor && checked ? colors.white : undefined }}>
                  {optionFormatter ? optionFormatter(o) : o}
                </StyledLabel>
              }
            />
            {checked && subElement ? <StyledSubElement>{subElement[o]}</StyledSubElement> : null}
          </StyledDiv>
        );
      })}
  </>
);

const Radios = ({
  editing = false,
  name,
  onChange,
  value,
  options,
  optionFormatter,
  disabledOptions,
  optionSpecificElements,
  style,
  compact = false,
  horizontal = false,
  color,
  twoColumns,
}: IInputBasics & IRadio): JSX.Element | JSX.Element[] =>
  !editing ? (
    <div
      style={{
        ...historyValue,
        ...{
          color: style && style.color ? `${style.color}` : undefined,
          backgroundColor: style && style.backgroundColor ? `${style.backgroundColor}` : undefined,
          padding: style && style.backgroundColor ? '0 2px 0 2px' : 0,
          borderRadius: style && style.backgroundColor ? '0.5rem' : 0,
        },
      }}
    >
      {textElements(value, optionFormatter)}
    </div>
  ) : (
    <div>
      {radioElements(
        value,
        options,
        optionFormatter,
        compact,
        radioOnChange(onChange, name),
        disabledOptions,
        optionSpecificElements,
        style,
        horizontal,
        color,
        twoColumns,
      )}
    </div>
  );

export default Radios;
