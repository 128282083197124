import { timeMonth, timeYear, timeHour, timeDay, timeWeek, scaleTime, scaleLinear, scalePoint } from 'd3';
import { maxDate, minDate } from 'neuro-utils';
import { looseDimensions, normalDimensions, tightDimensions } from '../config';
import { IData, IGraphDimensions, TGraphScale, TXTick } from '../interfaces';
import { DateTime } from 'luxon';

/**
 * Function for parsing linearScale from data. By default gets the upper limit by rounding max value to upper tens
 * @param data IData array of a single graphData object
 * @param scale scale object for which we want to find the linear scale
 * @param useAdditionalScale true or false
 * @returns linear scale derived from the data
 */
const parseLinearScale = (
  data: Array<IData>,
  scale: TGraphScale,
  useAdditionalScale: boolean,
): [number, number] | undefined => {
  let max = -999;
  let maxStack = -1;
  let maxStackDate = new Date(1);
  let compareStack = -1;
  let compareStackDate = new Date(1);
  if (!data || !data.length) return undefined;
  data.forEach((d) => {
    if (d.dataPoints.length === 0) return;
    if ((!d.useAdditionalScale && !useAdditionalScale) || (d.useAdditionalScale && useAdditionalScale)) {
      if (data.every((d) => d.type === 'stackedBarChart')) {
        d.dataPoints.forEach((dp) => {
          if (
            typeof dp.value === 'number' &&
            (dp.value > maxStack ||
              dp.date.getTime() === maxStackDate.getTime() ||
              dp.date.getTime() > maxStackDate.getTime())
          ) {
            if (dp.date.getTime() === maxStackDate.getTime()) {
              maxStack += dp.value;
            } else if (dp.date.getTime() > maxStackDate.getTime()) {
              if (dp.date.getTime() === compareStackDate.getTime()) {
                compareStack += dp.value;
              } else {
                maxStack = compareStack > maxStack ? compareStack : maxStack;
                compareStack = dp.value;
                compareStackDate = dp.date;
              }
            } else {
              maxStack = dp.value;
              maxStackDate = dp.date;
            }
          }
        });
        max = compareStack > maxStack ? compareStack : maxStack;
      } else {
        d.dataPoints.forEach((dp) => {
          if (typeof dp.value === 'number' && dp.value > max) {
            max = dp.value;
          }
        });
      }
    }
  });
  max = Math.ceil(max / 10) * 10;
  return scale.linearScaleCalculator ? scale.linearScaleCalculator(max) : [0, max];
};

// Lauantai-illan spagettia:D
// TODO: tästä järkevämpi
const parseNewShiftedTimeframe = (
  timeframe: [Date, Date],
  totalTimeframe: [Date, Date],
  timeframeLength: TTimeframeLengthOption,
  direction: 'left' | 'right',
): [Date, Date] => {
  let newStart: Date = timeframe[0];
  let newEnd: Date = timeframe[1];
  switch (timeframeLength) {
    case 'all': {
      return totalTimeframe;
    }
    case '5y': {
      const change = direction === 'left' ? -2 : 2;
      newStart = new Date(timeframe[0].getFullYear() + change, timeframe[0].getMonth(), timeframe[0].getDate());
      newEnd = new Date(timeframe[1].getFullYear() + change, timeframe[1].getMonth(), timeframe[1].getDate());
      if (newStart.getTime() < totalTimeframe[0].getTime()) {
        newStart = totalTimeframe[0];
        newEnd = new Date(
          totalTimeframe[0].getFullYear() + 5,
          totalTimeframe[0].getMonth(),
          totalTimeframe[0].getDate(),
        );
      }
      if (newEnd.getTime() > totalTimeframe[1].getTime()) {
        newEnd = totalTimeframe[1];
        newStart = new Date(
          totalTimeframe[1].getFullYear() - 5,
          totalTimeframe[1].getMonth(),
          totalTimeframe[1].getDate(),
        );
      }
      break;
    }
    case '3y': {
      const change = direction === 'left' ? -1 : 1;
      newStart = new Date(timeframe[0].getFullYear() + change, timeframe[0].getMonth(), timeframe[0].getDate());
      newEnd = new Date(timeframe[1].getFullYear() + change, timeframe[1].getMonth(), timeframe[1].getDate());
      if (newStart.getTime() < totalTimeframe[0].getTime()) {
        newStart = totalTimeframe[0];
        newEnd = new Date(
          totalTimeframe[0].getFullYear() + 3,
          totalTimeframe[0].getMonth(),
          totalTimeframe[0].getDate(),
        );
      }
      if (newEnd.getTime() > totalTimeframe[1].getTime()) {
        newEnd = totalTimeframe[1];
        newStart = new Date(
          totalTimeframe[1].getFullYear() - 3,
          totalTimeframe[1].getMonth(),
          totalTimeframe[1].getDate(),
        );
      }
      break;
    }
    case '2y': {
      const change = direction === 'left' ? -6 : 6;
      newStart = new Date(timeframe[0].getFullYear(), timeframe[0].getMonth() + change, timeframe[0].getDate());
      newEnd = new Date(timeframe[1].getFullYear(), timeframe[1].getMonth() + change, timeframe[1].getDate());
      if (newStart.getTime() < totalTimeframe[0].getTime()) {
        newStart = totalTimeframe[0];
        newEnd = new Date(
          totalTimeframe[0].getFullYear() + 2,
          totalTimeframe[0].getMonth(),
          totalTimeframe[0].getDate(),
        );
      }
      if (newEnd.getTime() > totalTimeframe[1].getTime()) {
        newEnd = totalTimeframe[1];
        newStart = new Date(
          totalTimeframe[1].getFullYear() - 2,
          totalTimeframe[1].getMonth(),
          totalTimeframe[1].getDate(),
        );
      }
      break;
    }
    case '1y': {
      const change = direction === 'left' ? -4 : 4;
      newStart = new Date(timeframe[0].getFullYear(), timeframe[0].getMonth() + change, timeframe[0].getDate());
      newEnd = new Date(timeframe[1].getFullYear(), timeframe[1].getMonth() + change, timeframe[1].getDate());
      if (newStart.getTime() < totalTimeframe[0].getTime()) {
        newStart = totalTimeframe[0];
        newEnd = new Date(
          totalTimeframe[0].getFullYear() + 1,
          totalTimeframe[0].getMonth(),
          totalTimeframe[0].getDate(),
        );
      }
      if (newEnd.getTime() > totalTimeframe[1].getTime()) {
        newEnd = totalTimeframe[1];
        newStart = new Date(
          totalTimeframe[1].getFullYear() - 1,
          totalTimeframe[1].getMonth(),
          totalTimeframe[1].getDate(),
        );
      }
      break;
    }
    case '9m': {
      const change = direction === 'left' ? -3 : 3;
      newStart = new Date(timeframe[0].getFullYear(), timeframe[0].getMonth() + change, timeframe[0].getDate());
      newEnd = new Date(timeframe[1].getFullYear(), timeframe[1].getMonth() + change, timeframe[1].getDate());
      if (newStart.getTime() < totalTimeframe[0].getTime()) {
        newStart = totalTimeframe[0];
        newEnd = new Date(
          totalTimeframe[0].getFullYear(),
          totalTimeframe[0].getMonth() + 9,
          totalTimeframe[0].getDate(),
        );
      }
      if (newEnd.getTime() > totalTimeframe[1].getTime()) {
        newEnd = totalTimeframe[1];
        newStart = new Date(
          totalTimeframe[1].getFullYear(),
          totalTimeframe[1].getMonth() - 9,
          totalTimeframe[1].getDate(),
        );
      }
      break;
    }
    case '6m': {
      const change = direction === 'left' ? -2 : 2;
      newStart = new Date(timeframe[0].getFullYear(), timeframe[0].getMonth() + change, timeframe[0].getDate());
      newEnd = new Date(timeframe[1].getFullYear(), timeframe[1].getMonth() + change, timeframe[1].getDate());
      if (newStart.getTime() < totalTimeframe[0].getTime()) {
        newStart = totalTimeframe[0];
        newEnd = new Date(
          totalTimeframe[0].getFullYear(),
          totalTimeframe[0].getMonth() + 6,
          totalTimeframe[0].getDate(),
        );
      }
      if (newEnd.getTime() > totalTimeframe[1].getTime()) {
        newEnd = totalTimeframe[1];
        newStart = new Date(
          totalTimeframe[1].getFullYear(),
          totalTimeframe[1].getMonth() - 6,
          totalTimeframe[1].getDate(),
        );
      }
      break;
    }
    case '3m': {
      const change = direction === 'left' ? -1 : 1;
      newStart = new Date(timeframe[0].getFullYear(), timeframe[0].getMonth() + change, timeframe[0].getDate());
      newEnd = new Date(timeframe[1].getFullYear(), timeframe[1].getMonth() + change, timeframe[1].getDate());
      if (newStart.getTime() < totalTimeframe[0].getTime()) {
        newStart = totalTimeframe[0];
        newEnd = new Date(
          totalTimeframe[0].getFullYear(),
          totalTimeframe[0].getMonth() + 3,
          totalTimeframe[0].getDate(),
        );
      }
      if (newEnd.getTime() > totalTimeframe[1].getTime()) {
        newEnd = totalTimeframe[1];
        newStart = new Date(
          totalTimeframe[1].getFullYear(),
          totalTimeframe[1].getMonth() - 3,
          totalTimeframe[1].getDate(),
        );
      }
      break;
    }
    case '2m': {
      const change = direction === 'left' ? -20 : 20;
      newStart = new Date(timeframe[0].getFullYear(), timeframe[0].getMonth(), timeframe[0].getDate() + change);
      newEnd = new Date(timeframe[1].getFullYear(), timeframe[1].getMonth(), timeframe[1].getDate() + change);
      if (newStart.getTime() < totalTimeframe[0].getTime()) {
        newStart = totalTimeframe[0];
        newEnd = new Date(
          totalTimeframe[0].getFullYear(),
          totalTimeframe[0].getMonth() + 2,
          totalTimeframe[0].getDate(),
        );
      }
      if (newEnd.getTime() > totalTimeframe[1].getTime()) {
        newEnd = totalTimeframe[1];
        newStart = new Date(
          totalTimeframe[1].getFullYear(),
          totalTimeframe[1].getMonth() - 2,
          totalTimeframe[1].getDate(),
        );
      }
      break;
    }
    case '1m': {
      const change = direction === 'left' ? -10 : 10;
      newStart = new Date(timeframe[0].getFullYear(), timeframe[0].getMonth(), timeframe[0].getDate() + change);
      newEnd = new Date(timeframe[1].getFullYear(), timeframe[1].getMonth(), timeframe[1].getDate() + change);
      if (newStart.getTime() < totalTimeframe[0].getTime()) {
        newStart = totalTimeframe[0];
        newEnd = new Date(
          totalTimeframe[0].getFullYear(),
          totalTimeframe[0].getMonth() + 1,
          totalTimeframe[0].getDate(),
        );
      }
      if (newEnd.getTime() > totalTimeframe[1].getTime()) {
        newEnd = totalTimeframe[1];
        newStart = new Date(
          totalTimeframe[1].getFullYear(),
          totalTimeframe[1].getMonth() - 1,
          totalTimeframe[1].getDate(),
        );
      }
      break;
    }
    case '14d': {
      const change = direction === 'left' ? -3 : 3;
      newStart = new Date(timeframe[0].getFullYear(), timeframe[0].getMonth(), timeframe[0].getDate() + change);
      newEnd = new Date(timeframe[1].getFullYear(), timeframe[1].getMonth(), timeframe[1].getDate() + change);
      if (newStart.getTime() < totalTimeframe[0].getTime()) {
        newStart = totalTimeframe[0];
        newEnd = new Date(
          totalTimeframe[0].getFullYear(),
          totalTimeframe[0].getMonth(),
          totalTimeframe[0].getDate() + 14,
        );
      }
      if (newEnd.getTime() > totalTimeframe[1].getTime()) {
        newEnd = totalTimeframe[1];
        newStart = new Date(
          totalTimeframe[1].getFullYear(),
          totalTimeframe[1].getMonth(),
          totalTimeframe[1].getDate() - 14,
        );
      }
      break;
    }
    case '7d': {
      const change = direction === 'left' ? -2 : 2;
      newStart = new Date(timeframe[0].getFullYear(), timeframe[0].getMonth(), timeframe[0].getDate() + change);
      newEnd = new Date(timeframe[1].getFullYear(), timeframe[1].getMonth(), timeframe[1].getDate() + change);
      if (newStart.getTime() < totalTimeframe[0].getTime()) {
        newStart = totalTimeframe[0];
        newEnd = new Date(
          totalTimeframe[0].getFullYear(),
          totalTimeframe[0].getMonth(),
          totalTimeframe[0].getDate() + 7,
        );
      }
      if (newEnd.getTime() > totalTimeframe[1].getTime()) {
        newEnd = totalTimeframe[1];
        newStart = new Date(
          totalTimeframe[1].getFullYear(),
          totalTimeframe[1].getMonth(),
          totalTimeframe[1].getDate() - 7,
        );
      }
      break;
    }
    case '3d': {
      const change = direction === 'left' ? -1 : 1;
      newStart = new Date(timeframe[0].getFullYear(), timeframe[0].getMonth(), timeframe[0].getDate() + change);
      newEnd = new Date(timeframe[1].getFullYear(), timeframe[1].getMonth(), timeframe[1].getDate() + change);
      if (newStart.getTime() < totalTimeframe[0].getTime()) {
        newStart = totalTimeframe[0];
        newEnd = new Date(
          totalTimeframe[0].getFullYear(),
          totalTimeframe[0].getMonth(),
          totalTimeframe[0].getDate() + 3,
        );
      }
      if (newEnd.getTime() > totalTimeframe[1].getTime()) {
        newEnd = totalTimeframe[1];
        newStart = new Date(
          totalTimeframe[1].getFullYear(),
          totalTimeframe[1].getMonth(),
          totalTimeframe[1].getDate() - 3,
        );
      }
      break;
    }
  }
  return [newStart, newEnd];
};

const parseNewTimeframe = (
  timeframe: [Date, Date],
  totalTimeframe: [Date, Date],
  timeframeLength: TTimeframeLengthOption,
): [Date, Date] => {
  let newStart: Date = minDate(timeframe);
  let newEnd: Date = maxDate(timeframe);
  switch (timeframeLength) {
    case 'all': {
      return [minDate(totalTimeframe), maxDate(totalTimeframe)];
    }
    case '5y': {
      newStart = new Date(timeframe[1].getFullYear() - 5, timeframe[1].getMonth(), timeframe[1].getDate());
      if (newStart.getTime() < totalTimeframe[0].getTime()) {
        newStart = totalTimeframe[0];
        newEnd = new Date(
          totalTimeframe[0].getFullYear() + 5,
          totalTimeframe[0].getMonth(),
          totalTimeframe[0].getDate(),
        );
      }
      break;
    }
    case '3y': {
      newStart = new Date(timeframe[1].getFullYear() - 3, timeframe[1].getMonth(), timeframe[1].getDate());
      if (newStart.getTime() < totalTimeframe[0].getTime()) {
        newStart = totalTimeframe[0];
        newEnd = new Date(
          totalTimeframe[0].getFullYear() + 3,
          totalTimeframe[0].getMonth(),
          totalTimeframe[0].getDate(),
        );
      }
      break;
    }
    case '2y': {
      newStart = new Date(timeframe[1].getFullYear() - 2, timeframe[1].getMonth(), timeframe[1].getDate());
      if (newStart.getTime() < totalTimeframe[0].getTime()) {
        newStart = totalTimeframe[0];
        newEnd = new Date(
          totalTimeframe[0].getFullYear() + 2,
          totalTimeframe[0].getMonth(),
          totalTimeframe[0].getDate(),
        );
      }
      break;
    }
    case '1y': {
      newStart = new Date(timeframe[1].getFullYear() - 1, timeframe[1].getMonth(), timeframe[1].getDate());
      if (newStart.getTime() < totalTimeframe[0].getTime()) {
        newStart = totalTimeframe[0];
        newEnd = new Date(
          totalTimeframe[0].getFullYear() + 1,
          totalTimeframe[0].getMonth(),
          totalTimeframe[0].getDate(),
        );
      }
      break;
    }
    case '9m': {
      newStart = new Date(timeframe[1].getFullYear(), timeframe[1].getMonth() - 9, timeframe[1].getDate());
      if (newStart.getTime() < totalTimeframe[0].getTime()) {
        newStart = totalTimeframe[0];
        newEnd = new Date(
          totalTimeframe[0].getFullYear(),
          totalTimeframe[0].getMonth() + 9,
          totalTimeframe[0].getDate(),
        );
      }
      break;
    }
    case '6m': {
      newStart = new Date(timeframe[1].getFullYear(), timeframe[1].getMonth() - 6, timeframe[1].getDate());
      if (newStart.getTime() < totalTimeframe[0].getTime()) {
        newStart = totalTimeframe[0];
        newEnd = new Date(
          totalTimeframe[0].getFullYear(),
          totalTimeframe[0].getMonth() + 6,
          totalTimeframe[0].getDate(),
        );
      }
      break;
    }
    case '3m': {
      newStart = new Date(timeframe[1].getFullYear(), timeframe[1].getMonth() - 3, timeframe[1].getDate());
      if (newStart.getTime() < totalTimeframe[0].getTime()) {
        newStart = totalTimeframe[0];
        newEnd = new Date(
          totalTimeframe[0].getFullYear(),
          totalTimeframe[0].getMonth() + 3,
          totalTimeframe[0].getDate(),
        );
      }
      break;
    }
    case '2m': {
      newStart = new Date(timeframe[1].getFullYear(), timeframe[1].getMonth() - 2, timeframe[1].getDate());
      if (newStart.getTime() < totalTimeframe[0].getTime()) {
        newStart = totalTimeframe[0];
        newEnd = new Date(
          totalTimeframe[0].getFullYear(),
          totalTimeframe[0].getMonth() + 2,
          totalTimeframe[0].getDate(),
        );
      }
      break;
    }
    case '1m': {
      newStart = new Date(timeframe[1].getFullYear(), timeframe[1].getMonth() - 1, timeframe[1].getDate());
      if (newStart.getTime() < totalTimeframe[0].getTime()) {
        newStart = totalTimeframe[0];
        newEnd = new Date(
          totalTimeframe[0].getFullYear(),
          totalTimeframe[0].getMonth() + 1,
          totalTimeframe[0].getDate(),
        );
      }
      break;
    }
    case '14d': {
      newStart = new Date(timeframe[1].getFullYear(), timeframe[1].getMonth(), timeframe[1].getDate() - 14);
      if (newStart.getTime() < totalTimeframe[0].getTime()) {
        newStart = totalTimeframe[0];
        newEnd = new Date(
          totalTimeframe[0].getFullYear(),
          totalTimeframe[0].getMonth(),
          totalTimeframe[0].getDate() + 14,
        );
      }
      break;
    }
    case '7d': {
      newStart = new Date(timeframe[1].getFullYear(), timeframe[1].getMonth(), timeframe[1].getDate() - 7);
      if (newStart.getTime() < totalTimeframe[0].getTime()) {
        newStart = totalTimeframe[0];
        newEnd = new Date(
          totalTimeframe[0].getFullYear(),
          totalTimeframe[0].getMonth(),
          totalTimeframe[0].getDate() + 7,
        );
      }
      break;
    }
    case '3d': {
      newStart = new Date(timeframe[1].getFullYear(), timeframe[1].getMonth(), timeframe[1].getDate() - 3);
      if (newStart.getTime() < totalTimeframe[0].getTime()) {
        newStart = totalTimeframe[0];
        newEnd = new Date(
          totalTimeframe[0].getFullYear(),
          totalTimeframe[0].getMonth(),
          totalTimeframe[0].getDate() + 3,
        );
      }
      break;
    }
  }
  return [newStart, newEnd];
};

/**
 * Returns nearest (larger) static timeframe based on dates supplied.
 * Used to change/upscale custom timeframes to static ones
 * @param fromDate Selected from date
 * @param toDate Selected to date
 * @param totalTimeframe Total timeframe for the graph
 * @returns { timeFrame: [Date, Date]; timeFrameLength: TTimeframeLengthOption } - Return new timeframe and which static length it is
 */
const getTimeFrameLengthBasedOnDates = (
  fromDate: Date,
  toDate: Date,
  totalTimeframe: [Date, Date],
): { timeFrame: [Date, Date]; timeFrameLength: TTimeframeLengthOption } => {
  const fromDT = DateTime.fromJSDate(fromDate);
  const toDT = DateTime.fromJSDate(toDate);

  const diff = toDT.diff(fromDT, ['years', 'months', 'days']);

  let nextTimeframe: TTimeframeLengthOption | null = null;

  if (diff.years > 0) {
    if (diff.years >= 5) {
      nextTimeframe = 'all';
    } else if (diff.years >= 3) {
      nextTimeframe = '5y';
    } else if (diff.years >= 2) {
      nextTimeframe = '3y';
    } else if (diff.years >= 1) {
      nextTimeframe = '2y';
    }
  } else if (diff.months > 0) {
    if (diff.months >= 9) {
      nextTimeframe = '1y';
    } else if (diff.months >= 6) {
      nextTimeframe = '9m';
    } else if (diff.months >= 3) {
      nextTimeframe = '6m';
    } else if (diff.months >= 2) {
      nextTimeframe = '3m';
    } else if (diff.months >= 1) {
      nextTimeframe = '2m';
    }
  } else {
    if (diff.days >= 14) {
      nextTimeframe = '1m';
    } else if (diff.days >= 7) {
      nextTimeframe = '14d';
    } else if (diff.days >= 3) {
      nextTimeframe = '7d';
    } else {
      nextTimeframe = '3d';
    }
  }

  const newTimeFrame = nextTimeframe && parseNewTimeframe([fromDate, toDate], totalTimeframe, nextTimeframe);

  return { timeFrame: newTimeFrame || [new Date(), new Date()], timeFrameLength: nextTimeframe || 'all' };
};

// TODO: This should be improved
/**
 * Function for turning dates into x-ticks to be used in the graphics
 * @param d Date
 * @param timeframe the timeframe
 * @returns title and its priority parsed from the date
 */
const getXTickTitle = (d: Date, timeframe: [Date, Date]): { title: string; priority: 'low' | 'normal' | 'high' } => {
  if (d.getMonth() === 0 && d.getDate() === 1) return { title: `${d.getFullYear()}`, priority: 'high' };
  if (d.getDate() === 1) return { title: `${d.getMonth() + 1}/${d.getFullYear()}`, priority: 'normal' };
  // Less than year
  if (Math.abs(timeframe[0].getTime() - timeframe[1].getTime()) < 31536000000) {
    return {
      title: `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`,
      priority: 'normal',
    };
  }
  return { title: '', priority: 'low' };
};

// TODO: maybe this also should be improved
const getTickInterval = (
  timeframe: [Date, Date],
  timeframeLength: TTimeframeLengthOption,
  timeframeWidth: number,
): any => {
  // If difference between timeframe[0] and timeframe[1] more than 90 days, return one tick per month
  const dayDiff = Math.ceil(Math.abs(timeframe[1].getTime() - timeframe[0].getTime()) / (1000 * 3600 * 24));
  if (dayDiff > 90 && dayDiff < 600) {
    return timeMonth.every(1);
  }
  if (dayDiff < 5 && dayDiff > 3) return timeHour.every(12);
  if (dayDiff <= 3) return timeHour.every(3);
  if (dayDiff > 7000) return timeYear.every(5);
  if (dayDiff > 3500) return timeYear.every(2);
  if (dayDiff >= 600) return timeYear.every(1);
  switch (timeframeLength) {
    case '3d': {
      return timeHour.every(6);
    }
    case '7d':
    case '14d': {
      return timeDay.every(1);
    }
    case '1m': {
      return timeWeek.every(1);
    }
    case '2m':
    case '3m': {
      if (timeframeWidth < 100) return timeMonth.every(1);
      return timeWeek.every(1);
    }
  }

  // If timeframeLength is all and dayDiff 90 days and more than 30 days
  if (dayDiff <= 90 && dayDiff > 30) {
    if (timeframeWidth < 100) return timeMonth.every(1);
    return timeWeek.every(1);
  }
  // 30 days or less
  return timeWeek.every(1);
};

// TODO this also should probably be improved
const getXTicks = (
  timeframe: [Date, Date],
  timeframeLength: TTimeframeLengthOption,
  timeframeWidth: number,
): TXTick[] => {
  const xTicks: TXTick[] = [];

  // Define the function that is used to calculate the ticks
  const timeRange = scaleTime()
    .domain(timeframe)
    .range([0, timeframeWidth * 10]);
  // Create the ticks here
  timeRange
    .ticks(getTickInterval(timeframe, timeframeLength, timeframeWidth) ?? 1)
    .forEach((value: Date, i: number) => {
      const x = timeRange(value);
      if (typeof x !== 'number') throw RangeError('d3-scale domain or range is unfit');
      const title = getXTickTitle(value, timeframe);
      const ticksSoFar = xTicks.slice(0, i);
      if (i > 0 && ticksSoFar.some((t) => t.value === title.title)) {
        title.title = '';
        title.priority = 'low';
      }
      xTicks.push({ x: Math.round(x), value: title.title, priority: title.priority });
    });

  // Make adjustments to ticks here
  switch (timeframeLength) {
    case '14d': {
      xTicks.forEach((t, i) => {
        if (i % 4 !== 0) {
          t.value = '';
          t.priority = 'low';
        }
      });
      break;
    }
    case '1m': {
      xTicks.forEach((t, i) => {
        if (i % 2 !== 0) {
          t.value = '';
          t.priority = 'low';
        }
      });
      break;
    }
    case 'all':
    case '5y':
    case '3y':
    case '2y': {
      if (!xTicks.find((x) => x.priority === 'low')) {
        for (let i = xTicks.length - 1; i > 0; i--) {
          xTicks.push({ x: Math.round((xTicks[i].x + xTicks[i - 1].x) / 2), value: '', priority: 'low' });
        }
      }
      break;
    }
  }
  return xTicks;
};

// TODO this also should be improved. Compare with getYPoint-function of graph.tsx
const getYTicks = (scale: TGraphScale | undefined, graphHeight: number): { y: number; value: string }[] => {
  if (!scale) return [];
  const yTicks: { y: number; value: string }[] = [];
  switch (scale.type) {
    case 'linear': {
      if (!scale.linearScale) break;
      const valueRange = scaleLinear().domain(scale.linearScale).range([graphHeight, 0]);
      valueRange.ticks(6).forEach((value) => {
        const y = valueRange(value);
        if (typeof y !== 'number') return;
        yTicks.push({
          y: Math.round(y),
          value: scale.formatter ? scale.formatter(value.toString()) : value.toString(),
        });
      });
      break;
    }
    case 'custom': {
      if (!scale.customScale) break;
      const valueRange = scalePoint().domain(scale.customScale).range([graphHeight, 0]);
      scale.customScale.forEach((cs) => {
        const y = valueRange(cs);
        if (!(y || y === 0)) return;
        yTicks.push({ y: Math.round(y), value: scale.formatter ? scale.formatter(cs) : cs });
      });
      break;
    }
    case 'hybridCL': {
      if (!scale.linearScale || !scale.customScale) break;
      const step = scale.tickAmount
        ? graphHeight / (scale?.tickAmount + scale.customScale.length - 1)
        : graphHeight / (6 + scale.customScale.length - 1);
      const customScaleRange: [number, number] = [graphHeight, graphHeight - (scale.customScale.length - 1) * step];
      const linearScaleRange: [number, number] = [graphHeight - scale.customScale.length * step, 0];
      const customValueRange = scalePoint().domain(scale.customScale).range(customScaleRange);
      const linearValueRange = scaleLinear()
        .domain(scale.linearScale)
        .range(linearScaleRange)
        .nice(scale.tickAmount ?? 6);
      scale.customScale.forEach((cs) => {
        const y = customValueRange(cs);
        if (!(y || y === 0)) return;
        yTicks.push({ y: Math.round(y), value: scale.formatter ? scale.formatter(cs) : cs });
      });

      linearValueRange.ticks(scale.tickAmount ?? 6).forEach((value: number) => {
        const y = linearValueRange(value);
        if (!(y || y === 0)) return;
        yTicks.push({
          y: Math.round(y),
          value: scale.formatter ? scale.formatter(value.toString()) : value.toString(),
        });
      });

      break;
    }
    case 'hybridLC': {
      if (!scale.linearScale || !scale.customScale) break;
      const step = scale.tickAmount
        ? graphHeight / (scale?.tickAmount + scale.customScale.length - 1)
        : graphHeight / (6 + scale.customScale.length - 1);
      const linearScaleRange: [number, number] = [graphHeight, 0 + scale.customScale.length * step];
      const customScaleRange: [number, number] = [0 + (scale.customScale.length - 1) * step, 0];
      const customValueRange = scalePoint().domain(scale.customScale).range(customScaleRange);
      const linearValueRange = scaleLinear()
        .domain(scale.linearScale)
        .range(linearScaleRange)
        .nice(scale.tickAmount ?? 6);
      scale.customScale.forEach((cs) => {
        const y = customValueRange(cs);
        if (!(y || y === 0)) return;
        yTicks.push({ y: Math.round(y), value: scale.formatter ? scale.formatter(cs) : cs });
      });
      linearValueRange.ticks(scale.tickAmount ?? 6).forEach((value: number) => {
        const y = linearValueRange(value);
        if (!(y || y === 0)) return;
        yTicks.push({
          y: Math.round(y),
          value: scale.formatter ? scale.formatter(value.toString()) : value.toString(),
        });
      });
      break;
    }
  }
  return yTicks;
};

const parseTimeframeWidth = (width: number, dimensions: IGraphDimensions): number => {
  return (
    width -
    dimensions.leftColumn.width -
    dimensions.leftColumn.paddingLeft -
    dimensions.rightColumn.width -
    dimensions.rightColumn.paddingRight
  );
};

const padZero = (str: string, len?: number) => {
  len = len || 2;
  const zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
};

const invertColor = (hex: string): string => {
  if (!hex || typeof hex !== 'string') return '#ad9718';
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  const re = new RegExp('^[a-zA-Z0-9]{6}$');
  if (!re.test(hex)) {
    return '#ad9718';
  }
  // invert color components
  try {
    const r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
      g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
      b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return '#' + padZero(r) + padZero(g) + padZero(b);
  } catch {
    return '#ad9718';
  }
};

const parseDimensions = (layout: 'tight' | 'normal' | 'loose'): IGraphDimensions => {
  switch (layout) {
    case 'tight': {
      return tightDimensions;
    }
    case 'normal': {
      return normalDimensions;
    }
    case 'loose': {
      return looseDimensions;
    }
  }
};

export {
  parseLinearScale,
  parseNewShiftedTimeframe,
  parseNewTimeframe,
  getXTicks,
  getYTicks,
  parseTimeframeWidth,
  invertColor,
  parseDimensions,
  getTimeFrameLengthBasedOnDates,
};
