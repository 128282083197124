import * as React from 'react';
import { sortDate } from 'neuro-utils';
import { Theme, Radio, styled as muiStyled } from '@mui/material';
import {
  IAddon,
  IEvent,
  TAddonOption,
  TEventPriority,
  TAddonTheme,
  IGraphDimensions,
} from 'Components/sq-graphics/interfaces';

import * as Icon from './Icons';
import { GraphTooltip, MultipleGraphTooltip } from '../GraphTooltip';
import PlatformConditional from 'Components/PlatformConditional';
import colors from '../../../../config/theme/colors';
import { getExpansionPanelTheme } from 'Components/sq-graphics/config';

const AddonWrapper = muiStyled('div')(({ dimensions }: { dimensions: IGraphDimensions }) => ({
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'row',
  marginTop: `${dimensions.addons.marginTop}rem`,
  marginBottom: `${dimensions.addons.marginBottom}rem`,
}));

const StyledColumn = muiStyled('div')(({ width, padding }: { width?: number; padding?: string }) => ({
  width: width ? `${width}rem` : 'auto',
  height: 'auto',
  padding: padding,
  boxSizing: 'border-box',
}));

const StyledDivider = muiStyled('div')(({ color, dimensions }: { color: string; dimensions: IGraphDimensions }) => ({
  width: `${dimensions.addons.divider.width}rem`,
  minWidth: `${dimensions.addons.divider.width}rem`,
  height: 'auto',
  borderRadius: '0.2rem',
  backgroundColor: color ?? 'black',
  opacity: 0.5,
  boxSizing: 'border-box',
}));

const StyledTitle = muiStyled('div')(({ color, fontWeight }: { color?: string; fontWeight?: 'bold' }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'center',
  alignItems: 'center',
  userSelect: 'none',
  fontSize: '1.6rem',
  color: color ?? 'black',
  fontWeight: fontWeight || 'normal',
  boxSizing: 'border-box',
  borderTop: '1px solid darkgray',
}));

const StyledExpansionPanelTitle = muiStyled('div')(
  ({
    color,
    dimensions,
    adjustWidthForMissingIcon,
  }: {
    color: string;
    dimensions: IGraphDimensions;
    adjustWidthForMissingIcon: boolean;
  }) => ({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    minWidth: `${
      dimensions.leftColumn.width - dimensions.addons.divider.width - 2 + (adjustWidthForMissingIcon ? 1.5 : 0)
    }rem`,
    maxWidth: `${
      dimensions.leftColumn.width - dimensions.addons.divider.width - 2 + (adjustWidthForMissingIcon ? 1.5 : 0)
    }rem`,
    fontSize: '1.4rem',
    color: color,
    fontWeight: 'bold',
    userSelect: 'none',
    boxSizing: 'border-box',
    padding: '0 0.5rem',
    cursor: adjustWidthForMissingIcon ? 'default' : 'pointer',
  }),
);

const StyledExpansionPanelTitleWrapper = muiStyled('div')({
  width: '100%',
  height: '2rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '0 0.5rem 0 0.5rem',
});

const StyledExpansionPanelWrapper = muiStyled('div')((props: { width: number }) => ({
  width: `${props.width}rem`,
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  zIndex: 10,
}));

const StyledExpansionPanelArrow = muiStyled('div')({
  display: 'flex',
  width: '1.5rem',
  height: '2rem',
  justifyContent: 'right',
  alignItems: 'center',
  cursor: 'pointer',
});

const PanelArrow = ({ open }: { open: boolean }) => (
  <svg
    transform={`rotate(${!open ? -90 : 0})`}
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 5.71411L0.669872 0.267852L9.33013 0.267851L5 5.71411Z" fill="#045A8B" />
  </svg>
);
const StyledExpansionPanelInfo = muiStyled('div')({
  display: 'flex',
  width: '1.5rem',
  height: '2rem',
  justifyContent: 'center',
  alignItems: 'center',
  paddingLeft: '0.5rem',
});

const StyledExpansionPanelLegendWrapper = muiStyled('div')({
  display: 'flex',
  width: 'max-content',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

const ColorBox = ({ color, fill }: { color: string; fill?: string }): JSX.Element => (
  <svg height="15" width="7" viewBox="0 0 7 15" overflow="visible">
    <rect
      x="1"
      width="7"
      height="15"
      fill={fill ?? color}
      fillOpacity="0.5"
      stroke={color}
      strokeWidth="1.5"
      rx={0.3}
    />
  </svg>
);

const diamondShape = (theme: TAddonTheme, priority: TEventPriority | undefined): JSX.Element => (
  <Icon.IconChange
    priority={priority}
    strokeColor={theme.priorityColors?.[priority ?? 'low'] || theme.primary}
    strokeWidth={2}
    fillColor="white"
    large={true}
    active={true}
  />
);

const themedDiamondWithText = (
  theme: TAddonTheme,
  dataPointPosition: 'left' | 'center' | 'right',
  text?: string,
  priority?: 'low' | 'normal' | 'high',
): JSX.Element => {
  const priorityColor = priority && theme.priorityColors?.[priority];
  return (
    <g>
      <Icon.IconChange strokeColor={priorityColor ?? theme.primary} strokeWidth={2} fillColor="white" active />{' '}
      {text && (
        <text
          y="-8"
          x={dataPointPosition === 'left' ? '10' : dataPointPosition === 'center' ? '30' : '-10'}
          dominantBaseline="hanging"
          fontWeight="bold"
          fontSize="16"
          fill={priorityColor ?? theme.primary}
          textAnchor={dataPointPosition === 'left' ? 'start' : dataPointPosition === 'right' ? 'end' : 'middle'}
        >
          {text}
        </text>
      )}
    </g>
  );
};

const activityIcon = (theme: TAddonTheme, priority?: 'low' | 'normal' | 'high'): JSX.Element => {
  const priorityColor = priority && theme.priorityColors?.[priority];
  return (
    <Icon.IconActivity
      strokeColor={priorityColor ?? theme.primary}
      strokeWidth={4}
      fillColor={`${priorityColor ?? theme.primary}44`}
      active
    />
  );
};

const adverseEffect = (theme: TAddonTheme): JSX.Element => (
  <Icon.IconAdverseEffect strokeColor={theme.primary} strokeWidth={2} fillColor="white" large active />
);

const administration = (theme: TAddonTheme): JSX.Element => (
  <Icon.IconAdministration strokeColor={theme.primary} strokeWidth={2} fillColor="white" large active />
);

const technicalIssue = (theme: TAddonTheme): JSX.Element => (
  <Icon.IconTechnicalIssue strokeColor={theme.primary} strokeWidth={2} fillColor="white" />
);

const textIcon = (
  title: string | undefined,
  theme: TAddonTheme,
  dataPointPosition: 'left' | 'center' | 'right',
): JSX.Element => {
  if (!title) return <g />;
  return (
    <g>
      <line y1="-13" y2="-2" x1="0" x2="0" stroke={theme.primary} strokeWidth="3" />
      <text
        y="-4"
        x="0"
        dominantBaseline="hanging"
        fontWeight="bold"
        fontSize="18"
        fill={theme.primary}
        textAnchor={dataPointPosition === 'left' ? 'start' : dataPointPosition === 'right' ? 'end' : 'middle'}
      >
        {title}
      </text>
    </g>
  );
};

const dynamicIcon = (data: TAddonEvent): JSX.Element => (
  <g transform={`translate(10,0)`}>
    {data.secondaryTitle && (
      <text
        style={{ fill: '#636262', fontSize: 12, fontWeight: 600, userSelect: 'none' }}
        textAnchor="start"
        x={0}
        y={-1}
      >
        {data.secondaryTitle}
      </text>
    )}
    <text style={{ fill: 'white', fontSize: 12, fontWeight: 600, userSelect: 'none' }} textAnchor="start" x={0} y={9.5}>
      {data.title}
    </text>
  </g>
);

const numberSquareShape = (
  n: number,
  theme: TAddonTheme,
  priority: 'high' | 'normal' | 'low' | undefined,
): JSX.Element => {
  const color = theme.priorityColors?.[priority ?? 'low'] || theme.primary;
  return (
    <React.Fragment>
      <Icon.IconMultipleEvents
        priority={priority}
        n={n}
        strokeColor={color}
        strokeWidth={1.5}
        fillColor={priority === 'high' ? color : 'white'}
        textColor={priority === 'high' ? 'white' : color}
        active={true}
      />
    </React.Fragment>
  );
};

const EventPoint = ({
  event,
  theme,
  dataPointPosition,
}: {
  event: TAddonEvent[];
  theme: TAddonTheme;
  dataPointPosition: 'left' | 'center' | 'right';
}): JSX.Element => {
  let icon: JSX.Element;

  if (event.length > 1)
    return numberSquareShape(
      event.length,
      theme,
      event.find((e) => e.priority === 'high')?.priority || event.find((e) => e.priority === 'normal')?.priority,
    );
  const singleEvent = event[0];

  switch (singleEvent.eventType) {
    case 'text': {
      icon = textIcon(singleEvent.title, theme, dataPointPosition);
      break;
    }
    case 'dynamic':
      icon = dynamicIcon(singleEvent);
      break;
    case 'adverseEffect': {
      icon = adverseEffect(theme);
      break;
    }
    case 'administration': {
      icon = administration(theme);
      break;
    }
    case 'technicalIssue': {
      icon = technicalIssue(theme);
      break;
    }
    case 'activity': {
      icon = activityIcon(theme, singleEvent.priority);
      break;
    }
    case 'themedDiamondWithText': {
      icon = themedDiamondWithText(theme, dataPointPosition, singleEvent.iconText, singleEvent.priority);
      break;
    }
    default:
      icon = diamondShape(theme, singleEvent.priority);
      break;
  }

  return icon;
};

// TODO: make this work like the one used in linegraph
const validateAddonEvents = (
  events: IEvent[] | undefined,
  addonWidth: number,
  xPoint: (date: Date | undefined) => number | undefined,
): TAddonEvent[][] => {
  if (!events) return [];
  const step = 20;
  const validatedEvents: TAddonEvent[] = [];
  // Lasketaan jokaselle pisteelle x-arvo jne.
  events.forEach((event) => {
    const x = xPoint(event.date);
    if (!(x || x === 0)) return;
    validatedEvents.push({ ...event, x });
  });
  validatedEvents.sort((e1, e2) => sortDate(e1.date, e2.date));
  const mergedEvents: TAddonEvent[][] = [];
  let i = 0;
  // Jos eventtejä ei yhtä enempää, ei tartte mergee overlappaavia eventtejä
  if (validatedEvents.length === 0) return [];
  if (validatedEvents.length === 1) return [validatedEvents];
  // Iteroidaan aikajanan alue läpi askel kerrallaan ja mergetään yhden askeleen sisällä olevat eventit
  let eventsWithinAStep: TAddonEvent[] = [];
  for (let xPos = 0; xPos <= addonWidth; xPos += step) {
    if (validatedEvents[i].x >= xPos && validatedEvents[i].x < xPos + step) {
      while (validatedEvents[i].x <= xPos + step) {
        eventsWithinAStep.push(validatedEvents[i]);
        i += 1;
        if (i === validatedEvents.length) break;
      }
      mergedEvents.push(eventsWithinAStep);
      eventsWithinAStep = [];
    }
    if (i === validatedEvents.length) break;
  }
  return mergedEvents;
};

type TAddonEvent = IEvent & { x: number };

// TODO
const Addon = ({
  addon,
  width,
  xPoint,
  theme,
  totalTimeframe,
}: {
  addon: IAddon | undefined;
  width: number;
  xPoint: (d: Date | undefined) => number | undefined;
  theme: TAddonTheme;
  totalTimeframe: [Date, Date];
}): JSX.Element => {
  if (!addon) return <svg />;
  const events = validateAddonEvents(addon.events, width * 10, xPoint);
  return (
    <svg viewBox={`0 0 ${width * 10} 30`} overflow="visible">
      <rect x="0" y="2" width="100%" height="26" fill="#F5F5F5" opacity="0.75" />
      <line x1="0" x2="0" y1="0" y2="30" stroke="darkgray" strokeWidth="1.5" />
      <line x1="100%" x2="100%" y1="0" y2="30" stroke="darkgray" strokeWidth="1.5" />
      {addon.items?.map((addon, i) => {
        let x1 = xPoint(addon.start);
        let x2 = xPoint(
          addon.end ? addon.end : totalTimeframe[1].valueOf() > new Date().valueOf() ? totalTimeframe[1] : new Date(),
        );
        let addonTheme = theme;
        if (addon.themeId) addonTheme = getExpansionPanelTheme(addon.themeId).addon;
        // Actions below are done to prevent tooltip from showing in left or right margin
        if ((x1 || x1 === 0) && x1 < 0) x1 = 0;
        if ((x2 || x2 === 0) && x2 > width * 10) x2 = width * 10;
        return (
          (x1 || x1 === 0) &&
          (x2 || x2 === 0) && (
            <GraphTooltip
              key={i + 'bar'}
              title={addon.title ?? ''}
              start={addon.start}
              end={addon.end}
              description={addon.description}
              content={
                <rect
                  key={i}
                  x={x1}
                  width={Math.abs(x2 - x1)}
                  height={10}
                  y={10}
                  fill={addonTheme.primary}
                  stroke={addonTheme.primary}
                  strokeWidth="1.5"
                  fillOpacity="0.5"
                />
              }
            />
          )
        );
      })}
      {events.map((event, i, arr) => {
        const dynamicEvents: TAddonEvent[] = [];
        event.forEach((e) => {
          const e2 = arr[i + 1] && arr[i + 1][arr[i + 1].length - 1];
          if (e.eventType === 'dynamic') {
            event.splice(event.indexOf(e), 1);
            const minDistances = [e.title, e.secondaryTitle]
              .filter((title) => title)
              .map((title) => (typeof title === 'string' ? title.replace(/[^a-zA-Z0-9 ]/g, '').length * 15 : 1));
            const minDistance = Math.max(...minDistances);
            if (e2 && e2.x - e.x < minDistance) return;
            dynamicEvents.push(e);
          }
        });
        const latestDynamic = dynamicEvents.sort((e1, e2) => sortDate(e2.date, e1.date))[0];
        return (
          <React.Fragment key={i}>
            {event.length > 1 ? (
              <MultipleGraphTooltip
                data={event.map((e) => ({
                  title: e.title ?? '',
                  date: e.date,
                  start: e.date,
                  end: e.endDate,
                  description: e.description,
                  name: '',
                }))}
                content={
                  <g
                    transform={`translate(${event.reduce((prev, curr) => prev + curr.x, 0) / event.length}, ${15})`}
                    overflow="visible"
                  >
                    <EventPoint event={event} theme={theme} dataPointPosition="center" />
                  </g>
                }
              />
            ) : event.length === 1 ? (
              <GraphTooltip
                key={i}
                title={event[0].title ?? ''}
                date={event[0].date}
                description={event[0].description}
                content={
                  <g transform={`translate(${event[0].x}, ${15})`} overflow="visible">
                    <EventPoint
                      event={event}
                      theme={theme}
                      dataPointPosition={event[0].x < 5 ? 'left' : event[0].x > width * 10 - 5 ? 'right' : 'center'}
                    />
                  </g>
                }
              />
            ) : null}
            <PlatformConditional platform="epilepsy">
              {latestDynamic && (
                <g
                  transform={`translate(${
                    [latestDynamic].reduce((prev, curr) => prev + curr.x, 0) / [latestDynamic].length
                  }, ${9})`}
                  overflow="visible"
                >
                  <EventPoint event={[latestDynamic]} theme={theme} dataPointPosition="center" />
                </g>
              )}
            </PlatformConditional>
          </React.Fragment>
        );
      })}
    </svg>
  );
};

const StyledDynamicEventLegendArea = muiStyled('div')({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #E8E8E8',
  padding: '0.3rem 0 0.3rem 0',
  backgroundColor: 'white',
});

const StyledDynamicEventLegend = muiStyled('div')({
  display: 'inline-flex',
  height: 8,
  padding: '0rem 1rem 0 1rem',
  textAlign: 'center',
  alignItems: 'center',
  userSelect: 'none',
  fontWeight: 600,
  fontSize: 12,
});

const AddonLegend = ({ legend }: { legend: { type: string; titles: Array<string> } }): JSX.Element => {
  if (!legend) return <></>;
  switch (legend.type) {
    case 'dynamic':
      if (!Array.isArray(legend.titles)) return <></>;
      return (
        <StyledDynamicEventLegendArea>
          {legend.titles.length > 1 && (
            <StyledDynamicEventLegend style={{ color: '#636262' }}>
              <span style={{ position: 'relative', bottom: 1 }}>{`(${legend.titles[1]})`}</span>
            </StyledDynamicEventLegend>
          )}
          <StyledDynamicEventLegend
            style={{
              backgroundColor: 'rgb(4, 90, 139, 0.5)',
              borderTop: '2px solid #045A8B',
              borderBottom: '2px solid #045A8B',
              color: 'white',
            }}
          >
            <span style={{ position: 'relative', bottom: 1 }}>{legend.titles[0]}</span>
          </StyledDynamicEventLegend>
        </StyledDynamicEventLegendArea>
      );
    default:
      return <></>;
  }
};

const StyledAddonOptionsWrapper = muiStyled('div')(
  ({ width, dimensions }: { width: number; dimensions: IGraphDimensions }) => ({
    width: `${width - dimensions.addons.divider.width}rem`,
    height: '100%',
    padding: '0 0.3rem',
    boxSizing: 'border-box',
  }),
);

const AddonOptionsArea = muiStyled('div')({
  borderTop: `1px solid ${colors.appBlue.default + '80'}`,
  '& > div:not(:last-child)': {
    marginBottom: '2rem',
  },
});

const StyledAddonOption = muiStyled('div')({
  display: 'flex',
  flexDirection: 'row',
  cursor: 'pointer',
  lineHeight: '1.3rem',
  marginTop: '0.5rem',
  padding: '0 0.3rem 0 0',
});

const AddonOptionsWrapper = muiStyled('div')(({ width }: { width: number }) => ({
  marginLeft: '0.5rem',
  width: `${width}rem`,
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  boxSizing: 'border-box',
}));

const StyledAddonOptionIcon = muiStyled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '2rem',
  minWidth: '2rem',
  height: '1.2rem',
  marginTop: '0.2rem',
});

const StyledAddonOptionTitle = muiStyled('div')(({ theme }: { theme: Theme }) => ({
  fontSize: '1.2rem',
  lineHeight: '1.2rem',
  marginLeft: '0.5rem',
  userSelect: 'none',
  fontWeight: '600',
  color: theme.palette.grey[500],
  marginTop: '0.5rem',
}));
const StyledAddonOptionOption = muiStyled('div')(({ theme }: { theme: Theme }) => ({
  alignSelf: 'center',
  fontSize: '1.2rem',
  userSelect: 'none',
  fontWeight: '600',
  color: theme.palette.grey[500],
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

// TODO
const AddonOptions = ({ options, width, color, dimensions }: IAddonOptionsProps): JSX.Element => (
  <AddonOptionsWrapper width={width}>
    <StyledAddonOptionsWrapper width={width} dimensions={dimensions}>
      <AddonOptionsArea>
        {options.map((o, i) => (
          <div key={i}>
            {o.title && <StyledAddonOptionTitle>{o.title}</StyledAddonOptionTitle>}
            {o.options?.map((oitem) => (
              <StyledAddonOption key={oitem} onClick={() => o.onOptionClick?.(oitem)}>
                <StyledAddonOptionIcon>
                  {o.type === 'radio' ? (
                    <Radio
                      checked={o.selectedOption === oitem}
                      sx={{
                        '& svg': {
                          width: '1.2rem',
                          height: '1.2rem',
                        },
                        padding: 0,
                      }}
                    />
                  ) : o.type === 'reload' ? (
                    <svg
                      style={{ cursor: 'pointer' }}
                      width="8"
                      height="12"
                      viewBox="0 0 9 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 3V4.5L6 2.5L4 0.5V2C1.79 2 0 3.79 0 6C0 6.785 0.23 7.515 0.62 8.13L1.35 7.4C1.125 6.985 1 6.505 1 6C1 4.345 2.345 3 4 3ZM7.38 3.87L6.65 4.6C6.87 5.02 7 5.495 7 6C7 7.655 5.655 9 4 9V7.5L2 9.5L4 11.5V10C6.21 10 8 8.21 8 6C8 5.215 7.77 4.485 7.38 3.87Z"
                        fill="#045A8B"
                      />
                    </svg>
                  ) : null}
                </StyledAddonOptionIcon>
                <StyledAddonOptionOption>
                  {o.optionFormatter ? o.optionFormatter(oitem) : oitem}
                </StyledAddonOptionOption>
              </StyledAddonOption>
            ))}
          </div>
        ))}
      </AddonOptionsArea>
    </StyledAddonOptionsWrapper>
    <StyledDivider color={color} dimensions={dimensions} />
  </AddonOptionsWrapper>
);

interface IAddonOptionsProps {
  options: TAddonOption[];
  width: number;
  color: string;
  dimensions: IGraphDimensions;
}

export {
  AddonWrapper,
  StyledColumn,
  StyledDivider,
  StyledTitle,
  StyledExpansionPanelTitle,
  Addon,
  AddonOptions,
  StyledExpansionPanelWrapper,
  StyledExpansionPanelTitleWrapper,
  StyledExpansionPanelArrow,
  PanelArrow,
  StyledExpansionPanelInfo,
  StyledExpansionPanelLegendWrapper,
  AddonLegend,
  ColorBox,
};
