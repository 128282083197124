import * as React from 'react';

interface IOwnProps {
  strokeColor: string;
  strokeWidth: number;
  fillColor: string;
  active?: boolean;
  priority?: 'low' | 'normal' | 'high' | undefined;
}

export const IconActivity = ({ strokeColor, strokeWidth, fillColor, active = false }: IOwnProps): JSX.Element => {
  const [hover, setHover] = React.useState<boolean>(false);

  const mouseEnter = (): void => {
    if (active) {
      setHover(true);
    }
  };

  const mouseLeave = (): void => {
    setHover(false);
  };

  return (
    <g
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      transform={`translate(${hover ? -7.8 : -7}, ${hover ? -7.8 : -7}) scale(${hover ? 1.1 : 1})`}
    >
      {/** First box to lay a white background for the colored box, which might have opacity in fill color */}
      <svg width="14" height="14" viewBox="0 0 14 14" fill="white" xmlns="http://www.w3.org/2000/svg">
        <rect width="14" height="14" fill={'white'} stroke={'white'} strokeWidth={strokeWidth} />
      </svg>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="14" height="14" fill={fillColor} stroke={strokeColor} strokeWidth={strokeWidth} />
      </svg>
    </g>
  );
};
