import { TextField, Chip, styled } from '@mui/material';
import { Autocomplete } from '@mui/material';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { equals, isNil } from 'ramda';

import { capitalize } from '../../../utility/string';
import colors from '../../../config/theme/colors';

const ListItem = styled('li')(({ selected }: { selected?: boolean }) => ({
  margin: 0,
  padding: 0,

  backgroundColor: selected ? `${colors.appBlue.default} !important` : 'white',
  color: selected ? 'white' : undefined,
}));

// Fix this later (proper check for array/object)
const textElements = (
  group: string,
  value?: { [key: string]: string } | Array<{ [key: string]: string }> | '' | null,
  label?: string,
  renderOption?: (o: any) => React.ReactNode,
): JSX.Element | JSX.Element[] =>
  value && typeof value === 'object' && !Array.isArray(value) ? (
    <div>
      {value && label && group
        ? `${(value as { [key: string]: string })[group]} - ${(value as { [key: string]: string })[label]}`
        : ''}
    </div>
  ) : Array.isArray(value) ? (
    (value as Array<{ [key: string]: string }>).map((v) => (
      <div key={v[label || '']}>{v && label && group ? `${v[group]} - ${v[label]}` : ''}</div>
    ))
  ) : value ? (
    renderOption ? (
      <div style={{ fontWeight: 600 }}>{renderOption(value)}</div>
    ) : (
      <div style={{ fontWeight: 600 }}>{value}</div>
    )
  ) : (
    <></>
  );

const acOnChange =
  (name: string, onChange?: IInputBasics['onChange']) =>
  (e: React.SyntheticEvent<Element, Event>, value: any): void => {
    if (onChange) {
      onChange({ [name]: value });
    }
  };

const SelectElements = ({
  name,
  options,
  groupID,
  value,
  labelID,
  onChange,
  placeholder,
  width,
  multiple,
  onInputChange,
  getOptionLabel,
  renderOption,
  groupBy,
  renderGroup,
}: IInputBasics & IAutoCompleteSelect): JSX.Element => {
  const nullValue = !!(
    value === '' ||
    (value &&
      typeof value === 'object' &&
      !Array.isArray(value) &&
      Object.values(value).every((v) => v === '' || v === null || v === undefined))
  );

  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  return (
    <Autocomplete
      value={value && !nullValue ? value : multiple ? [] : (null as any)}
      options={options}
      renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
      style={{ width: `${width || 20}rem` }}
      getOptionLabel={
        getOptionLabel
          ? getOptionLabel
          : (option): string => (labelID && !isNil(option[labelID]) ? capitalize(option[labelID]) : '')
      }
      onChange={acOnChange(name, onChange)}
      isOptionEqualToValue={(option, value) => equals(option, value)}
      groupBy={(option) => {
        return !isNil(option[groupID]) && groupBy
          ? groupBy(option)
          : !isNil(option[groupID])
            ? capitalize(option[groupID])
            : '';
      }}
      clearText={fm('general.clear')}
      renderGroup={renderGroup}
      renderTags={(value, getTagProps): JSX.Element[] =>
        value.map((option, i) =>
          labelID && !isNil(option[labelID]) ? (
            <div key={(!isNil(option[labelID]) ? option[labelID] : '') + i} style={{ width: '100%' }}>
              <Chip
                color="primary"
                label={capitalize(option[labelID])}
                {...getTagProps({ index: i })}
                style={{ marginBottom: '0.5rem' }}
              />
            </div>
          ) : (
            <></>
          ),
        )
      }
      renderOption={
        renderOption
          ? (p: React.HTMLAttributes<HTMLLIElement>, o: any, { selected }) => (
              <ListItem {...p} selected={selected}>
                {renderOption(o)}
              </ListItem>
            )
          : undefined
      }
      multiple={multiple}
      onInputChange={onInputChange}
    />
  );
};

const AutocompleteSelect = ({
  editing = false,
  value,
  groupID,
  labelID,
  renderOption,
  ...props
}: IInputBasics & IAutoCompleteSelect): JSX.Element =>
  !editing ? (
    <div>{textElements(groupID, value, labelID, renderOption)}</div>
  ) : (
    <SelectElements groupID={groupID} value={value} labelID={labelID} renderOption={renderOption} {...props} />
  );

export default AutocompleteSelect;
