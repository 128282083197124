import * as React from 'react';
import GraphMenu from './GraphMenu';
import Graphs from './Graphs';
import Settings from './Settings';
import { styled as muiStyled } from '@mui/material';
import {
  IDashboardGraphProps,
  IData,
  IGraphData,
  IGraphDimensions,
  TGraphScale,
  TGraphSettings,
} from 'Components/sq-graphics/interfaces';
import HistoryTabs, { HistoryTabsContext } from 'Components/HistoryTabs';

const StyledGraphContainer = muiStyled('div')(({ dimensions }: { dimensions: IGraphDimensions }) => ({
  width: '100%',
  minHeight: '30rem',
  display: 'flex',
  flexDirection: 'row',
  boxSizing: 'border-box',
  marginTop: `${dimensions.graphs.marginTop}rem`,
  marginBottom: `${dimensions.graphs.marginBottom}rem`,
}));

const StyledGraphColumn = muiStyled('div')(({ padding, width }: { padding?: string; width: number }) => ({
  width: `${width}rem`,
  height: 'auto',
  padding: padding,
  boxSizing: 'border-box',
}));

const parseGraphHeight = (
  data: IGraphData | undefined,
  dimensions: IGraphDimensions,
  platform: Platform | null,
): number => {
  if (platform && dimensions.graphHeights[platform])
    return dimensions.graphHeights[platform] || dimensions.graphHeights.default;

  if (!data) return dimensions.graphHeights.default;
  if (data.data.find((d) => 'type' in d && d.type === 'heatGraph'))
    return ((data.scale?.customScale || []).length - 1) * 3;

  return dimensions.graphHeights.default;
};

const GraphArea = ({
  data,
  referenceData,
  allGraphData,
  graphDataGroups,
  graphMenuSettings,
  xPoint,
  yPoint,
  getYTicks,
  selLeftMenu,
  setLeftMenu,
  setReferenceGraphData,
  settings,
  timeframeWidth,
  dimensions,
  platform,
}: IOwnProps): JSX.Element => {
  return (
    <StyledGraphContainer dimensions={dimensions}>
      {/** TODO näitä dimensions-conffeja pitäis viel parannella */}
      <StyledGraphColumn
        width={dimensions.leftColumn.width - dimensions.graphs.scaleArea.width}
        // TODO: Tää paddingi pitäis viedä dimensions-conffiin
        padding="0 1rem 0 0"
      >
        <GraphMenu
          graphData={allGraphData}
          graphDataGroups={graphDataGroups}
          graphMenuSettings={graphMenuSettings}
          selLeftMenu={selLeftMenu}
          setLeftMenu={setLeftMenu}
          selRefGraphs={Object.keys(referenceData)}
          setReferenceGraphData={setReferenceGraphData}
          referenceGraphsBelowEachOther={settings.drawReferenceGraphsBelowEachOther}
          dimensions={dimensions}
        />
      </StyledGraphColumn>
      <StyledGraphColumn width={timeframeWidth + 2 * dimensions.graphs.scaleArea.width}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {data ? (
            <>
              {data.data.every((d) => 'data' in d) ? (
                <>
                  <div
                    style={{
                      marginLeft: `${dimensions.graphs.scaleArea.width}rem`,
                      width: `calc(100% - ${dimensions.graphs.scaleArea.width * 2}rem)`,
                    }}
                  >
                    <HistoryTabs isGraph={true}>
                      {(data.data as Array<{ data: IData[]; id: string }>).map((d: { data: IData[]; id: string }) => {
                        const title = d.data
                          ?.find((dd) => dd.dataPoints.find((dp) => dp.id.includes(d.id)))
                          ?.dataPoints.map((point) => point.additionalValue)[0];
                        return {
                          id: d.id,
                          title: <>{title ?? d.id}</>,
                          content: (
                            <HistoryTabsContext.Consumer>
                              {({ selected }) => (
                                <div style={{ marginLeft: `-${dimensions.graphs.scaleArea.width}rem` }}>
                                  <Graphs
                                    height={parseGraphHeight(data, dimensions, platform)}
                                    data={data}
                                    xPoint={xPoint}
                                    yPoint={yPoint}
                                    getYTicks={getYTicks}
                                    referenceData={
                                      !settings.drawReferenceGraphsBelowEachOther && Object.values(referenceData).length
                                        ? Object.values(referenceData)[0]
                                        : undefined
                                    }
                                    drawGraphTitles={!!Object.keys(referenceData).length}
                                    graphSettings={settings.graphSettings}
                                    timeframeWidth={timeframeWidth}
                                    dimensions={dimensions}
                                    selectedTab={selected}
                                  />
                                </div>
                              )}
                            </HistoryTabsContext.Consumer>
                          ),
                        };
                      })}
                    </HistoryTabs>
                  </div>
                </>
              ) : (
                <Graphs
                  height={parseGraphHeight(data, dimensions, platform)}
                  data={data}
                  xPoint={xPoint}
                  yPoint={yPoint}
                  getYTicks={getYTicks}
                  referenceData={
                    !settings.drawReferenceGraphsBelowEachOther && Object.values(referenceData).length
                      ? Object.values(referenceData)[0]
                      : undefined
                  }
                  drawGraphTitles={!!Object.keys(referenceData).length}
                  graphSettings={settings.graphSettings}
                  timeframeWidth={timeframeWidth}
                  dimensions={dimensions}
                />
              )}
            </>
          ) : (
            <svg
              style={{ marginLeft: `${dimensions.graphs.scaleArea.width}rem` }}
              width={timeframeWidth * 10}
              height={300}
              x="0"
              y="0"
              viewBox={`0 0 ${timeframeWidth * 10} ${300}`}
            >
              <rect x="0" y="0" width="100%" height="100%" fill="#F5F5F5" opacity="1" />
            </svg>
          )}
          {settings.drawReferenceGraphsBelowEachOther &&
            Object.entries(referenceData).map(([key, rData]) => (
              <div key={key} style={{ marginTop: '1rem' }}>
                <Graphs
                  height={parseGraphHeight(rData, dimensions, platform)}
                  data={rData}
                  xPoint={xPoint}
                  yPoint={yPoint}
                  getYTicks={getYTicks}
                  drawGraphTitles={!!Object.keys(referenceData).length}
                  graphSettings={settings.graphSettings}
                  timeframeWidth={timeframeWidth}
                  dimensions={dimensions}
                />
              </div>
            ))}
        </div>
      </StyledGraphColumn>
      <StyledGraphColumn
        width={dimensions.rightColumn.width - dimensions.graphs.scaleArea.width}
        padding="0 1rem 0 1rem"
      >
        <Settings />
      </StyledGraphColumn>
    </StyledGraphContainer>
  );
};

interface IOwnProps {
  data: IGraphData | undefined;
  referenceData: { [key: string]: IGraphData };
  allGraphData: IDashboardGraphProps['graphData'];
  graphDataGroups: IDashboardGraphProps['graphDataGroups'];
  graphMenuSettings: IDashboardGraphProps['graphMenuSettings'];
  xPoint: (date: Date | undefined) => number | undefined;
  yPoint: (value: string | number, scale: TGraphScale, graphHeight: number) => number | undefined;
  getYTicks: (scale: TGraphScale | undefined, graphHeight: number) => { y: number; value: string }[];
  selLeftMenu: string;
  setLeftMenu: (key: string) => void;
  setReferenceGraphData: (key: string) => void;
  settings: Required<TGraphSettings>;
  timeframeWidth: number;
  dimensions: IGraphDimensions;
  platform: Platform | null;
}

export default GraphArea;
