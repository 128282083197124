import { Container, Item } from 'Components/Grid';
import * as React from 'react';
import styled from 'styled-components';

import colors from '../../../config/theme/colors';
import Tooltip from '../../ToolTip';

const StyledRadioBox = styled.div`
  height: 3rem;
  text-align: center;
  border: 1px ${colors.primary} solid;
  cursor: pointer;
  &:hover {
    background-color: rgba(4, 90, 139, 0.08);
  }
`;

const StyledLabel = styled.div`
  height: 100%;
  color: ${colors.primary};
  font-size: 1.5rem;
  font-weight: 600;
`;

const formatValue = (value: string | number | boolean): string | boolean | number => {
  return value === 'true'
    ? true
    : value === 'false'
      ? false
      : typeof value === 'string' && !isNaN(parseInt(value)) && value.includes('.')
        ? parseFloat(value)
        : value;
};

const radioOnChange =
  (
    name: string,
    clickedValue: string | number | boolean,
    currentValue?: string | number | boolean,
    onChange?: IInputBasics['onChange'],
  ) =>
  (): void => {
    // Clear value if the same one is clicked
    const value = clickedValue === currentValue ? null : formatValue(clickedValue);
    onChange && onChange({ [name]: value });
  };

const RadioButtonRows = ({
  editing = false,
  name,
  onChange,
  value,
  options,
  optionFormatter,
  tooltips,
  height,
  width,
}: IInputBasics & IRadioButtonRows): JSX.Element | JSX.Element[] => {
  const optionSelectButton = (label: string | number | boolean, index: number): JSX.Element => {
    return (
      <StyledRadioBox
        style={{
          backgroundColor: formatValue(label) === value ? 'rgba(4, 90, 139, 0.28)' : undefined, // 0.28 for better contrast
          height: height ? height + 'rem' : undefined,
          minWidth: width ? width + 'rem' : undefined,
          margin: index % 2 ? '0 -0.1rem' : undefined, // Overlap borders
        }}
        onClick={radioOnChange(name, label, value, onChange)}
      >
        <StyledLabel>
          <Container style={{ height: '100%' }} alignItems="center" justifyContent="center">
            <Item style={{ margin: '0rem 1rem' }}>{optionFormatter ? optionFormatter(label) : label}</Item>
          </Container>
        </StyledLabel>
      </StyledRadioBox>
    );
  };

  return editing ? (
    <Container>
      {Array.isArray(options) &&
        options.map((o, i) => (
          <Item key={`${o}`}>
            {tooltips && tooltips[i] && tooltips[i].index === i ? (
              <Tooltip title={''} description={tooltips[i].message} content={optionSelectButton(o, i)} hover={true} />
            ) : (
              optionSelectButton(o, i)
            )}
          </Item>
        ))}
    </Container>
  ) : (
    <React.Fragment>{optionFormatter && value ? optionFormatter(value) : value}</React.Fragment>
  );
};

export default RadioButtonRows;
