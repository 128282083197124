/**
 * Editing event component. Render form components for events
 */

import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import ActionButton from '../ActionButton';
import { Container, Item } from '../Grid';

import colors from '../../config/theme/colors';

const StyledCancel = styled.a`
  font-size: 1.6rem;
  font-weight: 600;
  cursor: pointer;
  color: ${colors.primary};
  text-transform: uppercase;
  margin-right: 2rem;
`;

const EditingEvent = ({
  editingElements,
  thisIndex,
  thisOnChange,
  cancel,
  save,
  saveDisabled,
  saveDisabledMessage,
}: IOwnProps): JSX.Element => (
  <div style={{ backgroundColor: colors.lightestGray, padding: '2rem 2rem 2rem 5rem' }}>
    {editingElements(thisIndex, thisOnChange)}
    <Container alignItems="center" justifyContent="flex-end">
      <Item>
        {cancel && (
          <StyledCancel onClick={cancel}>
            <FormattedMessage id="general.cancel" />
          </StyledCancel>
        )}
      </Item>
      <Item>
        <ActionButton
          text={'general.accept'}
          onClick={() => save()}
          width={12}
          height={3}
          fontSize={16}
          disabled={saveDisabled}
          disabledTooltip={
            typeof saveDisabledMessage === 'string' ? <span>{saveDisabledMessage}</span> : saveDisabledMessage
          }
        />
      </Item>
    </Container>
  </div>
);

interface IOwnProps {
  editingElements: (i: number, onChange: IFormData['onChange']) => JSX.Element;
  thisIndex: number;
  thisOnChange: IFormData['onChange'];
  cancel?: () => void;
  save: () => void;
  saveDisabled?: boolean;
  saveDisabledMessage?: string | JSX.Element;
}

export default EditingEvent;
