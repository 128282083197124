import * as React from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector as useSelector } from 'Store/index';
import { find, includes, without } from 'ramda';

import DashboardGraph from '../../components/DashboardGraph';
import ContentWrapper from '../../components/ContentWrapper';
import Tile from '../../components/DashboardTile';
import { Container, Item } from '../../components/Grid';
import DocumentLoader from '../../containers/DocumentLoader/index';
import ErrorContainer from '../../containers/ErrorContainer';

import { styleDash } from '../../config/theme/componentTheme';
import { filteredDocumentTypes } from '../../config/generalSettings';

import { getEnabledDocuments } from '../../utility/randomUtil';
import { isEnded } from '../../utility/isEnded';

import Icon from './Icon';
import MyDash from 'Routes/MyService/Dash';

import { getActiveTreatments, getRightDocumentsForPlatform } from '../Treatment/utils/index';
import { withCapabilities } from 'Containers/CapabilityHandler';
import { IGroupACLMapping } from '../../config/definitions/accessControl';
import { checkMyServCapability, isMyServiceAvailable } from 'Routes/MyService/util';

// Find if there are any documents being edited
const editingDocuments = (documents: IControlProps[], n: string, session?: ISessionStore): boolean => {
  const docTypes = session ? getEnabledDocuments(session) : [];
  const thisDocTypes = find((t) => t.name === n, docTypes);

  // True, if editing document is of the same type as the tile, or if it's included in the subTypes
  return !!find(
    (d) => d._editing && (d._type === n || includes(d._type, thisDocTypes?.subTypes || [])),
    documents || [],
  );
};

// Find if there are no documents
// NOTE: No documents message is only shown if there is NO documents (this does not include ended medication/treatments/etc)
const noDocs = (
  documents: IControlProps[],
  n: string,
  session?: ISessionStore,
  myappDocs?: IControlProps[],
): boolean => {
  const docTypes = session ? getEnabledDocuments(session) : [];
  const thisDocTypes = find((t) => t.name === n, docTypes);

  if (
    (session?.platforms?.selected === 'epilepsy' && myappDocs && n === 'seizure') ||
    (session?.platforms?.selected === 'sleepApnea' && myappDocs && n === 'background')
  ) {
    const defaultCondition = find(
      (d) => d._type === n || includes(d._type, thisDocTypes?.subTypes || []),
      documents || [],
    );
    const patientReportedSeizureFound = !!myappDocs?.find((d) => d._type === 'patientReportedSeizure');
    const sharedBgDocFound = !!myappDocs?.find((d) => d._type === 'background');
    return !(defaultCondition || patientReportedSeizureFound || sharedBgDocFound);
  }

  // True if theres no documents matching main or subType
  return !find((d) => d._type === n || includes(d._type, thisDocTypes?.subTypes || []), documents || []);
};

const getDocumentsLength = (platform?: string, documentType?: string, documents?: IControlProps[]) => {
  if (!documents || documents.length < 1) return null;
  if (platform === 'sma' || platform === 'dmd') {
    if (documentType === 'medication') {
      const medDocs = documents as IMedication[];
      const activeMeds = medDocs.filter((m) => m._type === 'medication' && m.hasEnded?.[0] !== true);
      return activeMeds && activeMeds.length;
    }
    if (documentType === 'therapy') {
      const therapyDocs = documents as ITherapy[];
      const activeTherapies = therapyDocs.filter((t) => !isEnded(t.endDate));
      return activeTherapies && activeTherapies.length;
    }
    return null;
  }
  if (platform === 'ms') {
    if (documentType === 'therapy') {
      const therapyDocs = documents as ITherapy[];
      const activeTherapies = therapyDocs.filter((t) => !isEnded(t.endDate));
      return activeTherapies && activeTherapies.length;
    }
    if (documentType === 'comorbidity') {
      const comorbidityDocs = documents as IComorbidity[];
      const active = comorbidityDocs.filter((t) => !isEnded(t.endDate));
      return active && active.length;
    }
    return null;
  }
  if (platform === 'epilepsy') {
    if (documentType === 'surgicalTreatment') {
      return documents.length;
    }
    if (documentType === 'otherSymptoms') {
      const docs = documents as IOtherSymptoms[];
      const activeDocs = docs.filter((t) => !isEnded(t.endDate));
      return activeDocs && activeDocs.length;
    }
    if (documentType === 'medication') {
      const medDocs = documents as IMedication[];
      const activeMeds = medDocs.filter((m) => m._type === 'medication' && m.hasEnded?.[0] !== true);
      return activeMeds && activeMeds.length;
    }
    return null;
  }
  if (platform === 'sleepApnea') {
    if (documentType === 'diagnosis') {
      const sleepApneaAndRespiratoryFailureDocs = (
        documents as (IDiagnosis | ISleepApneaFirstVisit | IRespiratoryFirstVisit)[]
      ).filter(
        (d) =>
          'diagnosis' in d &&
          ['G47.3', 'J96.1', 'J96.9', 'sleepApneaSuspicion', 'respiratoryFailureSuspicion'].includes(d.diagnosis ?? ''),
      ) as IDiagnosis[];
      return sleepApneaAndRespiratoryFailureDocs.length;
    }
    if (documentType === 'medication') {
      const medDocs = documents as IMedication[];
      const activeMeds = medDocs.filter((m) => m._type === 'medication' && m.hasEnded?.[0] !== true);
      return activeMeds && activeMeds.length;
    }
    if (documentType === 'treatment') {
      const docs = documents as any[];
      const treatmentDocsFiltered = getRightDocumentsForPlatform(docs);
      const activeDocs = getActiveTreatments(treatmentDocsFiltered);
      return activeDocs && activeDocs.length;
    }
    return null;
  }
  if (platform === 'ninmt') {
    if (documentType === 'medication') {
      const medDocs = documents as IMedication[];
      const activeMeds = medDocs.filter((m) => m._type === 'medication' && m.hasEnded?.[0] !== true);
      return activeMeds && activeMeds.length;
    }
    return null;
  }

  return null;
};

const RenderTile = ({
  section,
  contents,
  session,
  capabilityGroups = {},
}: {
  section: string;
  contents: IContents;
  session?: ISessionStore;
  capabilityGroups?: IGroupACLMapping;
}): JSX.Element => {
  const platform = session?.platforms?.selected;

  const docs = useSelector((s: IState) => s.documents?.sortedAndMergedDocuments)?.slice() ?? [];

  const myappDocs = useSelector((s: IState) => s.myapp?.sortedAndMergedDocuments);

  return (
    <Item key={section} xs="auto">
      {section === 'myService' &&
      platform &&
      !isMyServiceAvailable(platform, checkMyServCapability(platform, capabilityGroups), docs) ? (
        <ErrorContainer>
          <DocumentLoader name={section}>
            {(): JSX.Element => {
              if (checkMyServCapability(platform, capabilityGroups)) {
                return <MyDash isClickable={false} />;
              }
              return <></>;
            }}
          </DocumentLoader>
        </ErrorContainer>
      ) : (
        <Link to={'/' + section}>
          <ErrorContainer>
            <DocumentLoader name={section}>
              {(document): JSX.Element =>
                section === 'myService' ? ( // Get custom tile for My Service
                  platform && checkMyServCapability(platform, capabilityGroups) ? (
                    <MyDash isClickable={true} />
                  ) : (
                    <></>
                  )
                ) : (
                  <Tile
                    section={section}
                    content={contents[section]}
                    editing={editingDocuments(document.documents, section, session)}
                    noDocs={section === 'profile' ? false : noDocs(document.documents, section, session, myappDocs)}
                    icon={<Icon name={section} />}
                    platform={platform}
                    documentsLength={getDocumentsLength(platform, section, document.documents)}
                  />
                )
              }
            </DocumentLoader>
          </ErrorContainer>
        </Link>
      )}
    </Item>
  );
};

const TileWithCapabilities = withCapabilities(RenderTile);

const Dashboard = ({ sections, elements }: IOwnProps): JSX.Element => {
  const session = useSelector((s: IState) => s.session);
  const platform = session.platforms?.selected;
  const documents = useSelector((s: IState) => s.documents.sortedAndMergedDocuments);
  const filteredSections = filteredDocumentTypes(platform, documents);
  return (
    <>
      <ErrorContainer>
        <React.StrictMode>
          <DashboardGraph />
        </React.StrictMode>
      </ErrorContainer>
      <ContentWrapper>
        <Container justifyContent="center">
          <Item>
            <Container style={{ ...styleDash }} rowSpacing={'0rem'}>
              {without(filteredSections, sections).map((section: string) => (
                <TileWithCapabilities key={section} section={section} contents={elements.contents} session={session} />
              ))}
            </Container>
          </Item>
        </Container>
      </ContentWrapper>
    </>
  );
};

export interface IContents {
  [key: string]: JSX.Element;
}

interface IOwnProps {
  sections: string[];
  elements: {
    contents: IContents;
  };
}

export default Dashboard;
