import * as React from 'react';
import { styled as styledMui } from '@mui/material';
import {
  IDashboardGraphProps,
  IGraphData,
  IGraphDimensions,
  ILegend,
  TAddonData,
  TGraphData,
  TGraphScale,
  TGraphSettings,
  TXTick,
} from '../interfaces';
import TimeframeSelector from './TimeframeSelector';
import GraphArea from './GraphArea/index';
import AddonArea from './AddonArea/index';
import Timeline from './Timeline/index';
import { Paper } from '@mui/material';
import { AddonLegends } from './Legends';
import { scaleTime, scaleLinear } from 'd3-scale';

import { scalePoint } from 'd3';
import { areTimeframesEqual } from 'neuro-utils';
import {
  parseDimensions,
  parseTimeframeWidth,
  getXTicks,
  parseNewShiftedTimeframe,
  parseNewTimeframe,
  getYTicks,
  getTimeFrameLengthBasedOnDates,
} from '../util/calculators';
import {
  parseGraphSettings,
  parseDefaultTimeframe,
  parseDefaultLeftMenu,
  parseAndValidateAddonData,
  validateGraphData,
  filterAddonData,
  filterGraphData,
  parseSpecificAddonLegends,
} from '../util/validators';
import { injectIntl } from 'react-intl';

// TODO: nääkin pitäis siirtää johonkin järkevään paikkaan
const StyledXTicksWrapper = styledMui('div')(({ dimensions }: { dimensions: IGraphDimensions }) => ({
  position: 'absolute',
  left: `${dimensions.leftColumn.width + dimensions.leftColumn.paddingLeft}rem`,
  height: '100%',
  pointerEvents: 'none',
}));

// FUnction for checking if browser is chrome
const isChrome = (): boolean => {
  const isChromium = (window as any).chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  const isOpera = !!(window as any).opr;
  const isIEedge = winNav.userAgent.indexOf('Edge') > -1;
  const isIOSChrome = winNav.userAgent.match('CriOS');

  if (navigator.userAgent.indexOf('Chrome') > -1) {
    return true;
  }

  if (
    isIOSChrome ||
    (isChromium !== null &&
      typeof isChromium !== 'undefined' &&
      vendorName === 'Google Inc.' &&
      isOpera === false &&
      isIEedge === false)
  ) {
    return true;
  } else {
    return false;
  }
};

interface IXridProps {
  areaSelection: IOwnState['areaSelection'];
  xTicks: TXTick[];
  timeframeWidth: IOwnState['timeframeWidth'];
  dimensions: IGraphDimensions;
  useTabMargin?: boolean;
}

const XGrid = ({ xTicks, timeframeWidth, dimensions, areaSelection, useTabMargin }: IXridProps): JSX.Element => {
  return (
    <StyledXTicksWrapper dimensions={dimensions}>
      <svg x="0" y="0" width={timeframeWidth * 10} height="100%">
        {xTicks.map((xTick, i) => (
          <line
            key={`xGrid#${i}`}
            x1={xTick.x}
            x2={xTick.x}
            y1={useTabMargin ? 42 + dimensions.graphs.marginTop * 10 : 0}
            y2={
              isChrome()
                ? `calc(100% - ${(dimensions.paddingBottom + dimensions.legends.marginBottom) * 10 + 2}px)`
                : '100%'
            }
            strokeWidth={xTick.priority === 'low' ? '0.4' : '0.7'}
            stroke="#8D8D8D"
            opacity="1"
            strokeDasharray={xTick.priority === 'low' ? '10 4' : undefined}
          />
        ))}
        {areaSelection ? (
          <rect
            y={useTabMargin ? 41 + dimensions.graphs.marginTop * 10 : 0}
            height={
              isChrome()
                ? `calc(100% - ${(dimensions.paddingBottom + dimensions.legends.marginBottom) * 10 + 2}px)`
                : '100%'
            }
            x={areaSelection.start < areaSelection.end ? areaSelection.start : areaSelection.end}
            width={Math.abs(areaSelection.start - areaSelection.end)}
            opacity="0.3"
            fill="red"
            strokeWidth="2"
            stroke="red"
            strokeOpacity="0.6"
          />
        ) : null}
      </svg>
    </StyledXTicksWrapper>
  );
};

// TODO: kaikki nää iconit ja vastaavat pitäis siirtää johonkin järkevään paikkaan
const IconChange = (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="8.72168"
      y="0.928939"
      width="10"
      height="10"
      transform="rotate(45 8.72168 0.928939)"
      fill="white"
      stroke="#045A8B"
      strokeWidth="2"
    />
  </svg>
);

const IconAdverseEffect = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7" cy="7" r="6" fill="white" strokeWidth="2" stroke="#045A8B" />
  </svg>
);

const IconMultiple = (
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="0.5" width="12" height="15" fill="white" stroke="#045A8B" strokeWidth="2" />
    <path
      d="M9.12215 11H4.87215V10.05L6.50215 8.37C6.99548 7.86333 7.34215 7.46667 7.54215 7.18C7.74215 6.89333 7.84215 6.58 7.84215 6.24C7.84215 5.89333 7.74548 5.64667 7.55215 5.5C7.35882 5.35333 7.04215 5.28 6.60215 5.28C6.18882 5.28 5.73215 5.32667 5.23215 5.42L4.98215 5.46L4.91215 4.58C5.55882 4.38667 6.22882 4.29 6.92215 4.29C8.30215 4.29 8.99215 4.90333 8.99215 6.13C8.99215 6.61 8.88548 7.02667 8.67215 7.38C8.46548 7.73333 8.09215 8.16 7.55215 8.66L6.14215 10.03H9.12215V11Z"
      fill="#045A8B"
    />
  </svg>
);

const StyledPaper = styledMui(Paper)(({ width, dimensions }: { width: number; dimensions: IGraphDimensions }) => ({
  position: 'relative',
  width: `${width}rem`,
  minWidth: '89.4rem',
  minHeight: '30rem',
  paddingBottom: `${dimensions.paddingBottom}rem`,
  paddingLeft: `${dimensions.leftColumn.paddingLeft}rem`,
  paddingRight: `${dimensions.rightColumn.paddingRight}rem`,
  paddingTop: `${dimensions.paddingTop}rem`,
  boxSizing: 'border-box',
  margin: 'auto',
}));

const StyledWrapper = styledMui('div')({
  width: '100%',
  clipPath: 'inset(0 0 0 0)',
});

const StyledNoDataText = styledMui('div')({
  width: '100%',
  height: '30rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'dark-gray',
  fontSize: '8rem',
});

const renderGraphs = (
  graphData: IDashboardGraphProps['graphData'],
  addonData: IDashboardGraphProps['addonData'],
): boolean => {
  if (
    Object.keys(graphData).length === 1 &&
    Object.keys(graphData.common).length === 0 &&
    Object.keys(addonData).length === 0
  ) {
    return false;
  }
  return true;
};

const NoDataContainer = ({ width, dimensions }: { width: number; dimensions: IGraphDimensions }): JSX.Element => (
  <StyledWrapper>
    <StyledPaper width={width} dimensions={dimensions} square>
      <StyledNoDataText>No Data</StyledNoDataText>
    </StyledPaper>
  </StyledWrapper>
);

class Graph extends React.Component<IDashboardGraphProps, IOwnState> {
  public settings: Required<TGraphSettings>;
  public dimensions: IGraphDimensions;
  public addonLegends: ILegend[];
  private allData: { topData: TAddonData; graphData: TGraphData; bottomData: TAddonData };

  constructor(props: IDashboardGraphProps) {
    super(props);

    // Graph settings are parsed here
    this.settings = parseGraphSettings(props.settings);
    this.dimensions = parseDimensions(this.settings.layout);

    const timeframeWidth = parseTimeframeWidth(props.width, this.dimensions);

    // default left menu, timeframe and timeframeLength are set here
    const defaultTimeframe =
      this.settings.defaultTimeframe ||
      parseDefaultTimeframe(this.props.totalTimeframe, this.props.settings.defaultTimeframeLength);
    const defaultLeftMenu = parseDefaultLeftMenu(props.graphData);

    const { topData, bottomData } = parseAndValidateAddonData(props.addonData, this.settings.topDataAddons);
    const graphData = validateGraphData(props.graphData, this.settings);

    this.allData = { topData: topData, graphData: graphData, bottomData: bottomData };

    const selectedGraph = props.settings?.reduxStateSelectedGraph || defaultLeftMenu;
    this.state = {
      selLeftMenu: selectedGraph,
      timeframe: defaultTimeframe,
      topData: filterAddonData(topData, defaultTimeframe),
      graphData: filterGraphData(graphData, selectedGraph, defaultTimeframe),
      referenceGraphData: {},
      bottomData: filterAddonData(bottomData, defaultTimeframe),
      xTicks: getXTicks(defaultTimeframe, this.props.settings.defaultTimeframeLength, timeframeWidth),
      timeframeLength: this.props.settings.defaultTimeframeLength,
      timeframeWidth: timeframeWidth,
      areaSelection: null,
      selectingArea: false,
    };

    const fm = (id: string) => this.props.intl.formatMessage({ id });

    const sideEffectText = bottomData?.treatments?.id === 'ninmtTreatments';

    this.addonLegends = [
      ...[
        { legend: fm('graph.event'), id: 'event', icon: IconChange },
        {
          legend: sideEffectText ? fm('graph.sideEffect') : fm('graph.adverseEffect'),
          id: 'adverseEffect',
          icon: IconAdverseEffect,
        },
        { legend: fm('graph.multipleEvents'), id: 'multiple', icon: IconMultiple },
      ],
      ...parseSpecificAddonLegends(props.addonData, this.settings),
    ];

    this.setLeftMenu = this.setLeftMenu.bind(this);
    this.setReferenceGraphData = this.setReferenceGraphData.bind(this);
  }

  /**
   * Function for changing the current graph
   * @param key key of the graphData object to be shown
   */
  public setLeftMenu = (key: string): void => {
    const timeframe = areTimeframesEqual(this.state.timeframe, this.props.totalTimeframe)
      ? undefined
      : this.state.timeframe;

    this.props.setGraphSelections({ selectedGraph: key });

    this.setState({
      selLeftMenu: key,
      graphData: filterGraphData(this.allData.graphData, key, timeframe),
      referenceGraphData: {},
    });
  };

  /**
   * Function for setting reference graph
   * @param key key of the graphData object to be set as reference
   */
  public setReferenceGraphData = (key: string): void => {
    let obj: { [key: string]: IGraphData } = { ...this.state.referenceGraphData };
    const _class = Object.keys(this.allData.graphData).find((k) => !!this.allData.graphData?.[k]?.[key]) ?? 'common';
    if (!this.settings.drawReferenceGraphsBelowEachOther) {
      obj = key in obj ? {} : { [key]: this.allData.graphData[_class][key] };
      this.setState({
        referenceGraphData: obj,
      });
    } else {
      if (!(key in obj)) obj[key] = this.allData.graphData[_class][key];
      else delete obj[key];
      this.setState({
        referenceGraphData: obj,
      });
    }
  };

  /**
   * Function for calculating xPoint in current timeframe in use
   * @param d Date for which the x-point is wanted to be found
   * @param useTotalTimeframe If total timeframe is wanted to be used for calculating the x-point, mark this as true
   * @returns x-point of the date given, undefined if errors are thrown
   */
  public xPoint = (d: Date | undefined, useTotalTimeframe?: true): number | undefined => {
    if (!d) return undefined;
    const timeRange = scaleTime()
      .domain(useTotalTimeframe ? this.props.totalTimeframe : this.state.timeframe)
      .range([0, this.state.timeframeWidth * 10]);
    const val: number | undefined = timeRange(d);
    if (!(val || val === 0)) return undefined;
    return Math.round(val);
  };

  /**
   * Function for finding y-point in graph for given value. Supports several different scale types
   * @param value The value for which the y-point is wanted to be found
   * @param scale the scale in which the value should exist
   * @param graphHeight height of the graph
   * @returns y-point of the value given, undefined if errors are thrown
   */
  public yPoint = (value: number | string, scale: TGraphScale, graphHeight: number): number | undefined => {
    // TODO: util.tsx:ssä on getYTicks-funktio -> sitä ja tätä vois koittaa yhdistää järkevämmäks
    switch (scale.type) {
      case 'linear': {
        if (!scale.linearScale) return undefined;
        if (typeof value === 'string') return undefined;
        const valueRange = scaleLinear().domain(scale.linearScale).range([graphHeight, 0]);
        const val = valueRange(value);
        if (!(val || val === 0)) return undefined;
        return Math.round(val);
      }
      case 'custom': {
        if (!scale.customScale) return undefined;
        if (typeof value === 'number') return undefined;
        const valueRange = scalePoint().domain(scale.customScale).range([graphHeight, 0]);
        const val = valueRange(value);
        if (!(val || val === 0)) return undefined;
        return Math.round(val);
      }
      case 'hybridCL': {
        if (!scale.linearScale || !scale.customScale) return undefined;
        const step = scale.tickAmount
          ? graphHeight / (scale?.tickAmount + scale.customScale.length - 1)
          : graphHeight / (6 + scale.customScale.length - 1);
        const customScaleRange: [number, number] = [graphHeight, graphHeight - (scale.customScale.length - 1) * step];
        const linearScaleRange: [number, number] = [graphHeight - scale.customScale.length * step, 0];
        const customValueRange = scalePoint().domain(scale.customScale).range(customScaleRange);
        const linearValueRange = scaleLinear().domain(scale.linearScale).range(linearScaleRange);
        let val: number | undefined = undefined;
        if (typeof value === 'string') val = customValueRange(value);
        else if (typeof value === 'number' && (value || value === 0)) val = linearValueRange(value);
        if (!(val || val === 0)) return undefined;
        return Math.round(val);
      }
      case 'hybridLC': {
        if (!scale.linearScale || !scale.customScale) return undefined;
        const step = scale.tickAmount
          ? graphHeight / (scale?.tickAmount + scale.customScale.length - 1)
          : graphHeight / (6 + scale.customScale.length - 1);
        const linearScaleRange: [number, number] = [graphHeight, 0 + scale.customScale.length * step];
        const customScaleRange: [number, number] = [0 + (scale.customScale.length - 1) * step, 0];
        const linearValueRange = scaleLinear()
          .domain(scale.linearScale)
          .range(linearScaleRange)
          .nice(scale.tickAmount ?? 6);
        const customValueRange = scalePoint().domain(scale.customScale).range(customScaleRange);
        let val: number | undefined = undefined;
        if (typeof value === 'string') val = customValueRange(value);
        else if (typeof value === 'number' && (value || value === 0)) val = linearValueRange(value);
        if (!(val || val === 0)) return undefined;
        return Math.round(val);
      }
      default:
        return undefined;
    }
  };

  setNewTimeframe = (newTimeframe: [Date, Date], newTimeframeLength?: TTimeframeLengthOption) => {
    const timeframeLength = newTimeframeLength || this.state.timeframeLength;
    this.props.setGraphSelections({ timeframe: newTimeframe, timeframeLength }); // Save timeframe settings to store

    this.setState({
      timeframeLength,
      timeframe: newTimeframe,
      xTicks: getXTicks(newTimeframe, timeframeLength, this.state.timeframeWidth),
      graphData: filterGraphData(this.allData.graphData, this.state.selLeftMenu, newTimeframe),
      bottomData: filterAddonData(this.allData.bottomData, newTimeframe),
      topData: filterAddonData(this.allData.topData, newTimeframe),
    });
  };

  /**
   * Function for shifting the timeframe either backwards or forwards
   * @param direction The direction into which the timeframe is wanted to be shifted
   */
  public shiftTimeframe = (direction: 'left' | 'right'): void => {
    const newTimeframe = parseNewShiftedTimeframe(
      this.state.timeframe,
      this.props.totalTimeframe,
      this.state.timeframeLength,
      direction,
    );
    this.setNewTimeframe(newTimeframe);
  };

  /**
   * Function for changing length of the timeframe
   * @param timeframeLength The timeframe length into which it is wanted to be switched
   */
  public setTimeframeLength = (timeframeLength: IOwnState['timeframeLength']): void => {
    const newTimeframe: [Date, Date] = parseNewTimeframe(
      this.state.timeframe,
      this.props.totalTimeframe,
      timeframeLength,
    );

    this.setNewTimeframe(newTimeframe, timeframeLength);
  };

  // TODO: tutkia onko tällä mitään merkitystä
  componentDidUpdate = (prevProps: IDashboardGraphProps) => {
    if (Math.abs(this.props.width - prevProps.width) > 5) {
      const tfWidth = parseTimeframeWidth(this.props.width, this.dimensions);
      // file deepcode ignore ReactNextState: Needs rework to fix
      this.setState({
        timeframeWidth: tfWidth,
        xTicks: getXTicks(this.state.timeframe, this.state.timeframeLength, tfWidth),
      });
    }
  };

  private handleAreaSelection = (event: React.MouseEvent, type: 'down' | 'move' | 'up'): void => {
    // Prevents all kinds of stuff, such as selecting some images and opening context menus.
    event.preventDefault();

    // Area size that needs to be selected for area selection to trigger
    const selectionTreshold = 15;

    if (type !== 'down' && !this.state.selectingArea) return;

    // Get the x pos from mouse event
    const x = event.clientX;
    const y = event.clientY;

    // Get dimensions of the div that handles timeframe brushing. Return if invalid area
    const selectionContainerRect = document.getElementById('selectionContainer')?.getBoundingClientRect();
    if (!selectionContainerRect) return;
    const yArea = selectionContainerRect?.y;
    const xArea = selectionContainerRect?.x;
    const height = selectionContainerRect?.height;
    const width = selectionContainerRect?.width;
    if (!(xArea || xArea === 0) || !(yArea || yArea === 0) || !(height || height === 0)) return;

    // Set brushable area minimums and maximums
    const yAreaMin = yArea;
    const yAreaMax = yArea + height;
    const xAreaMin = 0;
    const xAreaMax = this.state.timeframeWidth * 10;

    // Adjust x to start from 0
    let aX = x - xArea - this.dimensions.leftColumn.width * 10;

    // If pressing mouse down out of the graph area, do nothing
    if ((aX < xAreaMin || aX > xAreaMax) && type === 'down') return;

    // If returnAfterSwitch is true, then no changes are made to the timeline
    let returnAfterSwitch = false;
    switch (type) {
      case 'down': {
        this.setState({ selectingArea: true, areaSelection: { start: aX, end: aX } });
        returnAfterSwitch = true;
        break;
      }
      case 'move': {
        if (aX < xAreaMin || aX > xAreaMax || y < yAreaMin || y > yAreaMax) {
          // If cursor is outside of the selectable area, then paint to the very edge
          if (aX < xAreaMin) {
            this.state.areaSelection &&
              this.setState({ areaSelection: { start: this.state.areaSelection.start, end: xAreaMin } });
          }
          if (aX > xAreaMax) {
            this.state.areaSelection &&
              this.setState({ areaSelection: { start: this.state.areaSelection.start, end: xAreaMax } });
          }

          // If cursor goes outside the event area, then reset selection (10 px margin for better working)
          const resetMargin = 10;
          if (x <= xArea + resetMargin || x >= xArea + width - resetMargin) {
            this.setState({ areaSelection: null, selectingArea: false });
          }
          returnAfterSwitch = true;
        } else {
          this.setState((prevState) => ({
            areaSelection: prevState.areaSelection
              ? { start: prevState.areaSelection.start, end: aX }
              : { start: aX, end: aX },
          }));
          returnAfterSwitch = true;
        }
        break;
      }
      case 'up': {
        const textSelection = (event.view as any | null)?.getSelection() as Selection | null;
        if (this.state.areaSelection) {
          if (
            this.state.areaSelection?.start === this.state.areaSelection.end ||
            (this.state.areaSelection?.start < this.state.areaSelection.end &&
              this.state.areaSelection?.start + selectionTreshold > this.state.areaSelection.end) ||
            (this.state.areaSelection?.start > this.state.areaSelection.end &&
              this.state.areaSelection?.start < this.state.areaSelection.end + selectionTreshold)
          ) {
            // Cancel area selection if selected area is too small or if start and end are the same
            this.setState({ selectingArea: false, areaSelection: null });
            returnAfterSwitch = true;
          } else if (textSelection?.rangeCount) {
            const range = textSelection.getRangeAt(0);
            const textRect = range.getBoundingClientRect();
            if (
              textRect.x >= selectionContainerRect.x &&
              textRect.x <= selectionContainerRect.x + width &&
              textRect.y >= selectionContainerRect.y &&
              textRect.y <= selectionContainerRect.y + height
            ) {
              // Cancel area selection if some text was selected inside its area
              this.setState({ selectingArea: false, areaSelection: null });
              returnAfterSwitch = true;
            }
          }
        }
        break;
      }
    }
    if (returnAfterSwitch) return;
    if (!this.state.areaSelection) return;

    if (aX < xAreaMin) aX = xAreaMin;
    if (aX > xAreaMax) aX = xAreaMax;
    const xStart = Math.min(this.state.areaSelection.start, aX);
    const xEnd = Math.max(this.state.areaSelection.start, aX);
    const timeRange = scaleTime()
      .domain(this.state.timeframe)
      .range([0, this.state.timeframeWidth * 10]);

    const newStaticTimeframe = getTimeFrameLengthBasedOnDates(
      timeRange.invert(xStart),
      timeRange.invert(xEnd),
      this.props.totalTimeframe,
    );

    // Clear selected/painted area
    this.setState({
      selectingArea: false,
      areaSelection: null,
    });
    // Set new timeline
    this.setNewTimeframe(newStaticTimeframe.timeFrame, newStaticTimeframe.timeFrameLength);
  };

  render() {
    if (!renderGraphs(this.props.graphData, this.props.addonData))
      return <NoDataContainer width={this.props.width} dimensions={this.dimensions} />;

    // Check whether the data is in certain format to enable top tabs
    const isTabData = (data?: IGraphData): boolean => {
      if (data?.data.every((d) => 'data' in d && d.data)) return true;
      return false;
    };

    return (
      <StyledWrapper>
        <TimeframeSelector
          metaLocalizations={this.props.metaLocalizations}
          timeframeWidth={this.state.timeframeWidth}
          xTicks={this.state.xTicks}
          timeframeSelectorsHiddenByDefault={this.settings.timeframeSelectorsHiddenByDefault}
          timeframeLengthOptions={this.settings.timeframeLengthOptions}
          timeframe={this.state.timeframe}
          totalTimeframe={this.props.totalTimeframe}
          timeframeLength={this.state.timeframeLength}
          setTimeframeLength={this.setTimeframeLength}
          shiftTimeframe={this.shiftTimeframe}
          width={this.props.width}
          xPoint={this.xPoint}
          dimensions={this.dimensions}
          reloadGraph={this.props.reloadGraph}
        />
        {/* Leave space at the bottom to render shadow */}
        <div style={{ paddingBottom: '0.2rem' }}>
          <StyledPaper
            width={this.props.width}
            dimensions={this.dimensions}
            elevation={this.props.paper ? 1 : 0}
            square
          >
            <div
              id="selectionContainer"
              onMouseDown={(event) => this.handleAreaSelection(event, 'down')}
              onMouseUp={(event) => this.handleAreaSelection(event, 'up')}
              onMouseMove={(event) => this.handleAreaSelection(event, 'move')}
            >
              <XGrid
                areaSelection={this.state.areaSelection}
                xTicks={this.state.xTicks}
                timeframeWidth={this.state.timeframeWidth}
                dimensions={this.dimensions}
                useTabMargin={isTabData(this.state.graphData)}
              />
              <AddonArea
                timeframeWidth={this.state.timeframeWidth}
                data={this.state.topData}
                xPoint={this.xPoint}
                settings={this.settings}
                dimensions={this.dimensions}
                totalTimeframe={this.props.totalTimeframe}
              />
              <GraphArea
                data={this.state.graphData}
                referenceData={this.state.referenceGraphData}
                allGraphData={this.allData.graphData}
                graphDataGroups={this.props.graphDataGroups}
                graphMenuSettings={this.props.graphMenuSettings}
                xPoint={this.xPoint}
                yPoint={this.yPoint}
                getYTicks={getYTicks}
                selLeftMenu={this.state.selLeftMenu}
                setLeftMenu={this.setLeftMenu}
                setReferenceGraphData={this.setReferenceGraphData}
                settings={this.settings}
                timeframeWidth={this.state.timeframeWidth}
                dimensions={this.dimensions}
                platform={this.props.platform}
              />
              <Timeline
                timeframeWidth={this.state.timeframeWidth}
                xTicks={this.state.xTicks}
                side="top"
                dimensions={this.dimensions}
              />
              <AddonArea
                data={this.state.bottomData}
                timeframeWidth={this.state.timeframeWidth}
                xPoint={this.xPoint}
                settings={this.settings}
                dimensions={this.dimensions}
                totalTimeframe={this.props.totalTimeframe}
              />
              {/** Do not draw the second timeline if there is only one addon. Some better rule should be invented here */}
              {Object.keys(this.state.bottomData).filter(
                (key) => (this.state.bottomData?.[key]?.addons || []).length > 0,
              ).length > 1 && (
                <Timeline
                  timeframeWidth={this.state.timeframeWidth}
                  xTicks={this.state.xTicks}
                  side="bottom"
                  dimensions={this.dimensions}
                />
              )}
              <AddonLegends
                legends={this.addonLegends}
                timeframeWidth={this.state.timeframeWidth}
                dimensions={this.dimensions}
              />
            </div>
          </StyledPaper>
        </div>
      </StyledWrapper>
    );
  }
}

interface IOwnState {
  /** Key of the selected graph menu */
  selLeftMenu: string;
  /** Currently selected timeframe */
  timeframe: [Date, Date];
  /** Variable holding the topData (data above graph) */
  topData: IDashboardGraphProps['addonData'];
  /** Variable holding data of currently selected graph menu */
  graphData: IGraphData | undefined;
  /** Variable holding graph data set as reference */
  referenceGraphData: { [key: string]: IGraphData };
  /** Variable holding the bottomData (data below graph) */
  bottomData: IDashboardGraphProps['addonData'];
  /** Variable holding the x-ticks (lines from top to bottom) */
  xTicks: TXTick[];
  /** Variable holding the info about length of timeframe (e.g. 1y, 1m, all) */
  timeframeLength: TTimeframeLengthOption;
  /** Variable holding timeframe area width in rem */
  timeframeWidth: number;

  areaSelection: { start: number; end: number } | null;
  selectingArea: boolean;
}

export default injectIntl(Graph);
