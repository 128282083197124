/**
 * MUI Grid components.
 * See: https://material-ui.com/components/grid/
 */

import { Grid, GridProps, styled } from '@mui/material';
import * as React from 'react';

export const Container = styled((other: GridProps) => <Grid container={true} {...other} />)``;

export const Item = styled((other: GridProps) => <Grid item={true} {...other} />)``;
