import * as React from 'react';

interface IOwnProps {
  strokeColor: string;
  strokeWidth: number;
  fillColor: string;
  large?: boolean;
  active?: boolean;
}

export const IconAdverseEffect = ({
  strokeColor,
  strokeWidth,
  fillColor,
  large = false,
  active = false,
}: IOwnProps): JSX.Element => {
  const [hover, setHover] = React.useState<boolean>(false);

  const mouseEnter = (): void => {
    if (active) {
      setHover(true);
    }
  };

  const mouseLeave = (): void => {
    setHover(false);
  };

  return (
    <g onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
      <circle
        cx={0}
        cy={0}
        r="5.5"
        transform={`scale(${large ? (hover ? 1.33 * 1.1 : 1.33) : 1})`}
        stroke={strokeColor}
        strokeWidth={strokeWidth * 0.8}
        fill={fillColor}
      />
    </g>
  );
};
