import { IDiseaseHistoryData } from 'Components/sq-graphics';
import { atcCodesByPlatform } from 'Routes/Medication/Document/config';

const epilepsyData = (fm: (id: string) => string): IDiseaseHistoryData => {
  const clinicalMeters = fm('graph.clinicalMeters');

  return {
    graphData: {
      common: {
        seizuresPerDay: {
          data: [],
          title: fm('seizure.seizuresPerDay'),
          scale: {
            type: 'linear',
          },
        },
        seizuresTotal: {
          data: [],
          title: fm('seizure.seizuresTotal'),
          scale: {
            type: 'linear',
          },
        },
        seizuresByType: {
          data: [],
          title: fm('seizure.seizuresByType'),
          scale: {
            type: 'linear',
          },
        },
        modifiedRankinScale: {
          data: [],
          title: 'MRS',
          scale: {
            type: 'linear',
            linearScale: [0, 6],
          },
        },
        modifiedChildrensGlobalAssessmentScale: {
          data: [],
          title: fm('functionalPerformance.modifiedChildrensGlobalAssessmentScaleShort'),
          scale: {
            type: 'linear',
            linearScale: [3, 10],
          },
        },
        engelClassification: {
          data: [],
          title: fm('surgicalTreatment.engelClassification.title'),
          scale: {
            type: 'custom',
            customScale: ['aI', 'bI', 'cI', 'dI', 'aII', 'bII', 'cII', 'dII', 'aIII', 'bIII', 'aIV', 'bIV', 'cIV']
              .reverse()
              .map((s) => fm(`surgicalTreatment.engelClassification.graph.scale.opts.${s}`)),
            formatter: (key: string): string => {
              if (key.includes(': B')) return 'B';
              if (key.includes(': C')) return 'C';
              if (key.includes(': D')) return 'D';
              return key;
            },
          },
        },
      },
    },
    graphDataGroups: {
      common: {
        groupHeader: clinicalMeters,
        open: true,
      },
    },
    addonData: {
      medications: {
        id: 'medications',
        type: 'expansionPanel',
        name: fm('medication.title'),
        expansionPanelTitleDescription: [
          {
            title: fm('graph.atc-codes'),
            style: { fontWeight: 600 },
          },
        ].concat(
          atcCodesByPlatform['epilepsy'].map((code) => {
            if (code === 'N03') {
              return {
                title: `${code}: ${fm(`graph.atcOpts.epilepsy.${code}`)}\n ${fm(
                  'graph.mgKgPerDayShown',
                )}:\n \u00A0\u00A0 ◦\u00A0\u00A0${fm('graph.lessThanOneYearOld')}\n\u00A0\u00A0 ◦\u00A0\u00A0${fm(
                  'graph.lessThan14YearsOld',
                )}`,
              };
            }
            return { title: `${code} - ${fm(`graph.atcOpts.epilepsy.${code}`)}` };
          }) as [],
        ),
        addons: [],
        expansionPanelShowAlways: true,
      },
      endedMedications: {
        id: 'endedMedications',
        type: 'expansionPanel',
        name: fm('medication.endedMedications'),
        addons: [],
      },
      medication: {
        id: 'medication',
        type: 'expansionPanel',
        name: fm('medication.title'),
        addons: [],
      },
      treatments: {
        id: 'treatments',
        type: 'expansionPanel',
        name: fm('graph.treatments'),
        addons: [],
      },
      otherSymptoms: {
        id: 'otherSymptoms',
        type: 'expansionPanel',
        name: fm('otherSymptoms.title'),
        addons: [],
      },
      contraceptionAndPregnancies: {
        id: 'contraceptionAndPregnancies',
        type: 'expansionPanel',
        name: fm('graph.contraceptionAndPregnancies'),
        addons: [],
      },
      diagnosis: {
        id: 'diagnosis',
        type: 'single',
        name: fm('diagnosis.title'),
        addons: [],
      },
      appointments: {
        id: 'appointments',
        type: 'single',
        name: fm('appointments.appointments'),
        addons: [],
      },
    },
  };
};

export default epilepsyData;
