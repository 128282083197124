import { IAddon, IData, IDataPoint, IEvent } from 'Components/sq-graphics';
import { isPartialDate, partialDateFromDate, partialDateToValue, sortPartialDate } from 'neuro-utils';
import { DateTime } from 'luxon';
import {
  calculateEQ5DTotalValue,
  calculateFSMCTotal,
  calculateFSS,
  calculateMSISTotal,
  calculateMSNQTotal,
} from 'neuro-calculation-commons';
import { bai, bdi, des, IBAI, IDES, IEQ5D, IESS, ITDCSReport, treatmentMonitoringInquiry } from 'neuro-schemas';
import { omitControlProps } from 'Utility/documentHandling';
import { ITreatmentMonitoringInquiry, Task_Progress } from 'neuro-schemas';
import { getNinmtTreatmentPeriodsDateValues } from 'Components/DashboardGraph/Utils';
import { difference, equals } from 'ramda';
import * as React from 'react';
import colors from '../../../../config/theme/colors';

export const convertPatientSelfReportEventsToTimeline = (
  docs: ({ relapseDate?: string } & IControlProps)[],
  fm: (id: string) => string,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
): Array<IAddon> | undefined => {
  if (!Array.isArray(docs) || docs.length === 0) return undefined;
  docs = docs.filter((d) => ['eq5d', 'fatigue', 'fsmc', 'msis', 'msnq', 'status', 'relapse'].includes(d._type));
  const events: IEvent[] = [];
  docs.forEach((doc) => {
    switch (doc._type) {
      case 'relapse': {
        if (!doc || !doc.relapseDate) return;
        const d = DateTime.fromISO(doc.relapseDate);
        const pd = partialDateFromDate(d.toJSDate());
        if (!isPartialDate(pd)) return;
        events.push({
          date: dateFromPartialUpdateTimeFrame(pd),
          eventType: 'selfReport',
          title: fm(`graph.selfReport.${doc._type}.title`),
          priority: 'normal',
        });
        break;
      }
      default: {
        const d = new Date(doc._cdate);
        if (!d) return;
        const pd = partialDateFromDate(d);
        if (!isPartialDate(pd)) return;
        events.push({
          date: dateFromPartialUpdateTimeFrame(pd),
          eventType: 'selfReport',
          title: fm(`graph.selfReport.${doc._type}.title`),
          priority: 'low',
        });
        break;
      }
    }
  });
  if (events.length === 0) return undefined;
  return [
    {
      id: 'selfReport',
      title: '',
      titleDescription: undefined,
      events,
    },
  ];
};

interface IStatusValues {
  prEDSS?: number;
  narcomsMobility?: number;
  narcomsMobilityVASValue?: number;
  narcomsHandFunction?: number;
  narcomsHandFunctionVASValue?: number;
  narcomsVision?: number;
  narcomsVisionVASValue?: number;
  narcomsFatigue?: number;
  narcomsFatigueVASValue?: number;
  narcomsCognition?: number;
  narcomsCognitionVASValue?: number;
  narcomsBladderBowel?: number;
  narcomsBladderBowelVASValue?: number;
  narcomsSensory?: number;
  narcomsSensoryVASValue?: number;
  narcomsSpasticity?: number;
  narcomsSpasticityVASValue?: number;
  narcomsPain?: number;
  narcomsDepression?: number;
  narcomsDepressionVASValue?: number;
  narcomsPainVASValue?: number;
  narcomsTremor?: number;
  narcomsTremorVASValue?: number;
  speechDysfagia?: number;
  speechDysfagiaVASValue?: number;
}

interface IFSMC {
  question01?: number;
  question02?: number;
  question03?: number;
  question04?: number;
  question05?: number;
  question06?: number;
  question07?: number;
  question08?: number;
  question09?: number;
  question10?: number;
  question11?: number;
  question12?: number;
  question13?: number;
  question14?: number;
  question15?: number;
  question16?: number;
  question17?: number;
  question18?: number;
  question19?: number;
  question20?: number;
}

interface IMSIS {
  physicalActivity?: number;
  grip?: number;
  carrying?: number;
  balance?: number;
  movingIndoors?: number;
  clumsiness?: number;
  stiffness?: number;
  limbHeaviness?: number;
  limbTremors?: number;
  limbSpasms?: number;
  bodyNotDoingWhatYouWant?: number;
  havingToDependOnOthers?: number;
  limitationsInHomeActivities?: number;
  stuckAtHome?: number;
  difficultiesUsingHands?: number;
  cutDownOnDailyActivitiesTime?: number;
  problemsUsingTransports?: number;
  takingLongerToDoThings?: number;
  difficultyDoingSpontaneusThings?: number;
  urgentToiletVisits?: number;
  feelingUnwell?: number;
  problemsSleeping?: number;
  mentallyFatigued?: number;
  msWorries?: number;
  feelingAnxiousOrTense?: number;
  feelingIrritable?: number;
  problemsConcentrating?: number;
  lackOfConfidence?: number;
  feelingDepressed?: number;
}

interface IFatigue {
  motivation?: number;
  exercise?: number;
  tendency?: number;
  physicalActivity?: number;
  problems?: number;
  sustainedPhysicalActivity?: number;
  duties?: number;
  disability?: number;
  life?: number;
}

interface IMSNQ {
  question01?: number;
  question02?: number;
  question03?: number;
  question04?: number;
  question05?: number;
  question06?: number;
  question07?: number;
  question08?: number;
  question09?: number;
  question10?: number;
  question11?: number;
  question12?: number;
  question13?: number;
  question14?: number;
  question15?: number;
}

interface IStatus {
  sections: IStatusValues;
}

/**
 * Convert my app msis document into valid data for graph
 *
 * - Makes sure if docs are valid type
 * - Calculate msis totals
 * - Map docs into data points
 *
 * @param {Array<IMSIS & IControlProps>} docs My app documents
 * @param {(date?: PartialDate, marker?: boolean)} dateFromPartialUpdateTimeFrame function declared at DashboardGraph index.tsx
 * @returns {Array<IData> | undefined} Data for graph or undefined
 */
export const convertMsisToGraph = (
  docs: Array<IMSIS & IControlProps>,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    const date = new Date(d._cdate);
    const values = calculateMSISTotal(d);
    const value = (values.scores?.mental ?? 0) + (values.scores?.physical ?? 0);
    if (!date || !(value || value === 0)) return;
    dataPoints.push({ id: 'fsmc', date: dateFromPartialUpdateTimeFrame(partialDateFromDate(date)), value: value });
  });
  return [{ id: 'msis', type: 'lineGraph', legend: 'MSIS-29', dataPoints }];
};

/**
 * Convert my app fsmc document into valid data for graph
 *
 * - Makes sure if docs are valid type
 * - Calculate fsmc totals
 * - Map docs into data points
 *
 * @param {Array<IFSMC & IControlProps>} docs My app documents
 * @param {(date?: PartialDate, marker?: boolean)} dateFromPartialUpdateTimeFrame function declared at DashboardGraph index.tsx
 * @returns {Array<IData> | undefined} Data for graph or undefined
 */
export const convertFsmcToGraph = (
  docs: Array<IFSMC & IControlProps>,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    const date = new Date(d._cdate);
    const values = calculateFSMCTotal(d);
    const value = (values.scores?.mental ?? 0) + (values.scores?.physical ?? 0);
    if (!date || !(value || value === 0)) return;
    dataPoints.push({
      id: 'fsmc',
      date: dateFromPartialUpdateTimeFrame(partialDateFromDate(date)),
      value: value,
      description: [
        {
          title: fm('graph.selfReport.fsmc.physicalFatigue'),
          values: values.scores?.physical || values.scores?.physical === 0 ? values.scores?.physical : '-',
        },
        {
          title: fm('graph.selfReport.fsmc.cognitiveFatigue'),
          values: values.scores?.mental || values.scores?.mental === 0 ? values.scores?.mental : '-',
        },
      ],
    });
  });
  return [{ id: 'fsmc', type: 'lineGraph', legend: 'FSMC', dataPoints }];
};

/**
 * Convert my app msnq document into valid data for graph
 *
 * - Makes sure if docs are valid type
 * - Calculate msnq totals
 * - Map docs into data points
 *
 * @param {IMyAppDocuments} docs My app documents
 * @param {(date?: PartialDate, marker?: boolean)} dateFromPartialUpdateTimeFrame function declared at DashboardGraph index.tsx
 * @returns {Array<IData> | undefined} Data for graph or undefined
 */
export const convertMsnqToGraph = (
  docs: Array<IMSNQ & IControlProps>,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
): Array<IData> | undefined => {
  if (docs.length === 0) return;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    const date = new Date(d._cdate);
    const value = calculateMSNQTotal(d).score;
    if (!date || !(value || value === 0)) return;
    dataPoints.push({ id: 'msnq', date: dateFromPartialUpdateTimeFrame(partialDateFromDate(date)), value: value });
  });
  return [{ id: 'msnq', type: 'lineGraph', legend: 'MSNQ', dataPoints }];
};

/**
 * Convert my app fatigue document into valid data for graph
 *
 * - Makes sure if docs are valid type
 * - Calculate fatigue totals
 * - Map docs into data points
 *
 * @param {Array<IControlProps>} docs My app documents
 * @param {(date?: PartialDate, marker?: boolean)} dateFromPartialUpdateTimeFrame function declared at DashboardGraph index.tsx
 * @returns {Array<IData> | undefined} Data for graph or undefined
 */
export const convertFssToGraph = (
  docs: Array<IControlProps>,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    const data: IFatigue = {};
    Object.entries(d).forEach(([k, d]) => {
      if (
        ![
          'motivation',
          'exercise',
          'tendency',
          'physicalActivity',
          'problems',
          'sustainedPhysicalActivity',
          'duties',
          'disability',
          'life',
        ].includes(k)
      ) {
        return;
      }
      data[k as keyof IFatigue] = d;
    });
    const date = new Date(d._cdate);
    const value = calculateFSS(data);
    if (!date || !(value || value === 0)) return;
    dataPoints.push({ id: 'fss', date: dateFromPartialUpdateTimeFrame(partialDateFromDate(date)), value: value });
  });
  return [{ id: 'fss', type: 'lineGraph', legend: 'FSS', dataPoints }];
};

const isStatus = (s: unknown): s is IStatus => !!s && typeof s === 'object' && Object.keys(s).includes('sections');

/**
 * Convert my app predss document into valid data for graph
 *
 * - Makes sure if docs are valid type
 * - Map docs into predss data points
 *
 * @param {Array<{ sections?: { prEDSS: number } } & IControlProps>} docs My app documents
 * @param {(date?: PartialDate, marker?: boolean)} dateFromPartialUpdateTimeFrame function declared at DashboardGraph index.tsx
 * @returns {Array<IData> | undefined} Data for graph or undefined
 */
export const convertPredssToGraph = (
  docs: Array<{ sections?: { prEDSS: number } } & IControlProps>,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
): Array<IData> | undefined => {
  if (docs.length === 0) return;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    const date = new Date(d._cdate);
    if (!date || !isStatus(d)) return;
    const value = d.sections.prEDSS;
    if (!(value || value === 0)) return;
    dataPoints.push({ id: 'predss', date: dateFromPartialUpdateTimeFrame(partialDateFromDate(date)), value: value });
  });
  return [{ id: 'predss', type: 'lineGraph', legend: 'prEDSS', dataPoints }];
};

/**
 * Convert my app EQ5D document into valid data for graph
 *
 * - Makes sure if docs are valid type
 * - Map docs into eq5d data points
 *
 * @param {Array<{ IEQ5D } & IControlProps>} docs My app documents
 * @param {(date?: PartialDate, marker?: boolean)} dateFromPartialUpdateTimeFrame function declared at DashboardGraph index.tsx
 * @returns {Array<IData> | undefined} Data for graph or undefined
 */
export const convertEq5dToGraph = (
  docs: Array<IEQ5D & IControlProps>,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
  level?: '5L' | '3L',
): Array<IData> | undefined => {
  if (docs.length === 0) return;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    const date = d.date ?? partialDateFromDate(new Date(d._cdate));
    const data: IEQ5D['sections'] | null = 'sections' in d && d?.sections ? d.sections : null;
    if (!date || !data) return;
    const value = calculateEQ5DTotalValue(data);
    if (!(value || value === 0)) return;
    dataPoints.push({
      id: 'eq5d',
      date: dateFromPartialUpdateTimeFrame(date),
      value: value < 0 ? '< 0' : value,
      title: value < 0 ? `${value}` : undefined,
      description: [
        {
          title: 'VAS',
          values: d.sections?.yourHealthToday || d.sections?.yourHealthToday === 0 ? d.sections.yourHealthToday : '-',
        },
      ],
    });
  });
  return [{ id: 'eq5d', type: 'lineGraph', legend: `EQ-5D${level ? '-' + level : ''}`, dataPoints }];
};

export const statusSectionKeys: Array<keyof IStatus['sections']> = [
  'narcomsMobility',
  'narcomsHandFunction',
  'narcomsVision',
  'narcomsFatigue',
  'narcomsCognition',
  'narcomsBladderBowel',
  'narcomsSensory',
  'narcomsSpasticity',
  'narcomsPain',
  'narcomsDepression',
  'narcomsTremor',
  'speechDysfagia',
];

/**
 * Convert my app status document into valid data for graph
 *
 * - Makes sure if docs are valid type
 * - Map docs into predss data points
 *
 * @param {IMyAppDocuments} docs My app documents
 * @param {(date?: PartialDate, marker?: boolean)} dateFromPartialUpdateTimeFrame function declared at DashboardGraph index.tsx
 * @returns {Array<IData> | undefined} Data for graph or undefined
 */
export const convertStatusToGraph = (
  docs: Array<Partial<IStatus> & IControlProps>,
  fm: (id: string) => string,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
): Array<IData> | undefined => {
  if (docs.length === 0) return;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    const data = d.sections;
    const date = new Date(d._cdate);
    if (!date || !data) return;
    statusSectionKeys.forEach((k) => {
      dataPoints.push({
        id: k,
        date: dateFromPartialUpdateTimeFrame(partialDateFromDate(date)),
        value: fm(`graph.selfReport.status.opts.${k}`),
        additionalValue: data?.[k] || data?.[k] === 0 ? data[k] : '-',
        description: fm(`graph.selfReport.status.opts.${k}`),
      });
    });
  });
  return [
    {
      id: 'status',
      type: 'heatGraph',
      legend: 'STATUS',
      dataPoints: dataPoints,
    },
  ];
};

export const convertBdiAndBaiToGraph = (
  docs: Array<(IBDI | IBAI) & IControlProps>,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
  latestNinmtTreatmentDecisionDate: Date | null,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  if (!latestNinmtTreatmentDecisionDate) return undefined;
  const bdiDataPoints: IDataPoint[] = [];
  const baiDataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    if (d._type === 'bdi') {
      const value = Task_Progress.calculateProgress('bdi', omitControlProps(d)).yielded;
      if (!value) return;
      bdiDataPoints.push({
        id: 'bdi',
        date: dateFromPartialUpdateTimeFrame(d.date),
        value: value,
        title: `${value}`,
        description: fm(`myService.ninmt.bdiTotalScoreDescOpts.${bdi.calculators.bdiDepressionDesc(value)}`),
      });
    } else if (d._type === 'bai') {
      const value = Task_Progress.calculateProgress('bai', omitControlProps(d)).yielded;
      if (!value) return;
      baiDataPoints.push({
        id: 'bai',
        date: dateFromPartialUpdateTimeFrame(d.date),
        value: value,
        title: `${value}`,
        description: fm(`myService.ninmt.baiAnxietyDescs.${bai.calculators.baiAnxietyDesc(value)}`),
      });
    }
  });
  return [
    {
      id: 'bdi',
      type: 'lineGraph',
      legend: 'BDI',
      dataPoints: bdiDataPoints,
    },
    {
      id: 'bai',
      type: 'lineGraph',
      legend: 'BAI',
      dataPoints: baiDataPoints,
    },
  ];
};

export const convertGicToGraph = (
  docs: Array<ITreatmentMonitoringInquiry & IControlProps>,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
  treatmentPeriodDocs: Array<ININMTTreatmentPeriod>,
  uiLang: Locale,
): Array<IData> | undefined => {
  if (docs.length === 0) return;
  if (treatmentPeriodDocs.length === 0) return;
  const locales = treatmentMonitoringInquiry.locales[uiLang];
  const sortedTreatmentPeriods = treatmentPeriodDocs.sort((d1, d2) => sortPartialDate(d2?.date, d1?.date));
  const xLineBreaks = sortedTreatmentPeriods
    .map((tp) => (tp?.date ? dateFromPartialUpdateTimeFrame(tp.date) : undefined))
    .filter((v) => v) as Array<Date>;

  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    if (!(d.treatmentEffectivenessEstimate || d.treatmentEffectivenessEstimate === 0)) return;
    dataPoints.push({
      id: 'gic',
      date: dateFromPartialUpdateTimeFrame(d.date),
      value: d.treatmentEffectivenessEstimate,
      title: locales[`opts.${d.treatmentEffectivenessEstimate}`],
      description: d.gic,
    });
  });
  return [
    {
      id: 'gic',
      type: 'lineGraph',
      legend: 'GIC',
      dataPoints: dataPoints,
      linegraphProps: {
        xAxisLineBreaks: xLineBreaks,
      },
    },
  ];
};

export const convertSleepToGraph = (
  treatmentMonitoringInquiries: Array<ITreatmentMonitoringInquiry & IControlProps>,
  ninmtPreInquiries: Array<ININMTPreInquiry & IControlProps>,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
  treatmentPeriodDocs: Array<ININMTTreatmentPeriod>,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (treatmentMonitoringInquiries.length === 0 && ninmtPreInquiries.length === 0) return undefined;
  if (treatmentPeriodDocs.length === 0) return;
  const sortedTreatmentPeriods = treatmentPeriodDocs.sort((d1, d2) => sortPartialDate(d2?.date, d1?.date));
  const xLineBreaks = sortedTreatmentPeriods
    .map((tp) => (tp?.date ? dateFromPartialUpdateTimeFrame(tp.date) : undefined))
    .filter((v) => v) as Array<Date>;
  const qualityPoints: IDataPoint[] = [];
  const quantityPoints: IDataPoint[] = [];

  treatmentMonitoringInquiries.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    if (d.sleep?.sleepQuality || d.sleep?.sleepQuality === 0) {
      qualityPoints.push({
        id: 'sleepQuality',
        date: dateFromPartialUpdateTimeFrame(d.date),
        value: d.sleep.sleepQuality,
        description: fm('graph.treatmentMonitoring'),
      });
    }
    if (d.sleep?.sleepQuantity || d.sleep?.sleepQuantity === 0) {
      quantityPoints.push({
        id: 'sleepQuantity',
        date: dateFromPartialUpdateTimeFrame(d.date),
        value: d.sleep.sleepQuantity,
        description: fm('graph.treatmentMonitoring'),
      });
    }
  });

  ninmtPreInquiries.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    if (d.sleep?.sleepQuality || d.sleep?.sleepQuality === 0) {
      qualityPoints.push({
        id: 'sleepQuality',
        date: dateFromPartialUpdateTimeFrame(d.date),
        value: d.sleep.sleepQuality,
        pointType: 'rect',
        description: fm('graph.preinquiry'),
      });
    }
    if (d.sleep?.sleepQuantity || d.sleep?.sleepQuantity === 0) {
      quantityPoints.push({
        id: 'sleepQuantity',
        date: dateFromPartialUpdateTimeFrame(d.date),
        value: d.sleep.sleepQuantity,
        description: fm('graph.preinquiry'),
      });
    }
  });

  return [
    {
      id: 'sleepQuantity',
      type: 'stackedBarChart',
      legend: fm('graph.sleepQuantity'),
      dataPoints: quantityPoints,
      stackedBarChartProps: {
        interval: 'daily',
      },
      extraLegends: [fm('graph.extraLegends.sleep.quantity')],
    },
    {
      id: 'sleepQuality',
      type: 'lineGraph',
      legend: fm('graph.sleepQuality'),
      dataPoints: qualityPoints,
      extraLegends: [fm('graph.extraLegends.sleep.quality'), fm('graph.extraLegends.sleep.quality2')],
      linegraphProps: {
        xAxisLineBreaks: xLineBreaks,
      },
    },
  ];
};

export const convertEpisodicSymptomsToGraph = (
  treatmentMonitoringInquiries: Array<ITreatmentMonitoringInquiry & IControlProps>,
  ninmtPreInquiries: Array<ININMTPreInquiry & IControlProps>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
  treatmentPeriodDocs: Array<ININMTTreatmentPeriod>,
  uiLang: Locale,
): Array<{ data: IData[]; id: string }> | undefined => {
  if (treatmentMonitoringInquiries.length === 0 && ninmtPreInquiries.length === 0) return undefined;
  const sortedTreatmentPeriods = treatmentPeriodDocs.sort((d1, d2) => sortPartialDate(d2?.date, d1?.date));
  const xLineBreaks = sortedTreatmentPeriods
    .map((tp) => (tp?.date ? dateFromPartialUpdateTimeframe(tp.date) : undefined))
    .filter((v) => v) as Array<Date>;
  const locales = treatmentMonitoringInquiry.locales[uiLang];

  const allSymptomsData: Array<{ data: IData[]; id: string }> = [];
  const painData: IData[] = [];
  const painAmountDataPoints: IDataPoint[] = [];
  const painDurationDataPoints: IDataPoint[] = [];
  const otherData: Array<IData[]> = [];
  const otherAmountDataPoints: Array<IDataPoint[]> = [];
  const otherDurationDataPoints: Array<IDataPoint[]> = [];

  treatmentMonitoringInquiries.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    if (!d.symptom) return;
    if (d.symptom.pain?.episodes) {
      if (d.symptom.pain.episodes.episodes === 'no') {
        // If episodes === 'no' draw point to 0 on graph
        painDurationDataPoints.push({
          id: 'painDuration',
          date: dateFromPartialUpdateTimeframe(d.date),
          value: '0',
          title: '0',
        });
      }
      d.symptom.pain.episodes.episodesInAWeek &&
        painAmountDataPoints.push({
          id: 'painAmount',
          date: dateFromPartialUpdateTimeframe(d.date),
          value: d.symptom.pain.episodes.episodesInAWeek,
          title: locales[`opts.${d.symptom.pain.episodes.episodesInAWeek}`],
          description: fm('graph.treatmentMonitoring'),
        });
      d.symptom.pain.episodes.episodesDuration &&
        painDurationDataPoints.push({
          id: 'painDuration',
          date: dateFromPartialUpdateTimeframe(d.date),
          value: d.symptom.pain.episodes.episodesDuration,
          title: locales[`opts.${d.symptom.pain.episodes.episodesDuration}`],
          description: fm('graph.treatmentMonitoring'),
        });
    }
    if (d.symptom.other) {
      d.symptom.other.forEach((o) => {
        if (!o.episodes) return;
        if (!o.symptomDescription) return;
        let allSymptomDataIndex: number;
        let isNew = false;
        if (allSymptomsData.findIndex((d) => d.id.includes(o.symptomDescription ?? '')) >= 0) {
          allSymptomDataIndex = allSymptomsData.findIndex((d) => d.id.includes(o.symptomDescription ?? ''));
        } else {
          isNew = true;
          allSymptomDataIndex = allSymptomsData.length;
        }
        if (isNew) {
          otherAmountDataPoints[allSymptomDataIndex] = [];
          otherDurationDataPoints[allSymptomDataIndex] = [];
          otherData[allSymptomDataIndex] = [];
        }
        if (o.episodes.episodes === 'no' && d.date) {
          otherDurationDataPoints[allSymptomDataIndex].push({
            id: `otherDuration${o.symptomDescription}`,
            date: dateFromPartialUpdateTimeframe(d.date),
            value: '0',
            title: '0',
          });
        }
        o.episodes.episodesInAWeek &&
          d.date &&
          otherAmountDataPoints[allSymptomDataIndex].push({
            id: `otherAmount${o.symptomDescription}`,
            date: dateFromPartialUpdateTimeframe(d.date),
            value: o.episodes.episodesInAWeek,
            title: locales[`opts.${o.episodes.episodesInAWeek}`],
          });
        o.episodes.episodesDuration &&
          d.date &&
          otherDurationDataPoints[allSymptomDataIndex].push({
            id: `otherDuration${o.symptomDescription}`,
            date: dateFromPartialUpdateTimeframe(d.date),
            value: o.episodes.episodesDuration,
            title: locales[`opts.${o.episodes.episodesDuration}`],
          });
        if (allSymptomsData[allSymptomDataIndex]?.id) {
          return;
        } else {
          allSymptomsData.push({
            data: [],
            id: `${[locales['other.title']]}: ${o.symptomDescription}`,
          });
        }
      });
    }
  });

  ninmtPreInquiries.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    if (!d.primarySymptom) return;
    if (d.primarySymptom.isSymptomEpisodic === 'no') {
      painDurationDataPoints.push({
        id: 'painDuration',
        date: dateFromPartialUpdateTimeframe(d.date),
        value: '0',
        title: '0',
        pointType: 'rect',
      });
    }
    d.primarySymptom.averageAmountOfSymptomEpisodes &&
      painAmountDataPoints.push({
        id: 'painAmount',
        date: dateFromPartialUpdateTimeframe(d.date),
        value: d.primarySymptom.averageAmountOfSymptomEpisodes,
        title: locales[`opts.${d.primarySymptom.averageAmountOfSymptomEpisodes}`],
        pointType: 'rect',
        description: fm('graph.preinquiry'),
      });
    d.primarySymptom.averageDurationOfSymptomEpisodes &&
      painDurationDataPoints.push({
        id: 'painDuration',
        date: dateFromPartialUpdateTimeframe(d.date),
        value: d.primarySymptom.averageDurationOfSymptomEpisodes,
        title: locales[`opts.${d.primarySymptom.averageDurationOfSymptomEpisodes}`],
        pointType: 'rect',
        description: fm('graph.preinquiry'),
      });
  });

  otherAmountDataPoints.forEach((oddp, i) => {
    otherData[i] = [
      ...otherData[i],
      {
        id: oddp.find((dp) => dp.id)?.id ?? '',
        dataPoints: oddp,
        type: 'stackedBarChart',
        legend: locales['other.episodesQuantity'],
        stackedBarChartProps: {
          interval: 'daily',
        },
      },
    ];
  });
  otherDurationDataPoints.forEach((oddp, i) => {
    otherData[i] = [
      ...otherData[i],
      {
        id: oddp.find((dp) => dp.id)?.id ?? '',
        dataPoints: oddp,
        type: 'lineGraph',
        useAdditionalScale: true,
        legend: locales['other.episodesDuration'],
      },
    ];
  });
  otherData.forEach((od, i) => {
    allSymptomsData[i].data = od;
  });
  painData.push({
    id: 'painAmount',
    dataPoints: painAmountDataPoints,
    type: 'stackedBarChart',
    legend: locales['other.episodesQuantity'],
    stackedBarChartProps: {
      interval: 'daily',
    },
  });
  painData.push({
    id: 'painDuration',
    dataPoints: painDurationDataPoints,
    type: 'lineGraph',
    useAdditionalScale: true,
    legend: locales['other.episodesDuration'],
    linegraphProps: {
      xAxisLineBreaks: xLineBreaks,
    },
  });
  allSymptomsData.unshift({ data: painData, id: locales['pain.title'] });
  return allSymptomsData;
};

export const convertDesToGraph = (
  docs: Array<IDES & IControlProps>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  uiLang: Locale,
): Array<IData> | undefined => {
  if (docs.length === 0) return;
  const dataPoints: IDataPoint[] = [];
  const locales = des.locales[uiLang];

  docs.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    if (d.dyspneaIntensity) {
      let numberValue;
      switch (d.dyspneaIntensity) {
        case 'canWalkAtOwnPaceRegardlessOfDistance':
          numberValue = 1;
          break;
        case 'dyspneaWhenWalkingAtOwnPaceOver100Meters':
          numberValue = 2;
          break;
        case 'dyspneaWhenWalkingAtOwnPaceAtHomeOrInTheWard':
          numberValue = 3;
          break;
        case 'dyspneaWhenMovingInBedOrGettingUp':
          numberValue = 4;
          break;
        case 'dyspneaWhenTalking':
          numberValue = 5;
          break;
        case 'dyspneaAtRest':
          numberValue = 6;
          break;
        default:
          break;
      }
      numberValue &&
        dataPoints.push({
          id: 'des',
          date: dateFromPartialUpdateTimeframe(d.date),
          value: numberValue,
          description: locales[`opts.${d.dyspneaIntensity}`],
        });
    }
  });
  return [
    {
      dataPoints: dataPoints,
      id: 'des',
      type: 'lineGraph',
      legend: 'DES',
    },
  ];
};

export const convertEssToGraph = (
  docs: Array<IESS & IControlProps>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
): Array<IData> | undefined => {
  if (docs.length === 0) return;
  const dataPoints: IDataPoint[] = [];

  docs.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    const totalScore = Task_Progress.calculateProgress('ess', omitControlProps(d)).yielded;
    if (totalScore || totalScore === 0) {
      dataPoints.push({
        id: 'ess',
        date: dateFromPartialUpdateTimeframe(d.date),
        value: totalScore,
      });
    }
  });
  return [
    {
      dataPoints: dataPoints,
      id: 'ess',
      type: 'lineGraph',
      legend: 'ESS',
    },
  ];
};

export const convertTdcsMyTreatmentsToGraph = (
  docs: Array<ITDCSReport & IControlProps>,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
  treatmentPeriodDocs: Array<ININMTTreatmentPeriod>,
): Array<IData> | undefined => {
  if (docs.length === 0) return;
  const treatmentPeriodsDateValues = getNinmtTreatmentPeriodsDateValues(treatmentPeriodDocs);
  const allTreatmentNamesDataPoints: Array<IDataPoint[]> = [];

  docs.forEach((d) => {
    if (!d.date) return;
    const docDateValue = partialDateToValue(d?.date);
    let treatmentPeriodIndex = treatmentPeriodsDateValues.findIndex(
      (dv, i) => dv <= docDateValue && (treatmentPeriodsDateValues?.[i + 1] ?? Number.MAX_SAFE_INTEGER) > docDateValue,
    );
    if (treatmentPeriodIndex < 0) treatmentPeriodIndex = 0;

    d?.treatments?.forEach((t) => {
      const treatmentName = t.name === 'other' && t.nameOther ? t.nameOther : t.name;
      const tNameDataPointIndex = allTreatmentNamesDataPoints.findIndex(
        (tndp) => tndp?.[0]?.id === `${treatmentName}-${treatmentPeriodIndex}`,
      );

      if (tNameDataPointIndex > -1) {
        d.date &&
          allTreatmentNamesDataPoints[tNameDataPointIndex].push({
            date: dateFromPartialUpdateTimeFrame(d.date),
            value: t.amountOfTreatmentSessions ?? '',
            id: `${treatmentName}-${t.specifier ?? ''}-${treatmentPeriodIndex}` ?? '',
            additionalValue: t.name === 'other' && t.nameOther ? t.nameOther : undefined,
            title: `${t.amountOfTreatmentSessions} ${fm('graph.treatmentsPerWeek')}`,
          });
      } else {
        d.date &&
          allTreatmentNamesDataPoints.push([
            {
              date: dateFromPartialUpdateTimeFrame(d.date),
              value: t.amountOfTreatmentSessions ?? '',
              id: `${treatmentName}-${t.specifier ?? ''}-${treatmentPeriodIndex}` ?? '',
              additionalValue: t.name === 'other' && t.nameOther ? t.nameOther : undefined,
              title: `${t.amountOfTreatmentSessions} ${fm('graph.treatmentsPerWeek')}`,
            },
          ]);
      }
    });
  });

  return allTreatmentNamesDataPoints.reverse().map((dp) => {
    const idSplitted = dp?.[0]?.id?.split('-');
    const legend = dp?.[0]?.additionalValue
      ? `${idSplitted?.[0]}${idSplitted?.[1].length > 0 ? ` ${idSplitted[1]}` : ''}`
      : `${fm(`tdcs.subjectOfTreatmentNames.${idSplitted?.[0]}`)}${
          idSplitted?.[1]?.length > 0 ? ` ${idSplitted[1]}` : ''
        }`;
    return {
      dataPoints: dp,
      id: `${dp?.[0].id}`,
      legend: legend,
      type: 'lineGraph',
    };
  });
};

export const convertPatientReportedMedicationsToTimeline = (
  docs: Array<(ININMTPreInquiry | ITreatmentMonitoringInquiry) & IControlProps>,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IAddon> | undefined => {
  if (docs.length === 0) return;
  const addons: Array<IAddon> = [];
  const docsSortedOldest1st = docs
    .slice()
    .sort((a, b) => a._docCreateDate - b._docCreateDate)
    .sort((a, b) => sortPartialDate(a.date, b.date));
  docsSortedOldest1st.forEach((doc, i, arr) => {
    if (!isPartialDate(doc.date)) return;
    const events: IEvent[] = [];
    const prevDocMedicationDefaults =
      i === 0
        ? undefined
        : docsSortedOldest1st[i - 1]?.medication?.map((m) => ({
            medicationName: m.medicationName,
            medicationDosage: m.medicationDosage,
          }));
    const medicationDefaults = doc?.medication?.map((m) => ({
      medicationName: m.medicationName,
      medicationDosage: m.medicationDosage,
    }));

    const modifiedMeds =
      medicationDefaults
        ?.map((md) => {
          const prevDosing = prevDocMedicationDefaults?.find((pmd) => md.medicationName === pmd.medicationName)
            ?.medicationDosage;
          if (prevDosing && prevDosing !== md.medicationDosage) return md;
          return undefined;
        })
        .filter((mm) => mm) ?? [];
    const addedMeds = difference(medicationDefaults ?? [], prevDocMedicationDefaults ?? []).filter(
      (m) => !modifiedMeds.some((m2) => equals(m, m2)),
    );
    const removedMeds = difference(prevDocMedicationDefaults ?? [], medicationDefaults ?? []).filter(
      (m) => !modifiedMeds.some((m2) => m.medicationName === m2?.medicationName),
    );

    const hasModifications =
      doc?.medication?.some((m) => m.onDemandDosingWithinWeek) ||
      (prevDocMedicationDefaults && medicationDefaults && !equals(prevDocMedicationDefaults, medicationDefaults)) ||
      addedMeds.length > 0 ||
      removedMeds.length > 0 ||
      modifiedMeds.length > 0;

    // NINMT pre-inquiry medication events are currently not included in graph, but differences with treatment monitoring are considered.
    doc._type === 'treatmentMonitoringInquiry' && hasModifications
      ? events.push({
          date: dateFromPartialUpdateTimeFrame(doc.date),
          eventType: 'modification',
          title: fm('graph.patientReportedMedication'),
          description: [
            {
              title: fm('myService.ninmt.treatmentMonitoringInquiry.addedMedications'),
              values: (
                <>
                  {addedMeds?.map((am, j) => (
                    <div
                      key={`${am?.medicationName}-${j}`}
                      style={{
                        backgroundColor: j % 2 !== 0 ? colors.lightestGray : undefined,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <span>{am?.medicationName}</span>
                      <span>{am?.medicationDosage}</span>
                    </div>
                  ))}
                </>
              ),
              condition: addedMeds.length > 0,
            },
            {
              title: fm('myService.ninmt.treatmentMonitoringInquiry.removedMedications'),
              values: (
                <>
                  {removedMeds?.map((rm, j) => (
                    <div
                      key={`${rm?.medicationName}-${j}`}
                      style={{
                        backgroundColor: j % 2 !== 0 ? colors.lightestGray : undefined,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <span>{rm?.medicationName}</span>
                      <span>{rm?.medicationDosage}</span>
                    </div>
                  ))}
                </>
              ),
              condition: removedMeds.length > 0,
            },
            {
              title: fm('myService.ninmt.treatmentMonitoringInquiry.modifiedMedications'),
              values: (
                <>
                  {modifiedMeds?.map((mm, j) => (
                    <div
                      key={`${mm?.medicationName}-${j}`}
                      style={{
                        backgroundColor: j % 2 !== 0 ? colors.lightestGray : undefined,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <span>{mm?.medicationName}</span>
                      <span>{mm?.medicationDosage}</span>
                    </div>
                  ))}
                </>
              ),
              condition: modifiedMeds.length > 0,
            },
            doc?.medication?.some((m) => m.onDemandDosingWithinWeek)
              ? {
                  title: (
                    <span style={{ paddingRight: '2.5rem' }}>
                      {fm('myService.ninmt.treatmentMonitoringInquiry.onDemandDosages')}
                    </span>
                  ),
                  values: (
                    <>
                      {doc?.medication
                        ?.filter((m) => m.onDemandDosingWithinWeek)
                        .map((em, j) => (
                          <div
                            key={j}
                            style={{
                              backgroundColor: j % 2 !== 0 ? colors.lightestGray : undefined,
                              display: 'flex',
                              justifyContent: 'space-between',
                              paddingRight: '1rem',
                            }}
                          >
                            <span>{em.medicationName}</span>
                            <span>{em.onDemandDosingWithinWeek}</span>
                          </div>
                        ))}
                    </>
                  ),
                }
              : {
                  title: fm('graph.noMedicationsTakenDuringTheWeekOnDemand'),
                },
          ],
        })
      : i > 0 && arr.slice(0, i).some((d) => Array.isArray(d.medication) && d.medication.length > 0)
        ? events.push({
            date: dateFromPartialUpdateTimeFrame(doc.date),
            title: fm('graph.noChangesInMedications'),
            eventType: 'selfReport',
            description: [
              {
                title: fm('graph.noMedicationsTakenDuringTheWeekOnDemand'),
              },
            ],
          })
        : void 0;
    events.length > 0 &&
      addons.push({
        id: 'patientReportedMedications',
        title: '',
        titleDescription: undefined,
        events: events,
        items: [],
      });

    return;
  });
  return addons.length > 0 ? addons : undefined;
};
