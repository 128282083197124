import { generateDxToPlatfMap } from '../routes/Diagnosis/utils/Diagnosis';
import { dmdDocumentTypes } from './platformSettings/dmd';
import { epilepsyAlerts, epilepsyDocumentTypes } from './platformSettings/epilepsy';
import { huntingtonDocumentTypes } from './platformSettings/huntington';
import { msDocumentTypes, msFilteredTypes } from './platformSettings/ms';
import { parkinsonDocumentTypes, parkinsonFilteredTypes, parkinsonAlerts } from './platformSettings/parkinson';
import { smaDocumentTypes } from './platformSettings/sma';
import { sleepApneaDocumentTypes, sleepApneaFilteredTypes } from './platformSettings/sleepApnea';
import { ninmtDocumentTypes, ninmtAlerts } from './platformSettings/ninmt';
import { mgravisDocumentTypes } from './platformSettings/mgravis';

/*
  Which documents/dash tiles can be loaded based on diagnosis/platform
  Some pages use multiple document types and these are defined as subTypes
  Secondary docs are supplied to pages, but their editing status doesn't affect the page
  NOTE: Order of these also defines in which order the tiles are loaded in dashboard (First commons then diagnose based)
*/
export type LoadableDocConfig = {
  name: string;
  subTypes?: string[];
  secondaryDocs?: string[];
  title?: string;
  nonNavigable?: boolean;
}[];
export const loadableDocumentTypes: { [key: string]: LoadableDocConfig } = {
  common: [{ name: 'profile' }],
  parkinson: parkinsonDocumentTypes,
  sma: smaDocumentTypes,
  dmd: dmdDocumentTypes,
  ms: msDocumentTypes,
  huntington: huntingtonDocumentTypes,
  epilepsy: epilepsyDocumentTypes,
  sleepApnea: sleepApneaDocumentTypes,
  ninmt: ninmtDocumentTypes,
  mgravis: mgravisDocumentTypes,
  // For local env, remember to modify vault-core user generation when adding new platforms (dbinitlocaldev.js)
};

// These documents are filtered from navigation after routes have been loaded
export const filteredDocumentTypes = (platform?: string, docs?: document[]): string[] => {
  const filterList = {
    parkinson: parkinsonFilteredTypes(docs),
    ms: msFilteredTypes(docs),
    sleepApnea: sleepApneaFilteredTypes(docs),
  } as { [key: string]: string[] };

  return platform ? filterList[platform] || [] : [];
};

// Get alerts for platforms
export const alerts = {
  parkinson: parkinsonAlerts,
  epilepsy: epilepsyAlerts,
  ninmt: ninmtAlerts,
} as { [key: string]: IAlert[] };

const dxToPlatfMap = generateDxToPlatfMap();
export { dxToPlatfMap as diagnosisPlatform };

export const MIRANDA_MED_INTG_SOURCE = 'miranda-medication-integration' as const;
