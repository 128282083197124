import * as React from 'react';
import { styled } from '@mui/system';
import { alpha, Theme } from '@mui/material/styles';
import { Paper, PaperProps } from '@mui/material';
import { PlayArrow, Key, Person, Error, CheckCircle } from '@mui/icons-material';
import { useIntl } from 'react-intl';

import { Container, Item } from 'Components/Grid';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import OTPDialog from './OTPDialog';
import { fetchWithOptions } from '../../../utility/fetch';
import { parseJWTFromCookie } from '../../../utility/jwtAuthTools';
import { useAppSelector as useSelector } from 'Store/index';
import { equals, includes, take } from 'ramda';
import { formatPartialDate, partialDateFromDate } from 'neuro-utils';
import colors from '../../../config/theme/colors';
import PlatformConditional from 'Components/PlatformConditional';
import theme from '../../../config/theme/muiTheme';
import Icon from '../../_Dashboard/Icon/index';
import { makeLog } from 'Utility/logger';
import { IData } from 'Components/DashboardTile/TileContentMaker/components/definitions';
import { surveysByPlatform } from '../Document/config';

const Tile = styled(({ id, ...other }: { id: string } & PaperProps) => <Paper id={id} {...other} />)(({ theme }) => ({
  width: theme.customComponents.tile.width,
  height: '29rem',
  margin: theme.customComponents.tile.margin,
  padding: `0 ${theme.customComponents.tile.padding}`,
  '&:hover': {
    backgroundColor: '#f8f8f8',
  },
  overflow: 'hidden',
  userSelect: 'none',
}));

const TileHeader = styled(Item)(({ theme }: { theme?: Theme }) => ({
  backgroundColor: theme?.customPalette.myms.primary.main && alpha(theme.customPalette.myms.primary.main, 0.05),
  margin: `0 -${theme?.customComponents.tile.padding} 0rem -${theme?.customComponents.tile.padding}`,
  padding: `0 ${theme?.customComponents.tile.padding}`,
  height: 3 + 2.5 + 1 + 'rem', // Tile header height + top padding + bottom margin
  borderBottom: `1px solid ${theme?.customPalette.myms.primary.main}`,
  color: theme?.customPalette.myms.primary.main,
  cursor: 'default',
  //...theme.customComponents.tile.header,
}));

const TileFooter = styled(Item)(({ theme }: { theme?: Theme }) => ({
  backgroundColor: theme?.customPalette.myms.primary.main && alpha(theme?.customPalette.myms.primary.main, 0.75),
  margin: `0 -${theme?.customComponents.tile.padding}`,
  padding: `0 ${theme?.customComponents.tile.padding}`,
  height: '5rem',
  cursor: 'default',
}));

const HeaderText = styled('div')({
  alignItems: 'center',
  display: 'flex',
  fontWeight: 600,
  fontSize: '1.6rem',
  textTransform: 'uppercase' as const,
});

const ButtonHeader = styled('div')(({ theme }: { theme?: Theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme?.customPalette.myms.primary.main,
  fontWeight: 600,
  fontSize: '1.4rem',
  cursor: 'pointer',
}));

const ButtonHeaderText = styled('div')(({ theme }: { theme?: Theme }) => ({
  color: theme?.customPalette.myms.primary.main && alpha(theme?.customPalette.myms.primary.main, 0.5),
}));

const Button = styled('div')(({ theme }: { theme?: Theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme?.palette.common.white,
  fontWeight: 600,
  fontSize: '1.4rem',
  cursor: 'pointer',
}));

const onClickButtonArea = async (e: React.MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();
};

const onClickButtonHeader = async () => {
  const response = await fetchWithOptions('/api/redirect/myms', { neurojwt: parseJWTFromCookie() }, { method: 'GET' });
  const parsedBody = await response.json();
  window.open(parsedBody.redirectLocation);
};

const MsServiceButtons = () => {
  const [OPTDialogOpen, setOTPDialogOpen] = React.useState(false);
  const [otpData, setOtpData] = React.useState<{ code: string; expiration: number } | undefined>(undefined);
  const { formatMessage } = useIntl();
  const getOTP = () => {
    fetchWithOptions(`/api/otpCreate`, { neurojwt: parseJWTFromCookie() }, { method: 'POST' })
      .then((res: Response) => {
        if (res.status === 200) return res.json();
        else throw res;
      })
      .then((data) => {
        setOtpData({ code: data.code, expiration: data.expires });
      })
      .catch((err) => {
        makeLog('Error', { name: 'OPT creation', message: 'Failed to create OTP' }, err);
      });
  };

  return (
    <>
      <Container alignContent={'center'}>
        <Item xs={6}>
          <Button
            onClick={() => {
              setOTPDialogOpen(true);
              getOTP();
            }}
          >
            <Key style={{ display: 'block', marginRight: '0.7rem' }} />
            {formatMessage({ id: 'myService.createOTP' })}
          </Button>
        </Item>
      </Container>
      <OTPDialog dialogOpen={OPTDialogOpen} setDialogOpen={setOTPDialogOpen} otpData={otpData} />
    </>
  );
};

const HeaderComponent = () => {
  const { formatMessage } = useIntl();
  return (
    <TileHeader onClick={onClickButtonArea}>
      <Container direction={'row'} alignItems="center" style={{ height: '100%' }}>
        <Item xs={true}>
          <HeaderText>
            <Person style={{ display: 'block', marginRight: '0.7rem' }} fontSize={'large'} />
            {formatMessage({ id: 'myService.msTitle' })}
          </HeaderText>
        </Item>
        <Item style={{ width: '13.5rem' }}>
          <ButtonHeader onClick={onClickButtonHeader}>
            <Container justifyContent={'flex-end'}>
              <ButtonHeaderText>{formatMessage({ id: 'myService.viewInfo' })}</ButtonHeaderText>
              <PlayArrow style={{ display: 'block' }} viewBox={'0 0 14 24'} />
            </Container>
          </ButtonHeader>
        </Item>
      </Container>
    </TileHeader>
  );
};

const FooterComponent = () => (
  <TileFooter onClick={onClickButtonArea}>
    <Container direction={'row'} alignItems="center" style={{ height: '100%' }}>
      <Item style={{ width: '100%' }}>
        <MsServiceButtons />
      </Item>
    </Container>
  </TileFooter>
);

const MyServTile = ({
  isClickable,
  fm,
  patientUsingStellarQMy,
}: {
  isClickable: boolean;
  fm: (id: string) => string;
  patientUsingStellarQMy: boolean;
}): JSX.Element => {
  const sentInvites = useSelector((s: IState) => s.myapp.sentInvites);
  const inviteAndTaskData: Array<IData> = [];
  const [dashData, setDashData] = React.useState<IData[]>([]);

  const taskLists = useSelector((s: IState) => s.myapp.taskLists) || [];
  const platform = useSelector((s: IState) => s.session.platforms?.selected);
  const locale = useSelector((s: IState) => s.settings?.userSettings?.uiLanguage);
  const taskUiSettings = useSelector((s: IState) => s.settings?.orgSettings?.settings?.taskUiSettings);
  const platformSettings = platform ? taskUiSettings?.[platform] : undefined;

  if (sentInvites && sentInvites.length > 0) {
    sentInvites.forEach((si, i) => {
      inviteAndTaskData.push({
        date: si.timestamp ? formatPartialDate(partialDateFromDate(new Date(si.timestamp))) : '-',
        title: fm('myService.inviteSentDash'),
        key: `inv-${i}`,
      });
    });
  }

  React.useEffect(() => {
    if (taskLists.length > 0) {
      const currPlatformTasks = taskLists.filter(
        (t) => t?.tasks?.every((t) => platform && surveysByPlatform[platform]?.includes(t)),
      );
      currPlatformTasks.forEach((t, i) => {
        const taskListDashData = {
          date: t.deadline ? formatPartialDate(t.deadline) : '-',
          title: `${fm('myService.taskList')}: ${t.title ?? '-'}`,
          key: `${t.title}-${i}`,
        };
        if (includes<IData>(taskListDashData, inviteAndTaskData)) return;
        inviteAndTaskData.push({
          date: t.deadline ? formatPartialDate(t.deadline) : '-',
          title: `${fm('myService.taskList')}: ${
            t.title && locale
              ? platformSettings?.[t.title]?.titleLocalized[locale]
                ? platformSettings[t.title]?.titleLocalized[locale]
                : platformSettings?.[t.title]?.titleLocalized['fi']
                  ? platformSettings[t.title]?.titleLocalized['fi']
                  : t.title === 'other'
                    ? fm('myService.opts.other')
                    : t.title
              : '-'
          }`,
          key: `${t.title}-${i}`,
        });
      });
    }
  }, [taskLists]);

  React.useEffect(() => {
    if (equals(dashData, inviteAndTaskData) || dashData.length > inviteAndTaskData.length) return;
    setDashData(
      inviteAndTaskData.sort((a, b) => {
        if (a.date && b.date && a.date !== '-' && b.date !== '-') {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        } else if (a.date === '-') {
          return 1;
        } else if (b.date === '-') {
          return -1;
        } else return 0;
      }),
    );
  }, [inviteAndTaskData]);

  return (
    <Container
      direction={'column'}
      style={{
        paddingTop: theme.customComponents.tile.padding,
        paddingBottom: theme.customComponents.tile.padding,
      }}
      rowSpacing={'0rem'}
      height={'100%'}
    >
      <Item xs={2} style={{ color: colors.myms.primary }}>
        <HeaderText>
          <div
            style={{
              marginRight: '1rem',
              background: `linear-gradient(${colors.primary}, #048B82)`,
              width: '3.8rem',
              height: '3.8rem',
              borderRadius: '5rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Icon name={'myServiceV2'} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '1.6rem' }}>
            <span style={{ textTransform: 'none', fontSize: theme.typography.fontSize }}>
              {fm('myService.appName')}
            </span>
            <span>{fm('myService.titleShort')}</span>
          </div>
        </HeaderText>
      </Item>
      {patientUsingStellarQMy ? (
        <Item
          xs={8}
          style={
            !isClickable
              ? { textAlign: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column' }
              : { paddingTop: '1rem' }
          }
        >
          {!isClickable ? (
            <>
              <div style={{ fontWeight: 600, color: colors.tertiaryText, fontSize: '2rem' }}>
                {fm(`myService.${platform}.tileNotClickableTitle`)}
              </div>
              <div style={{ color: colors.tertiaryText, lineHeight: '1.6rem' }}>
                {fm(`myService.${platform}.tileNotClickableInfo`)}
              </div>
            </>
          ) : dashData.length > 0 ? (
            <TileContentMaker type="recentList" data={dashData?.slice(0, 4)} />
          ) : (
            <Container justifyContent="center" alignItems="center">
              <Item style={{ fontSize: 36, color: colors.lightGray, marginTop: '3.7rem' }}>{fm('general.noData')}</Item>
            </Container>
          )}
        </Item>
      ) : (
        <Item
          xs={8}
          style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
        >
          <div style={{ fontWeight: 600, color: colors.tertiaryText, fontSize: '2rem' }}>
            {fm(!isClickable ? `myService.${platform}.tileNotClickableTitle` : 'myService.sendInvite')}
          </div>
          <div style={{ color: colors.tertiaryText, lineHeight: '1.6rem' }}>
            {fm(!isClickable ? `myService.${platform}.tileNotClickableInfo` : 'myService.sendInviteInfo')}
          </div>
        </Item>
      )}
      <Item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
        {!patientUsingStellarQMy ? (
          <>
            <Error style={{ color: colors.warning.default, margin: '0 0.5rem 0 0.3rem' }} />
            <span style={{ color: colors.tertiaryText, fontWeight: 600 }}>
              {fm('myService.patientNotUsingMyService')}
            </span>
          </>
        ) : (
          <>
            <CheckCircle style={{ color: '#7BA015', margin: '0 0.5rem 0 0.3rem' }} />
            <span style={{ color: colors.tertiaryText, fontWeight: 600 }}>{fm('myService.patientUsingMyService')}</span>
          </>
        )}
      </Item>
    </Container>
  );
};

/**
 * Represents MyMS data. Types don't exist in that project! :D
 *
 * Not to be confused with `IRelapse` defined in `src/config/definitions/documents/ms.d.ts`.
 */
type TMyMSRelapse = {
  /**
   * ISO format date string.
   *
   * @example "2024-03-31T21:00:00.000Z"
   */
  relapseDate: string;
};
function isTMyMSRelapse(n: unknown): n is TMyMSRelapse & IControlProps {
  return typeof n === 'object' && !Array.isArray(n) && n !== null && '_type' in n && n._type === 'relapse';
}

const MyDash = ({ isClickable }: { isClickable: boolean }): JSX.Element => {
  const myDocuments: IControlProps[] =
    useSelector<IControlProps[]>((s: IState) => s.myapp.sortedAndMergedDocuments) || [];
  const locale: Locale = useSelector<Locale>((s) => s.settings.userSettings.uiLanguage ?? 'fi');

  const questionnaires = myDocuments
    .filter(
      (d) =>
        d._type !== 'relapse' && ['fatigue', 'msis', 'relapse', 'eq5d', 'fsmc', 'msnq', 'status'].includes(d._type),
    )
    .sort((a, b) => b._cdate - a._cdate);
  const relapses = (myDocuments as unknown[])
    .filter<TMyMSRelapse & IControlProps>(isTMyMSRelapse)
    .sort((a: TMyMSRelapse, b: TMyMSRelapse) => +new Date(b.relapseDate) - +new Date(a.relapseDate));

  const twoMostRecentQuestionnaires = take(2, questionnaires);
  const mostRecentRelapse = take(1, relapses);

  const patientMySQStatus = useSelector((s: IState) => s.myapp.mysqUserId);

  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });
  return (
    <>
      <Tile id="myService" elevation={1} square={true}>
        <PlatformConditional platform={'ms'}>
          <Container direction={'column'} style={{ height: '100%' }} rowSpacing={'0rem'}>
            <HeaderComponent />
            <Item xs={true}>
              {Array.isArray(myDocuments) && myDocuments.length > 0 ? (
                <TileContentMaker
                  type="listWithSubheaders"
                  data={{
                    [formatMessage({ id: 'myService.latestQueries' })]: {
                      type: 'recentList',
                      data: twoMostRecentQuestionnaires.map((d) => ({
                        date: new Date(d._cdate).toLocaleDateString(locale),
                        title: formatMessage({ id: 'myService.documents.' + d?._type }),
                        key: d?._id,
                      })),
                    },
                    [formatMessage({ id: 'myService.relapseSuspicion' })]: {
                      type: 'specificList',
                      data: mostRecentRelapse.map((d) => ({
                        date: new Date(d.relapseDate).toLocaleDateString(locale),
                        title: formatMessage({ id: 'myService.documents.' + 'relapse' }),
                        key: d?._id,
                      })),
                    },
                  }}
                />
              ) : (
                <Container style={{ height: '100%' }} justifyContent="center" alignItems="center">
                  <Item style={{ fontSize: 36, color: colors.lightGray, marginTop: '-2rem' }}>
                    {formatMessage({ id: 'myService.noData' })}
                  </Item>
                </Container>
              )}
            </Item>
            <FooterComponent />
          </Container>
        </PlatformConditional>
        <PlatformConditional platform={['ninmt', 'sleepApnea', 'epilepsy']}>
          <MyServTile isClickable={isClickable} fm={fm} patientUsingStellarQMy={!!patientMySQStatus} />
        </PlatformConditional>
      </Tile>
    </>
  );
};

export default MyDash;
