/**
 * Create tabs for different history views
 */
import { exists } from 'neuro-utils';
import * as React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import colors from '../../config/theme/colors';
import { styleDocument } from '../../config/theme/componentTheme';
import { Container, Item } from '../Grid';
import { AddCircle } from '@mui/icons-material';
import { equals } from 'ramda';
import { styled } from '@mui/material';

const TabContainer = styled('div')``;
const TabsArea = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isGraph',
})`
  width: 100%;
  margin: ${(props: { isGraph?: boolean }): string =>
    props.isGraph ? '0 0 1rem 0;' : `0rem -${styleDocument.padding} 4rem -${styleDocument.padding};`};
  border-bottom: 1px solid ${colors.gray};
  padding: ${(props: { isGraph?: boolean }): string => (props.isGraph ? '0rem 1rem' : `0 ${styleDocument.padding};`)};
  box-sizing: ${(props: { isGraph?: boolean }): string => (props.isGraph ? 'border-box;' : `content-box;`)};
  background: 'linear-gradient(180deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.5) 100%);'};
`;

const Tabs = styled(Container)`
  margin-bottom: -1px; // Position tabs borders over TabsArea border
`;
const Tab = styled(Item, {
  shouldForwardProp: (prop) => prop !== 'isGraph',
})`
  padding: 0.7rem 2rem;
  z-index: 0;
  max-width: ${(props: ITabProps): string => (props.isGraph ? '25rem;' : 'none;')}

  // If selected
  background: ${(props: ITabProps): string => (props.selected ? `${colors.white}` : 'inherit')};
  border: ${(props: ITabProps): string => (props.selected ? `1px solid ${colors.gray}` : '0')};
  border-bottom: ${(props: ITabProps): string => (props.selected ? `1px solid ${colors.white}` : '0')};

  // If disabled
  cursor: ${(props: ITabProps): string => (props.disabled ? `unset` : 'pointer')};
`;

const TabButton = styled('button')`
  border: none;
  width: 100%;
  height: 100%;
  background-color: inherit;
  font-size: 1.6rem;
  font-weight: 600;

  // If ended or empty
  color: ${(props: { ended?: number; empty?: number }): string =>
    props.empty ? colors.secondaryText : props.ended ? colors.quaternary : colors.primary};

  font-family: inherit;
  cursor: inherit;
`;

const AddButtonStyle = styled('div')`
  padding: 0.7rem 4.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-weight: 600;
  color: ${colors.primary};
  &:hover {
    cursor: pointer;
    color: ${colors.primary};
  }
`;

interface ITabProps {
  selected?: number;
  isGraph?: boolean;
  disabled?: number;
}

const AddButton = ({ fm }: { fm: (descriptor: MessageDescriptor) => string }): JSX.Element => {
  return (
    <AddButtonStyle>
      <AddCircle />
      <span style={{ marginLeft: '0.7rem' }}>{fm({ id: `general.add` })}</span>
    </AddButtonStyle>
  );
};

interface IHistoryTabsContext {
  selected: number;
  changeSelected?: (i: number) => () => void;
}

export const HistoryTabsContext = React.createContext<IHistoryTabsContext>({ selected: 0 });

const HistoryTabs = ({
  children,
  addButton,
  buttonRow,
  disableZeroCounts = true,
  hideCount,
  startIndex,
  indexSelectionTools,
  isGraph,
}: IOwnProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const [selected, setSelected] = React.useState<number>(startIndex ?? 0);

  const fm = (id: string): string => formatMessage({ id: id });

  const selectTab = (i: number) => (): void => indexSelectionTools?.changeFunction(i) || setSelected(i);

  const selectedIndex = indexSelectionTools?.index ?? selected;

  // If the selected tab is disabled, set selected to nearest enabled
  React.useEffect(() => {
    if (children[selected] && (children[selected].disabled || (disableZeroCounts && children[selected].count === 0))) {
      const enabled = (selected === 0 ? children : children.slice().reverse()).find(
        (c) => c?.count && !c.disabled && (!disableZeroCounts || c.count > 0),
      );
      setSelected(enabled ? children.findIndex((c) => equals(c, enabled)) : 0);
    }
  }, [children]);

  return (
    <TabContainer>
      <TabsArea isGraph={!!isGraph}>
        <Tabs>
          {children.map((c, i) => (
            <Tab
              key={i}
              selected={i === selectedIndex ? 1 : undefined}
              disabled={c.disabled || (disableZeroCounts && c.count === 0) ? 1 : undefined}
              xl={'auto'}
              lg={'auto'}
              md={'auto'}
              xs={true}
              onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
              isGraph={isGraph}
            >
              <TabButton
                onClick={c.disabled || (disableZeroCounts && c.count === 0) ? undefined : selectTab(i)}
                empty={c.count === 0 ? 1 : undefined}
                ended={c.ended ? 1 : undefined}
              >
                <Container
                  style={{
                    height: '100%',
                    textAlign: 'center',
                    userSelect: 'none',
                  }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Item style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {typeof c.title === 'string' ? fm(c.title) : c.title}
                    {!hideCount && exists(c.count) && <span>&nbsp;({c.count})</span>}
                  </Item>
                </Container>
              </TabButton>
            </Tab>
          ))}
          <Item xs={true} />
          <div style={{ display: 'flex' }} onClick={typeof addButton === 'function' ? addButton(selectTab) : () => ''}>
            {typeof addButton === 'function' ? <AddButton fm={formatMessage} /> : addButton}
          </div>
        </Tabs>
      </TabsArea>
      <div>
        <HistoryTabsContext.Provider value={{ selected: selectedIndex, changeSelected: selectTab }}>
          <React.Fragment key={selectedIndex}>
            {children[selectedIndex] && children[selectedIndex].content}
            {buttonRow ? buttonRow : undefined}
          </React.Fragment>
        </HistoryTabsContext.Provider>
      </div>
    </TabContainer>
  );
};

interface IOwnProps {
  /** Children, tabs */
  children: Array<{
    title: string | JSX.Element;
    content: JSX.Element;
    count?: number;
    disabled?: boolean;
    ended?: boolean;
  }>;
  /** Use to add new tabs */
  addButton?: JSX.Element | ((selectTab: (i: number) => () => void) => (e?: any) => void);
  /** Additional buttons, e.g. for deleting tabs */
  buttonRow?: JSX.Element;
  /** If tabs with zero count are disabled */
  disableZeroCounts?: boolean;
  /** Hide count display in tab */
  hideCount?: true;
  /** Control which index is selected at mount */
  startIndex?: number;
  /** Control index and selection from outside this component */
  indexSelectionTools?: { index: number; changeFunction: (i: number) => void };
  /** Prop to slightly change layout on tabs used in graph */
  isGraph?: boolean;
}

export default HistoryTabs;
