/*
 * General visual components used in event steppers.
 * Note: These components are used outside of EventStepper, in other steppers that are more customized for specific events
 */
import { StepIconProps, StepLabel, StepContent, Menu, MenuItem, styled } from '@mui/material';
import { ArrowDropDown, ArrowRight, MoreVert } from '@mui/icons-material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import colors from '../../../config/theme/colors';
import { exists } from 'neuro-utils';
import ActionButton from '../../ActionButton';
import { Container, Item } from '../../Grid';

import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// Replace connector line with just empty span
// Need this custom connector to avoid error message from MUI about the completed prop not being boolean
export const Connector = ({ completed }: { completed?: boolean }): JSX.Element => (completed ? <span /> : <span />);

// Choose icon for step label when step is opened or closed
export const IconChooser = (props: StepIconProps): JSX.Element => {
  const { active } = props;
  return (
    <Container style={{ width: '5rem' }} justifyContent="center" alignItems="center">
      <Item>
        {active ? (
          <ArrowDropDown color="primary" style={{ display: 'block' }} />
        ) : (
          <ArrowRight color="disabled" style={{ display: 'block' }} />
        )}
      </Item>
    </Container>
  );
};

// Style of the step label, the area that is clicked to open the step
export const StyledStepLabel = styled(StepLabel)({
  height: '5rem',
  backgroundColor: `${colors.lightGray}`,
  cursor: 'pointer !important',
});

// The text inside the step label
export const StyledLabelText = styled('div')`
  color: ${colors.primaryText} !important;
  font-size: 1.6rem;
  font-weight: 600;
  max-height: 4.2rem; // This can fit exactly 2 lines
  overflow: hidden;
`;

// Style of the step content
export const StyledStepContent = styled(StepContent)`
  /* margin: ${(props: { editing?: boolean }): string =>
    !props.editing ? '2rem 0rem 3rem 5rem' : '0rem 0rem 3rem 0rem'} !important; */
  margin-left: ${(props: { editing?: boolean }): string => (!props.editing ? '5rem' : '0rem')} !important;
  margin-top: ${(props: { editing?: boolean }): string => (!props.editing ? '2rem' : '0rem')} !important;
  padding: 0rem 0rem 2rem 0rem !important;
  border: 0 !important;
`;

const StyledValueRow = styled('div')`
  margin-top: 1rem;
  * > &:first-of-type {
    margin-top: 0;
  }
`;
const StyledInputValueRow = styled('div')`
  margin-bottom: 2rem;
`;

/**
 * How to display header and value in event steppers 'view' mode. See StepperHeaderFormInputPair for form elements
 * @param {JSX.Element|string} header - Localized string or element
 * @param {any} value - Value
 * @returns {JSX.Element} StepperHeaderValuePair
 */
export const StepperHeaderValuePair = ({ header, value }: IStepperHeaderValuePair): JSX.Element => (
  <StyledValueRow>
    <Container>
      <Item xs={5} style={{ paddingRight: '2rem' }}>
        {header}
      </Item>
      <Item xs={true} style={{ fontWeight: 600 }}>
        {exists(value) ? value : '-'}
      </Item>
    </Container>
  </StyledValueRow>
);

interface IStepperHeaderValuePair {
  header: JSX.Element | string;
  value?: any;
}

/**
 * How to display header and input form in event steppers 'edit' mode.
 * @param {JSX.Element|string} header - Localized string or element
 * @param {JSX.Element} input - Input field
 * @returns {JSX.Element} StepperHeaderFormInputPair
 */
export const StepperHeaderFormInputPair = ({ header, input }: IStepperHeaderFormInputPair): JSX.Element => (
  <StyledInputValueRow>
    <Container>
      <Item xs={12} md={5} style={{ paddingRight: '2rem' }}>
        {header}
      </Item>
      <Item xs={true}>{input}</Item>
    </Container>
  </StyledInputValueRow>
);

interface IStepperHeaderFormInputPair {
  header: JSX.Element | string;
  input: JSX.Element;
}

// Header bar for events when needed as a div
export const StandAloneHeader = styled('div')`
  height: 5rem;
  background-color: ${colors.lightGray};
  padding: 0 0 0 5rem;
  font-weight: 600;
  cursor: default;
`;

export const StandAloneContent = styled('div')`
  /* margin: ${(props: { editing: boolean }): string =>
    !props.editing ? '2rem 0rem 3rem 5rem' : '0rem 0rem 3rem 0rem'} !important; */
  margin-left: ${(props: { editing: boolean }): string => (!props.editing ? '5rem' : '0rem')} !important;
  margin-top: ${(props: { editing: boolean }): string => (!props.editing ? '2rem' : '0rem')} !important;
  padding: 0rem 0rem 2rem 0rem !important;
  border: 0 !important;
`;

const StyledMenuIcon = styled(MoreVert)`
  display: block !important;
  color: #6c96ae;
  cursor: pointer;
`;

// Header bar edit button and menu
export const HeaderControls = ({
  index,
  type,
  setEditing,
  toggleMenu,
  anchor,
  openDeleteDialog,
}: IHeaderControls): JSX.Element => {
  // Ensure that the menu anchor is for the right index
  const menuAnchor = anchor && anchor.index === index ? anchor : null;
  return (
    <Container justifyContent="flex-end" alignItems="center">
      <Item style={{ marginRight: '2rem' }}>
        <ActionButton text={'general.edit'} onClick={setEditing(index, type)} width={10} height={3} fontSize={14} />
      </Item>
      <Item style={{ marginRight: '2rem' }}>
        <StyledMenuIcon onClick={toggleMenu} />
      </Item>
      <Menu id="eventsteppermenu" anchorEl={menuAnchor?.anchor} open={Boolean(menuAnchor?.anchor)} onClose={toggleMenu}>
        <MenuItem onClick={openDeleteDialog(index, type)}>
          <FormattedMessage id="general.deletionTitle" />
        </MenuItem>
      </Menu>
    </Container>
  );
};

export const StyledViewSelector = styled('span')`
  color: ${colors.primary};
  user-select: none;
  text-decoration: ${(props: { active: boolean }): string => (props.active ? 'none' : 'underline')};
  font-weight: ${(props: { active: boolean }): string => (props.active ? 'bold' : 'normal')};
  cursor: ${(props: { active: boolean }): string => (props.active ? 'default' : 'pointer')};
`;

const StyledControl = styled('div')`
  font-size: 1.8rem;
  color: ${(props: { active: boolean }): string => (props.active ? colors.primary : colors.gray)};
  cursor: ${(props: { active: boolean }): string => (props.active ? 'pointer' : 'default')};
  text-align: center;
  display: flex;
  align-items: baseline;
  transform: scaleY(1.25);
`;

export const StyledTableControls = ({
  eventRange,
  tableEvents,
  changeEventRange,
}: {
  eventRange: [number, number];
  tableEvents: any[];
  changeEventRange: (change: number) => () => void;
}): JSX.Element => {
  const negativeModifier = eventRange[0] - 6 >= 0 ? -6 : -eventRange[0];
  const positiveModifier = eventRange[1] + 6 <= tableEvents.length - 1 ? 6 : tableEvents.length - 1 - eventRange[1];
  return (
    <React.Fragment>
      <Item
        xs={true}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          paddingRight: '2rem',
          color: colors.tertiaryText,
          userSelect: 'none',
        }}
      >
        <StyledControl
          active={eventRange[0] > 0}
          onClick={eventRange[0] > 0 ? changeEventRange(negativeModifier) : (): string => ''}
        >
          <KeyboardDoubleArrowLeftIcon />
        </StyledControl>
        &nbsp;
        <StyledControl active={eventRange[0] > 0} onClick={eventRange[0] > 0 ? changeEventRange(-1) : (): string => ''}>
          <KeyboardArrowLeftIcon />
        </StyledControl>
        &nbsp;&nbsp;&nbsp;
        {`${eventRange[0] + 1}-${tableEvents.length < eventRange[1] + 1 ? tableEvents.length : eventRange[1] + 1} / ${
          tableEvents.length
        }`}
        &nbsp;&nbsp;&nbsp;
        <StyledControl
          active={eventRange[1] < tableEvents.length - 1}
          onClick={eventRange[1] < tableEvents.length - 1 ? changeEventRange(1) : (): string => ''}
        >
          <KeyboardArrowRightIcon />
        </StyledControl>
        &nbsp;
        <StyledControl
          active={eventRange[1] < tableEvents.length - 1}
          onClick={eventRange[1] < tableEvents.length - 1 ? changeEventRange(positiveModifier) : (): string => ''}
        >
          <KeyboardDoubleArrowRightIcon />
        </StyledControl>
      </Item>
    </React.Fragment>
  );
};

interface IHeaderControls {
  index: number;
  type?: string;
  setEditing: (index: number, type?: string) => (e: React.MouseEvent) => void;
  toggleMenu: (e: React.MouseEvent<SVGElement>) => void;
  anchor: { anchor: HTMLElement; index: number } | null;
  openDeleteDialog: (index: number, type?: string) => (e: React.MouseEvent) => void;
}
