/*
  Capabilities control what functionalities the user has access to.
  The UI does not enforce any of the capabilities, enforcing happens in backend (as soon as it is implemented).
  The UI uses capabilities to improve UX by hiding/disabling some functions that the user/org does not have access to.
  This list should be treated as append only at the moment. We might move this to a separate project/npm module as this list
  should be shared between backend and front-end.
*/

const PlatformCapabilities = {
  CLINICAL_RECORDS: 'CLINICAL_RECORDS',
  SMA_RECORD_LNEU: 'SMA_RECORD_LNEU',
  SMA_RECORD_TOI: 'SMA_RECORD_TOI',
  SMA_RECORD_FYST: 'SMA_RECORD_FYST',
  DMD_RECORD_LNEU: 'DMD_RECORD_LNEU',
  DMD_RECORD_TOI: 'DMD_RECORD_TOI',
  DMD_RECORD_FYST: 'DMD_RECORD_FYST',
  EPILEPSY_RECORD_NEU: 'EPILEPSY_RECORD_NEU',
  PARKINSON_RECORD_NEU: 'PARKINSON_RECORD_NEU',
  PARKINSON_RECORD_HOI: 'PARKINSON_RECORD_HOI',
  SLEEPAPNEA_RECORD_KEU: 'SLEEPAPNEA_RECORD_KEU',
  SLEEPAPNEA_RECORD_HOI: 'SLEEPAPNEA_RECORD_HOI',
  MIRANDA_MEDICATION_UPDATE: 'MIRANDA_MEDICATION_UPDATE',
  MIRANDA_MEDICATION_HIDE_OVERLAPPING: 'MIRANDA_MEDICATION_HIDE_OVERLAPPING',
  APOTTI_MEDICATION_UPDATE: 'APOTTI_MEDICATION_UPDATE',
  LIFECARE_DIAGNOSIS_SYNC: 'LIFECARE_DIAGNOSIS_SYNC',
  LIFECARE_LABORATORY_SYNC: 'LIFECARE_LABORATORY_SYNC',
  FIMLAB_LABORATORY_SYNC: 'FIMLAB_LABORATORY_SYNC',
  LIFECARE_MEDICATION_SYNC: 'LIFECARE_MEDICATION_SYNC',
  LFORCE_IMAGING_SYNC: 'LFORCE_IMAGING_SYNC',
  URANUS_CARETABLE_SYNC: 'URANUS_CARETABLE_SYNC',
  NMOSD_CAPABILITES: 'NMOSD_CAPABILITES',
  MEDICATION_CREATE: 'MEDICATION_CREATE',
  MEDICATION_CREATE_RETROSPECTIVE: 'MEDICATION_CREATE_RETROSPECTIVE',
  APPOINTMENTS: 'APPOINTMENTS',
  APPOINTMENTS_CREATE: 'APPOINTMENTS_CREATE',
  MYSERVICE_INTEGRATION: 'MYSERVICE_INTEGRATION',
  MYSERVICE_TILE: 'MYSERVICE_TILE',
  NINMT_MYSERVICE_TILE: 'NINMT_MYSERVICE_TILE',
  SR_MYSERVICE_TILE: 'SR_MYSERVICE_TILE',
  EPILEPSY_MYSERVICE_TILE: 'EPILEPSY_MYSERVICE_TILE',
  MYSERVICE_SURVEYS_DELETION: 'MYSERVICE_SURVEYS_DELETION',
  PATIENT_TRANSFER_INITIATE: 'PATIENT_TRANSFER_INITIATE',
  PATIENT_PROFILE_EDIT: 'PATIENT_PROFILE_EDIT',
} as const;
export type TPlatformCapability = (typeof PlatformCapabilities)[keyof typeof PlatformCapabilities];

export default PlatformCapabilities;
