import { Slider, SliderThumb } from '@mui/material';
import { exists } from 'neuro-utils';
import * as React from 'react';
import styled from 'styled-components';
import colors from '../../../config/theme/colors';
import theme from '../../../config/theme/muiTheme';

const ThumbIcon = ({
  dragging,
  grayOut,
  children,
}: {
  dragging: boolean;
  grayOut: boolean;
  children: JSX.Element;
}): JSX.Element => {
  return (
    <div>
      <svg width="33" height="41" viewBox="0 0 39 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <filter id="shadow">
            <feDropShadow dx="0" dy="0" stdDeviation={2} floodColor={colors.appBlue.medium} />
          </filter>
        </defs>
        <path
          d="M16.5 0C25.6066 0 33 7.32071 33 16.3378C33 25.355 16.4909 41.3799 16.5 40.9931C16.5016 40.9255 0 25.355 0 16.3378C0 7.32071 7.39337 0 16.5 0Z"
          fill={grayOut ? colors.gray : colors.appBlue.default}
          filter={dragging && !grayOut ? 'url(#shadow)' : undefined}
          style={{ transform: 'translate(3px, 3px)' }}
        />
      </svg>
      <div
        style={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          top: '-1.8rem',
        }}
      >
        {children}
      </div>
    </div>
  );
};

const StyledSliderThumb = styled(SliderThumb)(() => ({
  width: 1,
  height: 1,
  transform: 'translateY(-2.6rem)',
  '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
    boxShadow: '0px 0px',
  },
}));

const StyledRangeSlider = styled(Slider)(() => ({
  '& .MuiSlider-track': {
    backgroundColor: colors.appBlue.lightest,
    borderRadius: '0px',
    border: '0px',
    transform: 'translate(1px, -2px)',
  },
  '& .MuiSlider-rail': {
    backgroundColor: colors.white,
    height: '0.6rem',
    borderRadius: '0px',
    opacity: 1,
    border: '1px solid black',
    borderTop: '0px',
  },
  '& .MuiSlider-mark': {
    backgroundColor: 'black',
    height: 8,
    width: 1,
  },
}));

const RangeText = styled('div')(() => ({
  height: '2rem',
  minHeight: '2rem',
  padding: '0rem 1.3rem',
  color: colors.primaryText,
  fontWeight: 600,
}));

const CustomThumb = (props: React.HTMLAttributes<unknown>) => {
  const { children, ...other } = props;
  const value: number = React.isValidElement(children) && children.props.value;
  const grayOut: boolean = React.isValidElement(children) && (children.props as any).disabled;
  const [dragging, setDragging] = React.useState<boolean>(false);
  return (
    <StyledSliderThumb {...other} onFocus={() => setDragging(true)} onBlur={() => setDragging(false)}>
      {children}
      <ThumbIcon dragging={dragging} grayOut={grayOut}>
        <span style={{ color: colors.white, fontSize: theme.typography.fontSize, userSelect: 'none' }}>{value}</span>
      </ThumbIcon>
    </StyledSliderThumb>
  );
};

const RangeSlider = ({
  editing = false,
  name,
  onChange,
  value,
  min,
  max,
  marks,
  showRangeText = false,
  disabled,
}: IInputBasics & IRangeSlider): JSX.Element => {
  const grayOut = !editing || disabled;

  const [testValue, setTestValue] = React.useState<number[]>([min, max]);
  const isMountRender = React.useRef<boolean>(true);

  const handleValueChange = (e: Event, newVal: number | number[]) => {
    if (Array.isArray(newVal)) setTestValue(newVal);
  };

  // Handle mount
  React.useEffect(() => {
    if (editing && !disabled && value !== testValue) {
      if (!value) setTestValue([min, max]);
      else setTestValue(value);
    }
  }, []);

  // Handle clear
  React.useEffect(() => {
    if (isMountRender.current && !value) {
      isMountRender.current = false;
      return;
    } else {
      if (!value) setTestValue([min, max]);
    }
  }, [value]);

  // Handle change
  React.useEffect(() => {
    if (editing && !disabled && value !== testValue && onChange) {
      onChange({ [name]: testValue });
    }
  }, [testValue]);

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '8rem',
          display: 'flex',
          alignItems: 'end',
          padding: '0rem 1.3rem',
        }}
      >
        <StyledRangeSlider
          value={testValue}
          onChange={handleValueChange}
          components={{ Thumb: CustomThumb }}
          marks={marks}
          min={min}
          max={max}
          defaultValue={[min, max]}
          disabled={grayOut}
        />
      </div>
      {showRangeText && (
        <RangeText>{exists(value?.[0]) && exists(value?.[1]) ? `${value?.[0]} - ${value?.[1]}` : ''}</RangeText>
      )}
    </>
  );
};

export default RangeSlider;
