import { ClinicalArchive } from '.';
import { LoadableDocConfig } from '../generalSettings';

export const smaDocumentTypes: LoadableDocConfig = [
  { name: 'diagnosis' },
  { name: 'medication', subTypes: ['medication', 'adverseEffect'] },
  { name: 'motor', subTypes: ['hine_mm', 'rulm', 'chop_intend', 'hfms_e', 'mwt', 'mfm'], secondaryDocs: ['diagnosis'] }, // History needs diagnosis codes
  { name: 'motorFunctionAndMobilityAid', subTypes: ['motorfunction', 'mobilityAid'] },
  { name: 'nutrition' },
  { name: 'pulmonary', subTypes: ['chestCircumference', 'ventilationAid', 'pulmonary'] },
  { name: 'therapy' },
  { name: 'imaging', subTypes: ['cmap', 'dexa', 'muscleImaging', 'echocardiography', 'scoliosis', 'pelvis', 'mri'] },
  { name: 'clinicalstudy', subTypes: ['fvc', 'enmg', 'nocturnalPolygraphy'] },
  { name: 'examination' },
  { name: 'comorbidity', subTypes: ['comorbidity', 'hospitalization'], secondaryDocs: ['medication'] }, // Form needs medication names
  { name: 'generalhealth' },
  { name: 'background', subTypes: ['background', 'measurement'] },
];

export const smaGraphDocTypes = [
  'diagnosis',
  'medication',
  'adverseEffect',
  'hine_mm',
  'hfms_e',
  'rulm',
  'mfm',
  'mwt',
  'background',
  'measurement',
  'comorbidity',
  'hospitalization',
  'mri',
  'pelvis',
  'scoliosis',
  'muscleImaging',
  'dexa',
  'nutrition',
  'ventilationAid',
  'mobilityAid',
  'therapy',
  'motorfunction',
  'chop_intend',
  'papTherapy',
  'nocturnalPolygraphy',
  'enmg',
  'fvc',
];

/** Clinical archive types of SMA platform. */
export const smaClinicalArchiveTypes: readonly ClinicalArchive[] = ['LNEU', 'TOI', 'FYST'] as const;
