import * as React from 'react';
import { GraphColors, IData, IDataPoint, TGraphScale, TGraphSettings } from 'Components/sq-graphics/interfaces';
import { GraphTooltip } from '../../GraphTooltip';
import { GraphTooltipDescription } from '../../GraphTooltip/components';
import { invertColor } from '../../../util/calculators';
import { equals } from 'ramda';

const getGraphIds = (data: Array<IData>): string[] => {
  const ids: string[] = [];
  data.forEach((d) => {
    if (d.id in ids) return;
    ids.push(d.id);
  });
  return ids;
};

function IconTriangleDown({
  strokeColor,
  strokeWidth,
  fillColor,
}: {
  strokeColor: string;
  strokeWidth: number;
  fillColor: string;
}): JSX.Element {
  return (
    <path
      d="M 0 0 L 4.6 6 L 8.9 0 Z"
      x={0}
      y={0}
      width="8.6"
      height="8.6"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      fill={fillColor}
    />
  );
}

function IconTriangleUp({
  strokeColor,
  strokeWidth,
  fillColor,
}: {
  strokeColor: string;
  strokeWidth: number;
  fillColor: string;
}): JSX.Element {
  return (
    <path
      d="M 0 6 L 4.6 0 L 8.8 6 Z"
      x={0}
      y={0}
      width="8.6"
      height="8.6"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      fill={fillColor}
    />
  );
}

function IconNoChange({
  strokeColor,
  strokeWidth,
  fillColor,
}: {
  strokeColor: string;
  strokeWidth: number;
  fillColor: string;
}): JSX.Element {
  return (
    <rect
      x={6.5}
      y={0}
      width="4.3"
      height="4.3"
      transform={`rotate(45,${0},${0})`}
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      fill={fillColor}
    />
  );
}

const BoxGraph = ({
  data,
  xPoint,
  yPoint,
  height,
  scale,
  additionalScale,
  graphSettings,
  reference,
  numberOfOtherGraphs,
  graphTypeIndex,
  timeframeWidth,
}: IOwnProps): JSX.Element => {
  const ids: string[] = getGraphIds(data);
  const dataPoints: { [key: string]: IPoint[] } = {};
  ids.forEach((id) => {
    const dps: IPoint[] = [];
    data
      .filter((d) => d.id === id)
      .forEach((d) => {
        d.dataPoints.forEach((dp) => {
          if (!dp.additionalValue) return;
          let sc: TGraphScale = d.useAdditionalScale && additionalScale ? additionalScale : scale;
          if (reference && additionalScale) sc = additionalScale;
          const x = xPoint(dp.date);
          const yStart = yPoint(dp.value, sc, height);
          const yEnd = yPoint(dp.additionalValue, sc, height);
          if (!(x || x === 0) || !(yStart || yStart === 0) || !(yEnd || yEnd === 0)) return;
          dps.push({
            id: id,
            x: x,
            yStart: yStart,
            yEnd: yEnd,
            value: dp.value,
            additionalValue: dp.additionalValue,
            date: dp.date,
            legend: d.legend,
            title: dp.title,
            description: dp.description,
            useAdditionalScale: d.useAdditionalScale,
            alternativeDateString: dp.alternativeDateString,
          });
        });
      });
    dataPoints[id] = dps;
  });
  const [showLine, setShowLine] = React.useState<[number | undefined, string | undefined]>([undefined, undefined]);

  return (
    <g>
      {Object.entries(dataPoints).map(([key, points], index) => {
        let color: string = graphSettings?.[key]
          ? GraphColors[graphSettings[key]]
          : Object.values(GraphColors)[
              (numberOfOtherGraphs * graphTypeIndex + index) % Object.values(GraphColors).length
            ];
        if (reference) color = invertColor(color);
        return (
          <g key={key}>
            {points.map((p, i) => (
              <g key={`${key}${i}${p.x}${p.yStart}${p.yEnd}`}>
                <line
                  x1={p.x}
                  y1={p.yStart + 1}
                  x2={p.useAdditionalScale ? timeframeWidth * 10 : 0}
                  y2={p.yStart + 1}
                  stroke={'black'}
                  strokeWidth="0.5"
                  strokeDasharray={5}
                  opacity={showLine[0] === i && showLine[1] === p.id ? 1 : 0}
                />
                <line
                  x1={p.x}
                  y1={p.yEnd - 1}
                  x2={p.useAdditionalScale ? timeframeWidth * 10 : 0}
                  y2={p.yEnd - 1}
                  stroke={'black'}
                  strokeWidth="0.5"
                  strokeDasharray={5}
                  opacity={showLine[0] === i && showLine[1] === p.id ? 1 : 0}
                />
                <g onMouseLeave={() => setShowLine([undefined, undefined])}>
                  <GraphTooltip
                    key={`${i}${p.x}${p.yStart}`}
                    date={p.date}
                    name={p.legend}
                    title={p.title ?? p.value.toString()}
                    description={p.description ? <GraphTooltipDescription convertedData={p.description} /> : undefined}
                    content={
                      <g onMouseEnter={() => !equals([i, p.id], showLine) && setShowLine([i, p.id])}>
                        {Math.abs(p.yStart - p.yEnd) > 0 && (
                          <rect
                            x={p.x - 4.3}
                            y={p.yEnd > p.yStart ? p.yStart : p.yEnd}
                            width={8.6}
                            height={Math.abs(p.yStart - p.yEnd)}
                            fill={color}
                          />
                        )}
                        {/** Pienemmät y-arvot ylempänä graafissa */}
                        {p.yEnd < p.yStart ? (
                          <g transform={`translate(${p.x - 4.3},${p.yEnd})`}>
                            <IconTriangleUp strokeColor={color} strokeWidth={1} fillColor={'white'} />
                          </g>
                        ) : p.yEnd > p.yStart ? (
                          <g transform={`translate(${p.x - 4.3},${p.yEnd - 6})`}>
                            <IconTriangleDown strokeColor={color} strokeWidth={1} fillColor={'white'} />
                          </g>
                        ) : (
                          <g transform={`translate(${p.x - 6.5},${p.yEnd - 10}) scale(1.5)`}>
                            <IconNoChange strokeColor={color} strokeWidth={1} fillColor={'white'} />
                          </g>
                        )}
                      </g>
                    }
                    alternativeDateString={p.alternativeDateString}
                  />
                </g>
              </g>
            ))}
          </g>
        );
      })}
    </g>
  );
};

interface IPoint {
  id: string;
  x: number;
  yStart: number;
  yEnd: number;
  value: number | string;
  additionalValue: number | string;
  date: Date;
  legend?: string;
  title?: string;
  description?: IDataPoint['description'];
  useAdditionalScale?: boolean;
  alternativeDateString?: string;
}

interface IOwnProps {
  data: IData[];
  xPoint: (date: Date) => number | undefined;
  yPoint: (value: string | number, scale: TGraphScale, graphHeight: number) => number | undefined;
  height: number;
  scale: TGraphScale;
  additionalScale: TGraphScale | undefined;
  graphSettings: TGraphSettings['graphSettings'];
  reference?: true;
  numberOfOtherGraphs: number;
  graphTypeIndex: number;
  timeframeWidth: number;
}

export default BoxGraph;
