import { ClinicalArchive } from '.';
import { LoadableDocConfig } from '../generalSettings';

export const sleepApneaDocumentTypes: LoadableDocConfig = [
  {
    name: 'diagnosis',
    subTypes: ['diagnosis', 'sleepApneaFirstVisit', 'respiratoryFirstVisit', 'sleepApneaSeverity', 'performanceRating'],
  },
  {
    name: 'clpAndCnpTests',
    subTypes: ['sleepPolygraphy', 'sleepStudy', 'spirometry', 'nightOximetry', 'carbonDioxideMeasurement'],
    secondaryDocs: ['madTherapy', 'diagnosis'],
  },
  { name: 'examination' },
  { name: 'medication', subTypes: ['medication', 'adverseEffect'] },
  {
    name: 'treatment',
    subTypes: [
      'papTherapy',
      'hfncTherapy',
      'oxygenTherapy',
      'madTherapy',
      'surgicalTreatment',
      'otherTreatment',
      'patientDoesNotWantRespiratorySupportTherapy',
    ],
    secondaryDocs: ['diagnosis', 'sleepApneaFirstVisit', 'respiratoryFirstVisit', 'sleepPolygraphy', 'sleepStudy'],
  },
  { name: 'checklist' },
  {
    name: 'comorbidity',
    subTypes: ['comorbidity', 'pregnancy', 'contraception', 'sickLeave'],
    secondaryDocs: ['diagnosis'],
    title: 'otherHealth',
  },
  { name: 'background', subTypes: ['background', 'measurement'] },
  { name: 'myService', title: 'titleShort' },
];

export const sleepApneaGraphDocTypes = [
  'diagnosis',
  'medication',
  'adverseEffect',
  'papTherapy',
  'hfncTherapy',
  'oxygenTherapy',
  'madTherapy',
  'surgicalTreatment',
  'otherTreatment',
  'patientDoesNotWantRespiratorySupportTherapy',
  'sleepPolygraphy',
  'sleepStudy',
  'spirometry',
  'nightOximetry',
  'carbonDioxideMeasurement',
  'comorbidity',
  'background',
  'measurement',
];

// Filter these routes/types from showing in page navigations based on these rules
export const sleepApneaFilteredTypes = (docs?: document[]): string[] => {
  let diag = undefined;

  if (docs && docs.length > 0) {
    diag = docs.find(
      (d) =>
        (d as IDiagnosis).diagnosis === 'J96.1' ||
        (d as IDiagnosis).diagnosis === 'J96.9' ||
        (d as IDiagnosis).diagnosis === 'respiratoryFailureSuspicion',
    ) as IDiagnosis;
  }
  return [
    ...(diag ? [] : ['checklist']), // Filter checklist if no Respiratory dx
  ];
};

export const sleepApneaClinicalArchiveTypes: readonly ClinicalArchive[] = ['KEU', 'HOI'] as const;
