import { Box } from '@mui/material';
import { styled } from '@mui/material';
import * as React from 'react';

import { content } from '../../config/theme/componentTheme';

const StyledBox = styled(Box)({
  height: '100%;',
  margin: content.bodyMargin,
  padding: content.bodyPadding,
  ...content.bodyWidth,
});

const ContentWrapper = ({ maxWidth, children }: IOwnProps): JSX.Element => (
  <StyledBox sx={{ maxWidth }}>{children}</StyledBox>
);

interface IOwnProps {
  maxWidth?: 'sm' | 'md' | 'lg';
  children: JSX.Element | JSX.Element[];
}

export default ContentWrapper;
