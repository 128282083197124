import * as React from 'react';
import * as d3Path from 'd3-path';
import { GraphColors, IData, IDataPoint, TGraphScale, TGraphSettings } from 'Components/sq-graphics/interfaces';
import { GraphTooltip } from '../../GraphTooltip';
import { invertColor } from '../../../util/calculators';
import { GraphTooltipDescription } from '../../GraphTooltip/components';

const createPath = (p: IPoint): string => {
  const gPath = d3Path.path();
  gPath.moveTo(p.xStart, p.yStart);
  gPath.lineTo(p.xEnd, p.yEnd);
  return gPath.toString();
};

const getGraphIds = (data: Array<IData>): string[] => {
  const ids: string[] = [];
  data.forEach((d) => {
    if (d.id in ids) return;
    ids.push(d.id);
  });
  return ids;
};

const ConstantValueChart = ({
  data,
  xPoint,
  yPoint,
  height,
  scale,
  additionalScale,
  graphSettings,
  reference,
  numberOfOtherGraphs,
  graphTypeIndex,
}: IOwnProps): JSX.Element => {
  const ids: string[] = getGraphIds(data);
  const dataPoints: { [key: string]: IPoint[] } = {};
  ids.forEach((id) => {
    const dps: IPoint[] = [];
    data
      .filter((d) => d.id === id)
      .forEach((d) => {
        d.dataPoints.forEach((dp) => {
          let sc: TGraphScale = d.useAdditionalScale && additionalScale ? additionalScale : scale;
          if (reference && additionalScale) sc = additionalScale;
          const xStart = xPoint(dp.date);
          const y = yPoint(dp.value, sc, height);
          const xEnd = xPoint(dp.endDate ?? new Date());
          if (!(xStart || xStart === 0) || !(y || y === 0) || !(xEnd || xEnd === 0)) return;
          dps.push({
            id: id,
            xStart: xStart,
            yStart: y,
            xEnd: xEnd,
            yEnd: y,
            value: dp.value,
            date: dp.date,
            endDate: dp.endDate,
            legend: d.legend,
            title: dp.title,
            description: dp.description,
            alternativeDateString: dp.alternativeDateString,
          });
        });
      });
    dataPoints[id] = dps;
  });

  const [showLine, setShowLine] = React.useState<number | null>(null);

  return (
    <g>
      {Object.entries(dataPoints).map(([key, points], index) => {
        let color: string = graphSettings?.[key]
          ? GraphColors[graphSettings[key]]
          : Object.values(GraphColors)[
              (numberOfOtherGraphs * graphTypeIndex + index) % Object.values(GraphColors).length
            ];
        if (reference) color = invertColor(color);
        return (
          <g key={key}>
            {points.map((p, i) => {
              const path: { path: string; color: string; strokeWidth: number } = {
                path: createPath(p),
                color: color,
                strokeWidth: 2.5,
              };
              const date = p.date && !p.endDate;
              const startAndEnd = p.date && p.endDate;
              return (
                <g key={`${i}${p.yStart}${p.xStart}`}>
                  {path.path && (
                    <path
                      d={path.path}
                      stroke={path.color}
                      strokeWidth={path.strokeWidth + 'px'}
                      fill="none"
                      opacity={1}
                    />
                  )}
                  {((additionalScale && !additionalScale.dontDrawHelperLine) || reference) && (
                    <line
                      x1={p.xStart}
                      x2={p.useAdditionalScale || reference ? '100%' : 0}
                      y1={p.yStart}
                      y2={p.yStart}
                      stroke={'black'}
                      strokeWidth="0.5"
                      strokeDasharray={5}
                      opacity={showLine === i ? 1 : 0}
                    />
                  )}
                  <GraphTooltip
                    date={date ? p.date : undefined}
                    start={startAndEnd ? p.date : undefined}
                    end={startAndEnd ? p.endDate : undefined}
                    name={p.legend}
                    title={p.title ?? p.value.toString()}
                    description={p.description ? <GraphTooltipDescription convertedData={p.description} /> : undefined}
                    content={
                      <circle
                        cx={p.xStart}
                        cy={p.yStart}
                        r="6"
                        fill={color}
                        onMouseEnter={
                          additionalScale && !additionalScale.dontDrawHelperLine ? () => setShowLine(i) : () => null
                        }
                        onMouseLeave={() => setShowLine(null)}
                      />
                    }
                    alternativeDateString={p.alternativeDateString}
                  />
                </g>
              );
            })}
          </g>
        );
      })}
    </g>
  );
};

interface IPoint {
  id: string;
  xStart: number;
  yStart: number;
  xEnd: number;
  yEnd: number;
  value: string | number;
  date: Date;
  endDate?: Date;
  legend?: string;
  title?: string;
  description?: IDataPoint['description'];
  doNotDraw?: boolean;
  alternativeDateString?: string;
  useAdditionalScale?: boolean;
}

interface IOwnProps {
  data: IData[];
  xPoint: (date: Date | undefined) => number | undefined;
  yPoint: (value: string | number, scale: TGraphScale, graphHeight: number) => number | undefined;
  height: number;
  scale: TGraphScale;
  additionalScale: TGraphScale | undefined;
  graphSettings: TGraphSettings['graphSettings'];
  reference?: true;
  numberOfOtherGraphs: number;
  graphTypeIndex: number;
}

export default ConstantValueChart;
