import { LoadableDocConfig } from '../generalSettings';

export const huntingtonDocumentTypes: LoadableDocConfig = [
  { name: 'diagnosis', subTypes: ['diagnosis', 'huntingtonSymptoms', 'htt', 'uhdrsConfidenceIndex'] },
  { name: 'medication', subTypes: ['medication', 'adverseEffect'] },
  { name: 'motorFunctionHuntington', subTypes: ['uhdrsMotorRatingScale'] },
  { name: 'behaviour', subTypes: ['pbaS'] },
  { name: 'cognition', subTypes: ['sdmt'] },
  {
    name: 'functionalPerformance',
    subTypes: ['uhdrsFunctionalAssessment', 'uhdrsIndependenceScale', 'uhdrsFunctionalCapacity'],
  },
  { name: 'background', subTypes: ['background', 'measurement'] },
];

export const huntingtonGraphDocTypes = [
  'diagnosis',
  'medication',
  'adverseEffect',
  'uhdrsIndependenceScale',
  'uhdrsFunctionalAssessment',
  'uhdrsFunctionalCapacity',
  'uhdrsMotorRatingScale',
  'uhdrsConfidenceIndex',
  'pbaS',
  'sdmt',
  'background',
  'measurement',
];
