import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import colors from '../../config/theme/colors';
import { Container, Item } from '../Grid';
import InformationIcon from '../InfoIcon';
import { styled } from '@mui/material';

const RowArea = styled('div')`
  margin-bottom: ${(props: { bottomMargin?: boolean }): string => (props.bottomMargin === false ? '0' : '4.5rem')};
  * > &:last-of-type {
    margin-bottom: ${(props: { bottomMargin?: boolean }): string => (props.bottomMargin === true ? '4.5rem' : '1rem')};
  }
`;

const StyledTitle = styled('div')`
  color: ${colors.tertiaryText};
  padding: 0 3rem 0 0;
`;

const DescriptionText = styled('div')`
  font-size: 14px;
  padding: 1rem 10rem 0 0;
  color: ${colors.darkGray};
`;

const FormRow = ({
  title,
  titleValues,
  description,
  condition = true,
  children,
  headerWidth = 5,
  headerStyle,
  info,
  bottomMargin,
  id,
  formatTitle = true,
}: IOwnProps): JSX.Element | null =>
  condition ? (
    <RowArea id={id} bottomMargin={bottomMargin}>
      <Container>
        <Item xs={headerWidth}>
          {title && (
            <StyledTitle style={headerStyle}>
              <Container alignItems="center">
                <Item>{!formatTitle ? title : <FormattedMessage id={title} values={titleValues} />}</Item>
                {info && (
                  <Item xs={1} style={{ height: '4rem' }}>
                    <InformationIcon title={<FormattedMessage id={title} />} info={info} />
                  </Item>
                )}
              </Container>
            </StyledTitle>
          )}
          {description && <DescriptionText>{description}</DescriptionText>}
        </Item>
        <Item xs={true}>{children}</Item>
      </Container>
    </RowArea>
  ) : null;

interface IOwnProps {
  title?: string;
  titleValues?: Record<string, React.ReactNode>;
  description?: string | JSX.Element;
  condition?: boolean;
  children: JSX.Element | Array<JSX.Element | undefined | null> | string | number | undefined | null;
  headerWidth?: number;
  headerStyle?: React.CSSProperties;
  info?: JSX.Element;
  bottomMargin?: boolean;
  id?: string;
  formatTitle?: boolean;
}

export default FormRow;
