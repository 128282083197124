import * as React from 'react';
import colors from './colors';

export const screenBreakpoints = { xs: 0, sm: 950, md: 1400, lg: 1850, xl: 1920 };

// Wrap content with these settings.
export const content = {
  bodyMargin: '0 auto',
  bodyPadding: '0 5rem',
  // 185.4rem fits 4 tiles, 138.7rem fits 3, 92rem fits 2, 45.5rem fits one
  bodyWidth: {
    width: '225.6rem',
    '@media only screen and (max-width: 2375px)': {
      width: '180.2rem',
    },
    '@media only screen and (max-width: 1920px)': {
      width: '180.2rem',
    },
    '@media only screen and (max-width: 1919px)': {
      padding: '0rem',
    },
    '@media only screen and (max-width: 1850px)': {
      width: '134.8rem',
    },
    '@media only screen and (max-width: 1400px)': {
      width: '89.4rem',
    },
  },
};

// Header settings
export const styleHeader = {
  backgroundColor: colors.header,
  height: '7.5rem',
};

// Toolbar settings
export const styleToolbar = {
  backgroundColor: colors.primary,
  height: '5.5rem',
};

// Tile settings
export const styleTile = {
  width: 'auto',
  height: '21.5rem',
  margin: '0.7rem',
  padding: '2.6rem 3rem',
  contentHeight: '80%',
  tileHeader: {
    color: colors.primary,
    other: `
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.6rem;
      margin-top: 0.0rem;
      margin-bottom: 1.5rem;
      line-height: 1;
    `,
  },
  extraSmallFont: '1.2rem',
  smallFont: '1.4rem',
  mediumFont: '1.8rem',
  largeFont: '2.2rem',
  hugeFont: '2.8rem',
  overlay: {
    width: 'auto',
    height: '26.7rem',
    margin: '-27.4rem 0.7rem 0.7rem 0.7rem',
    position: 'relative',
  },
  lineHeight: '0.85em', // not relative to root because font size may vary within tile
};

export const tileSettingsNew = {
  width: '39.2rem', // 442px minus padding
  height: '24rem', // 290px minus padding, this height can fit 6 items with height 3.2rem, or header and 6 items with smaller heights
  margin: '0.6rem',
  padding: '2.5rem',
  overlay: {
    width: '44.2rem',
    height: '29rem',
    margin: `-${29 + 0.6}rem 0.6rem 0.6rem 0.6rem`,
  },
  icon: {
    // Not used yet
    width: '3rem',
    height: '3rem',
  },
  header: {
    width: '100%',
    height: '3rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: '1rem',
    lineHeight: '1.6rem',
  },
  body: { width: '100%', height: '100%' },
  bodyPadding: '0 0.6rem',
  fonts: {
    header: {
      size: '1.6rem',
      color: colors.primary,
    },
    diagnosisText: {
      size: '2.4rem',
      color: colors.primaryText,
    },
    bigText: {
      size: '1.8rem',
      color: colors.primaryText,
      secondaryColor: colors.tertiaryText,
    },
    normalText: {
      size: '1.6rem',
      color: colors.primaryText,
      secondaryColor: colors.tertiaryText,
    },
    smallText: {
      size: '1.3rem',
      color: colors.tertiaryText,
    },
    smallestText: {
      size: '1.1rem',
      color: colors.tertiaryText,
    },
  },
};

// Dash settings
export const styleDash = {
  margin: `0.6rem -${styleTile.margin}`, // Effect: remove margin from dash created by tiles
  width: 'auto',
} as React.CSSProperties;

// Document settings
export const styleDocument = {
  minHeight: '30rem',
  padding: '4rem',
  fontSize: '1.6rem',
  documentHeader: {
    color: colors.primary,
    infoTextColor: colors.primaryText,
    border: `1px solid ${colors.lightGray}`,
    other: `
      font-size: 4.4rem;
    `,
    secondary: `
      font-size: 2.2rem;
      font-weight: 600;
      margin-top: 2rem;
    `,
  },
};

export const dialogTitle = {
  fontSize: '2rem',
  fontWeight: 600,
  color: colors.primary,
  padding: '3rem',
} as React.CSSProperties;

export const dialogContent = {
  fontSize: '1.6rem',
  padding: '0 3rem',
} as React.CSSProperties;

export const dialogActions = {
  padding: '3rem',
} as React.CSSProperties;

export const dialogCancel = {
  fontSize: '1.6rem',
  fontWeight: 600,
  cursor: 'pointer',
  color: colors.primary,
  textTransform: 'uppercase',
  marginRight: '3rem',
} as React.CSSProperties;

export const dialogDelete = {
  fontSize: '1.6rem',
  fontWeight: 600,
  cursor: 'pointer',
  color: colors.error.default,
  textTransform: 'uppercase',
  marginRight: '3rem',
} as React.CSSProperties;

// Some controls settings
export const field = {
  width: '23rem',
  height: '2.5rem',
  borderRadius: '0.6rem',
  fontSize: '1.6rem',
  fontWeight: '600',
  letterSpacing: '0.02rem',
  boxShadow: 'inset -1px 1.1px 1px 0px rgba(4, 90, 139, 0.4)',
  border: 'solid 0.5px #959595',
  padding: '0rem 0.7rem',
};

export const patientSearch = {
  padding: '5rem',
  patientSearchBodyWidth: `
    width: 40rem;
    @media only screen and (max-width: 1280px) {
      width: 35rem;
    }
    @media only screen and (max-width: 960px) {
      width: 30rem;
    }
    @media only screen and (max-width: 760px) {
      width: 30rem;
    }
    @media only screen and (max-width: 600px) {
      width: 20rem;
    }
  `,
  patientSearchTabWidth: `
  width: 50rem;
  @media only screen and (max-width: 1280px) {
    width: 45rem;
  }
  @media only screen and (max-width: 960px) {
    width: 40rem;
  }
  @media only screen and (max-width: 760px) {
    width: 40rem;
  }
  @media only screen and (max-width: 600px) {
    width: 30rem;
  }
`,
  extendedSearchBodyWidth: `
    width: 80rem;
    @media only screen and (max-width: 1280px) {
      width: 70rem;
    }
    @media only screen and (max-width: 960px) {
      width: 60rem;
    }
    @media only screen and (max-width: 760px) {
      width: 60rem;
    }
    @media only screen and (max-width: 600px) {
      width: 40rem;
    }
  `,
  extendedSearchTabWidth: `
  width: 90rem;
  @media only screen and (max-width: 1280px) {
    width: 80rem;
  }
  @media only screen and (max-width: 960px) {
    width: 70rem;
  }
  @media only screen and (max-width: 760px) {
    width: 70rem;
  }
  @media only screen and (max-width: 600px) {
    width: 50rem;
  }
`,
};

export const smallActionButtonDimensions = {
  width: 15,
  height: 3,
  fontSize: 16,
} as const;

export const historyHeader = {
  color: colors.tertiaryText,
} as React.CSSProperties;

export const historyValue = {
  fontWeight: 600,
} as React.CSSProperties;

export const historyValuePadding = '0 3rem 0 0';
