import { Dialog, DialogActions, DialogTitle, Paper, PaperProps } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import ActionButton from '../ActionButton';

import colors from '../../config/theme/colors';
import { Container, Item } from '../Grid';

const DialogPaper = (props: PaperProps): JSX.Element => <Paper square={true} {...props} />;

const StyledCancel = styled.a`
  font-size: 1.6rem;
  font-weight: 600;
  cursor: pointer;
  color: ${colors.primary};
  text-transform: uppercase;
  width: auto;
`;

const ConfirmationDialog = ({
  open,
  text,
  cancel,
  confirm,
  width = 'xs',
  onlyConfirm = false,
  centeredText = false,
}: IConfirmationDialog): JSX.Element => (
  <Dialog open={open} maxWidth={width} fullWidth={true} PaperComponent={DialogPaper}>
    <DialogTitle style={{ padding: '3rem' }}>
      <Container alignItems="center" justifyContent={centeredText ? 'center' : 'flex-start'}>
        <Item style={{ color: colors.tertiaryText }}>{text}</Item>
      </Container>
    </DialogTitle>
    <DialogActions style={{ padding: '0rem 3rem 3rem 3rem' }}>
      <Container alignItems="center">
        {!onlyConfirm && (
          <Item xs={6}>
            <Container justifyContent="center">
              <Item>
                <StyledCancel onClick={cancel?.callback || undefined}>
                  {cancel?.text || <FormattedMessage id="general.cancel" />}
                </StyledCancel>
              </Item>
            </Container>
          </Item>
        )}
        <Item xs={onlyConfirm ? 12 : 6}>
          <Container justifyContent="center">
            <Item>
              <ActionButton
                text={confirm.text || 'general.accept'}
                onClick={confirm.callback || undefined}
                width={15}
                height={4}
                fontSize={16}
                loading={confirm.loading}
              />
            </Item>
          </Container>
        </Item>
      </Container>
    </DialogActions>
  </Dialog>
);

export interface IConfirmationDialog {
  open: boolean;
  text: string | JSX.Element;
  cancel?: {
    callback: (() => void) | null;
    text?: string | JSX.Element; // Locale element or any string
  };
  confirm: {
    callback: (() => void) | ((e: React.MouseEvent<Element, MouseEvent>) => void) | null;
    text?: string; // Locale string
    loading?: boolean;
  };
  width?: 'xs' | 'sm' | 'md' | 'lg';
  onlyConfirm?: boolean;
  centeredText?: boolean;
}

export default ConfirmationDialog;
