import { sortPartialDate } from 'neuro-utils';
import { diagnosisPlatform, LoadableDocConfig } from '../generalSettings';

export const msDocumentTypes: LoadableDocConfig = [
  {
    name: 'diagnosis',
    subTypes: [
      'diagnosis',
      'msSymptoms',
      'msMriAndLaboratoryFindings',
      'msDiagnosticCriteria',
      'nmosdDiagnosticCriteria',
      'msClinicalCourse',
    ],
  },
  { name: 'medication', subTypes: ['medication', 'adverseEffect', 'otherTreatment', 'periodWithoutMedication'] },
  { name: 'neurologicalStatusAndEDSS' },
  { name: 'relapse' },
  { name: 'imaging', subTypes: ['mri'] },
  {
    name: 'inflammatoryDiseaseActivity',
    subTypes: ['inflammatoryDiseaseActivityStart', 'inflammatoryDiseaseActivityMonitoring'],
    secondaryDocs: ['relapse', 'mri'],
  },
  { name: 'cognition', subTypes: ['sdmt'] },
  { name: 'examination' },
  {
    name: 'clinicalstudy',
    subTypes: ['vep', 'sep', 'baep', 'octscan', 'ecg', 'echocardiography', 'bloodPressure', 'heartRate'],
  },
  { name: 'comorbidity', subTypes: ['comorbidity'], title: 'comorbidities' },
  { name: 'therapy' },
  { name: 'background', subTypes: ['background', 'measurement'] },
  { name: 'myService', title: 'msTitle', nonNavigable: true },
];

export const msGraphDocTypes = [
  'diagnosis',
  'medication',
  'adverseEffect',
  'periodWithoutMedication',
  'otherTreatment',
  'neurologicalStatusAndEDSS',
  'mri',
  'sdmt',
  'relapse',
  'msSymptoms',
  'inflammatoryDiseaseActivityStart',
  'inflammatoryDiseaseActivityMonitoring',
  'vep',
  'sep',
  'baep',
  'octscan',
  'ecg',
  'echocardiography',
  'bloodPressure',
  'heartRate',
  'comorbidity',
  'background',
];

// Filter these routes/types from showing in page navigations based on these rules
export const msFilteredTypes = (docs?: document[]): string[] => {
  let latestMSDiagnosis = undefined;
  let idaDocs = [] as document[];
  const msDiagnosisCodes = Object.keys(diagnosisPlatform).filter((d) => {
    const idx = d as TDiagnosis;
    return diagnosisPlatform[idx] === 'ms';
  });

  if (docs && docs.length > 0) {
    // Get all MS diagnoses
    const msDiagnoses = docs.filter((d) => msDiagnosisCodes.includes((d as IDiagnosis).diagnosis || ''));
    msDiagnoses.sort((n1, n2) => sortPartialDate(n1.date, n2.date)).reverse();

    latestMSDiagnosis = msDiagnoses[0]?.diagnosis;
    idaDocs = docs.filter(
      (d) => d._type === 'inflammatoryDiseaseActivityStart' || d._type === 'inflammatoryDiseaseActivityMonitoring',
    );
  }

  return [
    ...(latestMSDiagnosis !== 'G36.0' || idaDocs.length > 0 ? [] : ['inflammatoryDiseaseActivity']), // Filter inflammatoryDiseaseActivity if latest diagnosis is G36.0 and no docs exist
  ];
};
