import { calculatePartialDateDiffenceDuration, isTime, sortPartialDate } from 'neuro-utils';
import { isEmpty } from 'ramda';
import { isParkinsonInfusionType } from 'Routes/Medication/Document/Form/Medication/RegimenDialog/utils';
import { ClinicalArchive } from '.';
import { LoadableDocConfig } from '../generalSettings';

export const parkinsonDocumentTypes: LoadableDocConfig = [
  {
    name: 'diagnosis',
    subTypes: ['diagnosis', 'parkinsonSymptoms', 'pd', 'msa', 'psp', 'dlb', 'cbd', 'ftd', 'ppa'],
  },
  {
    name: 'diagnosticSurvey',
    subTypes: ['npi', 'fbimod', 'bnsq', 'adl', 'iadl'],
    secondaryDocs: ['diagnosis', 'mmse'],
  },
  { name: 'medication', subTypes: ['medication', 'adverseEffect'] },
  { name: 'dbs' },
  { name: 'thalamotomyHifu', subTypes: ['thalamotomy', 'hifu'] },
  {
    name: 'updrs',
    subTypes: ['updrs_iii', 'updrs_v', 'levodopatest', 'dbsEffect', 'behaviourLanguage'],
    secondaryDocs: ['dbs'],
  },
  { name: 'cognition', subTypes: ['moca', 'mmse', 'tmt', 'cdr', 'sdmt'] },
  { name: 'imaging', subTypes: ['mri', 'tt', 'scintigraphy'] },
  { name: 'geneTest' },
  { name: 'examination' },
  { name: 'background', subTypes: ['background', 'measurement'] },
  { name: 'lifestyle' },
] as { name: string; subTypes?: string[]; secondaryDocs?: string[] }[];

export const parkinsonGraphDocTypes = [
  'diagnosis',
  'geneTest',
  'thalamotomy',
  'hifu',
  'updrs_v',
  'updrs_iii',
  'medication',
  'adverseEffect',
  'moca',
  'mmse',
  'tmt',
  'cdr',
  'dbsEffect',
  'dbs',
  'levodopatest',
  'sdmt',
  'mri',
  'tt',
  'scintigraphy',
  'fbimod',
];

// Filter these routes/types from showing in page navigations based on these rules
export const parkinsonFilteredTypes = (docs?: document[]): string[] => {
  let parkDiag = undefined;
  let dbsDocs = [] as document[];
  let parkDiagIndex = -1;
  let filterDbsDiagIndex = -1;

  if (docs && docs.length > 0) {
    parkDiag = docs.find((d) => (d as IDiagnosis).diagnosis === 'G20') as IDiagnosis;
    parkDiagIndex = [...docs]
      .sort((d1, d2) => sortPartialDate(d2.date, d1.date))
      .findIndex((d) => d.diagnosis === 'G20');
    const filterDbsDiagnosis = [
      'G23.1',
      'G90.3',
      'G23.2',
      'G23.3',
      'G31.0',
      'G25.9',
      'F02.8*G31.8',
      'F00.2*G30.8',
      'F02.0*G31.0',
    ];
    filterDbsDiagIndex = [...docs]
      .sort((d1, d2) => sortPartialDate(d2.date, d1.date))
      .findIndex((d) => filterDbsDiagnosis.includes(d.diagnosis));

    dbsDocs = docs.filter((d) => d._type === 'dbs');
  }

  return [
    ...((parkDiag && (parkDiagIndex < filterDbsDiagIndex || filterDbsDiagIndex === -1)) || dbsDocs.length > 0
      ? []
      : ['dbs']), // Include DBS if dbs doc is found OR ( G20 diagnosis is found AND MSA, CBD, DLB, FTLD diagnosis is not set after G20 )
  ];
};

export const parkinsonAlerts = [
  {
    // Notification to the user if the estimated time to replace the DBS generator is less than one year.
    locale: 'alerts.parkinson.dbsOneYear',
    severity: 'warning',
    popup: true,
    rule: (docs: document[]): boolean => {
      // Find first dbs document (docs should be sorted descending by commitDate)
      const dbsDoc = (docs.find((d) => d._type === 'dbs') as IDBS) || {};
      const generatorChangeDate = dbsDoc.generators?.[0]?.generatorNextChangeDate;
      if (!generatorChangeDate) return false;
      const duration = calculatePartialDateDiffenceDuration('days', generatorChangeDate);
      return dbsDoc.status !== 'ended' && Math.abs(duration.days) < 365;
    },
  },
  {
    // Notification if infusion medication documents from integration has unfilled regimen doses or dose times
    locale: 'alerts.parkinson.infusionMedicationUnfilledDose',
    severity: 'info',
    popup: true,
    rule: (docs: document[]): boolean => {
      const medIntegrationDocs = docs.filter((d) => d._type === 'medication' && d._lockedFor) as IMedication[];
      if (medIntegrationDocs.length < 1) return false;
      const medIntInfusionDocs = medIntegrationDocs.filter(
        (m) => m.regimen && m.regimen.some((r) => r.regimenType === 'custom'),
      );
      if (medIntInfusionDocs.length < 1) return false;
      const unfilledDataDoc = medIntInfusionDocs.find(
        (m) =>
          m.regimen &&
          m.regimen.find(
            (r) =>
              r.regimenType === 'custom' &&
              (!r.infusionDoses ||
                (r.infusionDoses &&
                  ((!r.infusionDoses.morningDose && isParkinsonInfusionType(m) === 'gel') ||
                    r.infusionDoses.upkeepDoses?.some(
                      (up) => isEmpty(up) || !up.upkeepDose || !isTime(up.upkeepDoseFrom) || !isTime(up.upkeepDoseTo),
                    )))),
          ),
      );
      if (unfilledDataDoc) return true;
      return false;
    },
  },
];

export const parkinsonClinicalArchiveTypes: readonly ClinicalArchive[] = ['NEU', 'HOI'] as const;
