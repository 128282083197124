import * as React from 'react';
import { styled as muiStyled } from '@mui/material';

const StyledContainer = muiStyled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
});

const SettingsIcon = (): JSX.Element => (
  <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="17.5" cy="17.5" r="17.5" fill="#B9D1DF" />
    <svg y="3" x="3">
      <path
        d="M14.5 2.41663C7.83002 2.41663 2.41669 7.82996 2.41669 14.5C2.41669 21.17 7.83002 26.5833 14.5 26.5833C21.17 26.5833 26.5834 21.17 26.5834 14.5C26.5834 7.82996 21.17 2.41663 14.5 2.41663ZM15.7084 22.9583H13.2917V20.5416H15.7084V22.9583ZM18.2096 13.5937L17.1221 14.7054C16.2521 15.5875 15.7084 16.3125 15.7084 18.125H13.2917V17.5208C13.2917 16.1916 13.8354 14.9833 14.7054 14.1012L16.2038 12.5787C16.6509 12.1437 16.9167 11.5395 16.9167 10.875C16.9167 9.54579 15.8292 8.45829 14.5 8.45829C13.1709 8.45829 12.0834 9.54579 12.0834 10.875H9.66669C9.66669 8.20454 11.8296 6.04163 14.5 6.04163C17.1704 6.04163 19.3334 8.20454 19.3334 10.875C19.3334 11.9383 18.8984 12.905 18.2096 13.5937Z"
        fill="#045A8B"
      />
    </svg>
  </svg>
);

const Settings = (): JSX.Element => {
  // This just to hide the settings icon
  return new Date().getFullYear() < 123 ? (
    <StyledContainer>
      <SettingsIcon />
    </StyledContainer>
  ) : (
    <></>
  );
};

export default Settings;
