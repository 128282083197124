import { LoadableDocConfig } from '../generalSettings';

export const mgravisDocumentTypes: LoadableDocConfig = [
  {
    name: 'diagnosis',
    subTypes: ['diagnosis', 'mgravisSymptoms'],
  },
  { name: 'medication', subTypes: ['medication', 'adverseEffect'] },
  { name: 'functionalPerformanceMgravis', subTypes: ['mgc', 'mgadl', 'passTest'] },
  { name: 'comorbidity', subTypes: ['hospitalization'] },
  { name: 'procedure', subTypes: ['procedure'] },
  { name: 'imaging', subTypes: ['thymusCt', 'thymusMri'] },
  { name: 'clpAndCnpTests', subTypes: ['diagnosticExamination'] },
  { name: 'examination' },
  { name: 'diagnosticTests', subTypes: ['diagnosticExamination'] },
  { name: 'background', subTypes: ['background', 'measurement'] },
];

export const mgravisGraphDocTypes = [
  'diagnosis',
  'mgravisSymptoms',
  'medication',
  'adverseEffect',
  'mgc',
  'mgadl',
  'passTest',
  'background',
  'diagnosticExamination',
  'procedure',
  'hospitalization',
  'thymusCt',
  'thymusMri',
];
