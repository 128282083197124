import type { ActionCreatorWithPreparedPayload, ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { PubSub, PubSubEventTypes, NeuroEventTypes } from 'neuro-event-commons';
import type { RootState, TDispatch } from '../index';
import { makeLog } from 'Utility/logger';
import { parseJWTFromCookie } from 'Utility/jwtAuthTools';
import { LOAD, SUBSCRIBERS_CHANGED, WEBSOCKET_CLOSED, sessionInvalidatedAction } from './index';
import { actions, reduxActions } from 'Store/documents/actions';

export interface IWebSocketManager {
  /**
   * The main starter of the instance. Claim the session ownership and establish
   * a real-time connection with the backend.
   */
  connect(base64_encoded_jwt: string): Promise<void>;

  /**
   * Close the sockets and channels and whatnot and pass them to garbage
   * collecting.
   */
  disconnect(): void;

  /**
   * For example, post something that invalidates older concurrent sessions (in
   * peer browsing contexts of the same browser).
   */
  post_session_orchestration_message(message: any): void;
}

type WebSocketConnectionPolicy = {
  /**
   * The maximum number of times a WebSocket connection shall be attempted to be
   * established.
   */
  max_connection_attempts_count: number;

  /**
   * Maximum time after which a single WebSocket connection attempt is aborted,
   * in milliseconds.
   *
   * _Maximum_ because other mechanisms (such as the underlying web browser
   * platform) may abort the attempt even sooner.
   *
   * Does not account for any event loop queuing time, though!
   */
  connection_attempt_timeout_ms: number;

  /**
   * Minimum delay between two consecutive attempts to establish a WebSocket
   * connection, in milliseconds.
   *
   * _Minimum_ because of the event loop -- there's often a bunch of other work
   * being done "at the same time" in bulky frontend apps!
   */
  connection_reattempt_delay_ms: number;
};

type Hooks = {
  /**
   * Redux state reader.
   */
  get_state: () => RootState;

  /**
   * Redux state update dispatcher.
   */
  dispatch: TDispatch;

  /**
   * Redux state action creator for when a subscription message is received.
   */
  action_creator_get_subscription_message: ActionCreatorWithPreparedPayload<
    [message: PubSub.ISubscriptionSummaryMessage],
    PubSub.ISubscriptionSummaryMessage,
    typeof SUBSCRIBERS_CHANGED
  >;

  /**
   * Redux state action creator for when WebSocket gets closed.
   */
  action_creator_websocket_closed: ActionCreatorWithoutPayload<typeof WEBSOCKET_CLOSED>;
};

/**
 * Duplicate definition. See definitive definitoon in neuro-api@0.19.3 source.
 * Ideally we would somehow import the definition from some common source...
 */
enum CloseReason {
  /** JWT session expired. */
  UserSessionExpired = 4000,
  /** User opened another session while still having an old one. */
  UserSessionDuplicated = 4001,
  /** Server is terminating. */
  ServerTerminating = 4002,
}

/**
 * Maintains WebSocket connection, concurrent sessions orchestrating
 * BroadcastChannel and their associated event listeners and hooks them into
 * Redux state.
 *
 * If you wish to change the implementation, here are some requirements that
 * should be taken into consideration and rely solely on manual testing due to
 * their distributed system nature:
 *
 *  1) Session invalidation by duplication for sessions in peer browsing
 *     contexts (i.e. e.g. tabs of the same browser on the same machine).
 *        If a user duplicates the tab, the older tab should become invalid and
 *     only the new tab should remain for use. Implemented client-side using
 *     Broadcast Channel API.
 *        An invalidated session should not attempt to reconnect WebSocket.
 *
 *  2) Session invalidation by duplication for sessions on different browsers or
 *     machines. Similar to the above case #1, but here Broadcast Channel API is
 *     of no use. Requires session orchestration on server-side.
 *        An invalidated session should not attempt to reconnect WebSocket.
 *
 *  3) Reconnecting WebSocket after graceful server restart. If the WebSocket
 *     backend (Neuro API) is restarted, it closes sockets gracefully and any
 *     clients should try to reconnect to the restarted backend ASAP.
 *
 *  4) Reconnecting WebSocket after some not-graceful catastrophic event. Say
 *     e.g. some network device explodes, the socket gets closed by some chain
 *     of events and the WebSocket connection gets terminated not-cleanly (as
 *     per the WebSocket protocol). In that case the client should try to
 *     reconnect after some time and a few times because networking problems are
 *     often temporary.
 *
 *  5) The UI React app needs to be able to show all kinds of bells and whistles
 *     to the user reflecting all sorts of state changes here. This means that
 *     we need to hook some of the state changes of this system into the Redux
 *     state from which the React app renders itself.
 */
class WebSocketManager implements IWebSocketManager {
  private static _singleton: WebSocketManager;

  /**
   * Channel facilitating orchestration of concurrent sessions. In other words,
   * e.g. browser tabs message each other using this channel to organize their
   * privileges such as the right to hold the single WebSocket connection allowed
   * for a single user at any given time.
   */
  private _session_orchestration_channel: BroadcastChannel | null = null;
  private readonly _session_orchestration_channel_id = 'stellarq_session_orchestration' as const;
  private _socket: WebSocket | null = null;
  private readonly _connection_policy: WebSocketConnectionPolicy;
  /** Event listeners associated with the WebSocket. */
  private readonly _event_listeners_ws = new Map<keyof WebSocketEventMap, any>();
  /** Event listeners associated with the BroadcastChannel. */
  private readonly _event_listeners_bc = new Map<keyof BroadcastChannelEventMap, EventListener>();
  private readonly _ws_api_url: URL;
  private _redux: Hooks;

  constructor(connection_policy: WebSocketConnectionPolicy, redux: Hooks) {
    if (WebSocketManager._singleton) {
      throw new Error('Attempted to re-instantiate singleton WebSocketManager');
    } else {
      WebSocketManager._singleton = this;
    }

    const url = new URL(window.location.toString());
    const protocol: 'ws:' | 'wss:' = url.protocol === 'http:' ? 'ws:' : 'wss:';
    const hostname = url.hostname;
    const port = protocol === 'ws:' ? 25790 : 443; // 25790 in localenv
    const path = '/api/sock';
    this._ws_api_url = new URL(`${protocol}${hostname}:${port}${path}`);
    this._connection_policy = connection_policy;

    this._redux = redux;
  }

  public async connect(base64_encoded_jwt: string): Promise<void> {
    if (this._redux.get_state().session.status.invalidated_at_client_time) {
      return;
    }

    this._session_orchestration_channel = new BroadcastChannel(this._session_orchestration_channel_id);
    const session_orchestration_listener = this.handle_session_orchestration_message.bind(this);
    this._session_orchestration_channel.addEventListener('message', session_orchestration_listener);
    this._event_listeners_bc.set('message', session_orchestration_listener);
    // TODO: Claim the session here (via this.post_session_orchestration_message)
    //       instead of at the Redux-whatever that is triggered at the Redux-managed
    //       JWT session load...
    //          For simplicity, WebSocketManager should probably be detached from
    //       the Redux state as much as possible. There are some required links
    //       though -- for example:
    //          1) Render some "invalidated session view" when the session gets
    //             invalidated by session duplication (BroadcastChannel)
    //          2) Render all sorts of bells and whistles when stuff happen
    //             over or about the WebSocket (messages received, connection closed...)

    if (this._socket !== null) {
      /* The app should do WebSocketManager#disconnect() to close any old
      WebSocket before using WebSocketManager#connect(). Getting here implies
      that the app did not do that. Therefore we log that as an error. */
      this.log_error(new Error('Connecting a WebSocket while there is already a reference to a socket stored'));
      this.disconnect();
    }

    const connection_url = new URL(this._ws_api_url);
    for (const [search_param] of connection_url.searchParams.entries()) {
      connection_url.searchParams.delete(search_param);
    }
    connection_url.searchParams.append('neurojwt', base64_encoded_jwt);

    let socket: WebSocket | null = null;
    let err_ws: Error | null = null;
    for (let attempt_no = 0; attempt_no < this._connection_policy.max_connection_attempts_count; attempt_no++) {
      try {
        socket = new WebSocket(connection_url);
      } catch (err_ws_instantiation) {
        err_ws = <Error>err_ws_instantiation;
        break;
      }

      /* TODO: remove TS assert
         -- Loop is breaked out above if instantiation fails, thus `socket` here
            should always be of type `WebSocket`... */
      const socket_asserted = <WebSocket>socket;

      // wait for the socket to open till some timeout
      let temp_open_cb: (() => unknown) | null = null;
      const socket_state = await new Promise<WebSocket['readyState']>((resolve) => {
        const timeout_id = setTimeout(() => {
          socket_asserted.close(); // abort connection attempt
          return resolve(socket_asserted.readyState);
        }, this._connection_policy.connection_attempt_timeout_ms);
        temp_open_cb = () => {
          clearTimeout(timeout_id);
          resolve(socket_asserted.readyState);
        };
        socket_asserted.addEventListener('open', temp_open_cb);
      });
      if (temp_open_cb !== null) {
        socket.removeEventListener('open', temp_open_cb);
      }
      // 1 = OPEN
      if (socket_state === 1) {
        break;
      }

      // wait a bit before the next attempt
      await new Promise<void>((resolve) => setTimeout(resolve, this._connection_policy.connection_reattempt_delay_ms));
    }

    if (socket === null || err_ws !== null) {
      this.log_error(err_ws);
      return;
    } else if (socket.readyState !== 1) {
      this.log_error(
        new Error(`Failed to establish a WebSocket connection using policy ${JSON.stringify(this._connection_policy)}`),
      );
      return;
    }

    const close_listener = this.handle_close.bind(this);
    socket.addEventListener('close', close_listener);
    this._event_listeners_ws.set('close', close_listener);

    const error_listener = this.handle_error.bind(this);
    socket.addEventListener('error', error_listener);
    this._event_listeners_ws.set('error', error_listener);

    const message_listener = this.handle_message.bind(this);
    socket.addEventListener('message', message_listener);
    this._event_listeners_ws.set('message', message_listener);

    this._socket = socket;
  }

  public disconnect(): void {
    this.close_websocket();
    this.close_broadcastchannel();
    return;
  }

  /** Handle a WebSocket's `close` event. */
  private handle_close(event: CloseEvent): void {
    this.close_broadcastchannel();
    this._redux.dispatch(this._redux.action_creator_websocket_closed());
    for (const [type, listener] of this._event_listeners_ws) {
      if (this._socket === null) {
        break;
      }
      this._socket.removeEventListener(type, listener);
    }
    this._socket = null;

    const jwt = parseJWTFromCookie();
    if (!jwt) {
      return;
    }
    if (!event.wasClean) {
      this.connect(jwt);
    } else {
      switch (event.code) {
        case CloseReason.ServerTerminating: {
          this.connect(jwt);
          break;
        }
        case CloseReason.UserSessionDuplicated: {
          this._redux.dispatch(sessionInvalidatedAction({ type: event.reason }));
          this.log_error(
            new Error(`WebSocket session was invalidated by backend event: code ${CloseReason.UserSessionDuplicated}`),
          );
          break;
        }
        case CloseReason.UserSessionExpired:
        default: {
          // nothing to do here...
          break;
        }
      }
    }
  }

  /** Handle a WebSocket's `error` event. */
  private handle_error(event: Event): void {
    // TODO: when does this happen? what's the event's content like?
    this.log_error(new Error(`Got error event from a WebSocket: ${JSON.stringify(event)}`));
  }

  /** Handle a WebSocket's `message` event. */
  private handle_message(event: MessageEvent): void {
    let message: PubSub.IBaseMessage;
    try {
      message = JSON.parse(event.data);
    } catch (err_parse) {
      this.log_error(new Error('Could not parse message received from WebSocket as JSON', { cause: err_parse }));
      return;
    }

    switch (message?.type) {
      case PubSubEventTypes.HCP_SUBSCRIBED:
      case PubSubEventTypes.HCP_UNSUBSCRIBED: {
        const message_asserted = <PubSub.ISubscriptionSummaryMessage>message;
        this._redux.dispatch(this._redux.action_creator_get_subscription_message(message_asserted));
        break;
      }
      case NeuroEventTypes.ACTION_COMMIT_FINISHED:
      case NeuroEventTypes.ACTION_COMMIT_INITIATED:
      case NeuroEventTypes.ACTION_COMMIT_DISCARDED: {
        const message_asserted = <PubSub.IPatientDocumentMessage>message;
        if (message_asserted.documentType && message_asserted.documentId) {
          actions.updateDocumentFromServer({
            name: message_asserted.documentType,
            id: message_asserted.documentId,
          })(this._redux.dispatch);
        }
        break;
      }
      case NeuroEventTypes.ACTION_DOCUMENT_DELETED: {
        const message_asserted = <PubSub.IPatientDocumentMessage>message;
        if (message_asserted.documentId) {
          this._redux.dispatch(reduxActions.deleteDocumentAction(message_asserted.documentId));
        }
        break;
      }
      case NeuroEventTypes.ACTION_COMMIT_UPDATED:
      case NeuroEventTypes.ACTION_DOCUMENT_CREATED: {
        // nothing to do
        break;
      }
      default: {
        this.log_error(new Error('Got unknown type of message from WebSocket: ' + message?.type));
      }
    }
  }

  /** Managed API for `BroadcastChannel#postMessage`. */
  public post_session_orchestration_message(message: any): void {
    if (this._session_orchestration_channel === null) {
      return;
    } else {
      this._session_orchestration_channel.postMessage(message);
    }
  }

  /** Handle a BroadcastChannel's `message` event. */
  private handle_session_orchestration_message(event: any): void {
    switch (event?.data?.type) {
      /* When a duplicated browsing context notifies that it has loaded the
      cookie-based session, then dispatch invalidation action in the current (old)
      browsing context. */
      case LOAD: {
        this.disconnect();
        this._redux.dispatch(sessionInvalidatedAction(event.data));
        this.log_error(new Error(`WebSocket session was invalidated by local event: code ${LOAD}`));
        break;
      }
      default: {
        this.log_error(new Error(`Got unknown type of event for '${event.target}': ` + event?.data?.type));
        break;
      }
    }
  }

  private log_error(error: unknown): void {
    if (error instanceof Error) {
      makeLog('Error', error);
    } else {
      makeLog(
        'Error',
        new Error(
          `Attempted to log a non-Error error -- .name: '${(<Error>error)?.name}', .message: '${(<Error>error)
            ?.message}'`,
        ),
      );
    }
  }

  /** Close and free resources. */
  private close_websocket(): void {
    if (this._socket === null) {
      return;
    }
    for (const [type, listener] of Object.values(this._event_listeners_ws)) {
      this._socket.removeEventListener(type, listener);
    }

    /* https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState */
    switch (this._socket.readyState) {
      case 0:
      // eslint-disable-next-line no-fallthrough
      case 1: {
        try {
          this._socket.close();
          this._socket = null;
          break;
        } catch (err_ws_close) {
          this.log_error(err_ws_close);
          break;
        }
      }

      case 2:
      // eslint-disable-next-line no-fallthrough
      case 3: {
        this.log_error(
          new Error(
            'Attempted to close a closing or closed WebSocket -- readyState: ' + this._socket.readyState.toString(),
          ),
        );
        break;
      }

      default: {
        this.log_error(
          new Error(
            `Got unknown WebSocket.readyState (typeof ${typeof this._socket.readyState}): '${this._socket.readyState}'`,
          ),
        );
        break;
      }
    }
  }

  /** Close and free resources. */
  private close_broadcastchannel(): void {
    if (this._session_orchestration_channel === null) {
      return;
    } else {
      for (const [event_type, listener_ref] of this._event_listeners_bc) {
        this._session_orchestration_channel.removeEventListener(event_type, listener_ref);
      }
      this._session_orchestration_channel.close(); // pass to gc
      this._session_orchestration_channel = null;
    }
  }
}

export default WebSocketManager;
