import { filter } from 'ramda';
import * as React from 'react';

import colors from '../../../config/theme/colors';
import { field } from '../../../config/theme/componentTheme';
import Tooltip from '../../ToolTip';
import { styled } from '@mui/material';

const StyledDiv = styled('div')`
  width: 100%;
`;

const StyledSelectArea = styled('div')`
  clear: both;
`;

const StyledSelect = styled('div')`
  user-select: none;
  float: left;
  margin: 0.5rem;
  padding: 0.3rem 1.4rem;
  font-size: 1.6rem;
  border-radius: ${field.borderRadius};
  cursor: pointer;
  background-color: ${(props: { selected?: true }): string => (props.selected ? colors.primary : colors.lightestGray)};
  color: ${(props: { selected?: true }): string => (props.selected ? colors.white : colors.primaryText)};
  font-weight: ${(props: { selected?: true }): string => (props.selected ? '600' : 'normal')};
  ${(props: { selected?: true }): string =>
    !props.selected ? 'border: solid 0.5px #959595;' : `border: solid 0.5px ${colors.primary};`}
  &:hover {
    background-color: ${colors.primary};
    color: ${colors.white};
  }
`;

const onChangeValues =
  (opt: string | number, name: string, values: Array<string | number>, onChange?: IInputBasics['onChange']) =>
  (): void => {
    // Need to map these values as they need to be separate and somehow they were inhering from newValues to values
    let newValues = values.map((s) => s);
    if (onChange) {
      if (filter((v: string | number) => opt === v, values).length > 0) {
        newValues = filter((v: string | number) => opt !== v, newValues);
      } else {
        newValues.push(opt);
      }
      onChange({ [name]: newValues });
    }
  };

const MultiSelect = ({
  editing = false,
  name,
  value,
  onChange,
  options,
  optionFormatter,
  width,
  autoSelectSingle = false,
  tooltips,
  style,
}: IInputBasics & IMultiSelect): JSX.Element => {
  const [values, setValues] = React.useState<string[]>(value === '' ? [] : (value as string[]));

  const isMountRender = React.useRef<boolean>(true);

  React.useEffect(() => {
    if (isMountRender.current) {
      // Automatically select the value if only one value, and prop is enabled
      if (editing && autoSelectSingle && (!value || value.length === 0) && options.length === 1) {
        onChangeValues(options[0], name, values, onChange)();
      }

      isMountRender.current = false;
      return;
    }

    setValues(value === '' ? [] : (value as string[]));
  }, [options, value]);

  return !editing ? (
    <StyledDiv style={{ width: `${width ?? 100}%` }}>{`${value}`}</StyledDiv>
  ) : (
    <StyledSelectArea style={{ width: `${width ?? 100}%` }}>
      {options.map((o: string | number, i: number) => (
        <Tooltip
          key={i}
          title={''}
          description={Array.isArray(tooltips) ? tooltips[i]?.message : undefined}
          content={
            <div style={{ display: 'inline-flex' }}>
              <StyledSelect
                key={o}
                style={style}
                onClick={onChangeValues(o, name, values, onChange)}
                selected={filter((v: string) => o === v, values).length > 0 ? true : undefined}
              >
                {optionFormatter ? optionFormatter(o) : o}
              </StyledSelect>
            </div>
          }
          hover={true}
          disabled={!Array.isArray(tooltips) || !tooltips[i] || tooltips[i].index !== i}
        />
      ))}
    </StyledSelectArea>
  );
};

export default MultiSelect;
