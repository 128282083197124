// Main colors of SQ Neuro
const colorPalette = {
  blue: '#045a8b', // Blue // AppBlue
  darkBlue: '#040e51', // Dark blue // AppBlue
  lightBlue: '#96c8d5', // Light blue
  lighterBlue: '#6c96ae', // Lighter blue
  extraLightBlue: '#b9cfda', // Extra light blue
  black: '#222222', // Blackish
  lightestGray: '#f5f5f5', // Lightest gray
  lightGray: '#e8e8e8', // Light gray
  mediumGray: '#b8b8b8', // Medium gray
  gray: '#8d8d8d', // Gray
  darkGray: '#6a6a6a', // Dark gray
  white: '#ffffff', // white
};

const colors = {
  primary: colorPalette.blue,
  secondary: colorPalette.darkBlue,
  tertiary: colorPalette.lightBlue,
  quaternary: colorPalette.lighterBlue,
  quinary: colorPalette.extraLightBlue,

  primaryWarning: '#ba3c3c', // Red
  secondaryWarning: '#cf7272', // Light Red

  error: {
    default: '#c20012', // Red
    lightest: '#ffcbd1', // Extra light red
  },

  primaryText: colorPalette.black, // Blackish
  secondaryText: colorPalette.gray, // Gray
  tertiaryText: colorPalette.darkGray, // Darker gray

  header: colorPalette.darkBlue, // Dark Blue

  brandBlue: {
    dark: colorPalette.darkBlue,
    default: '#2B3870',
  },

  appBlue: {
    // As in UI specs
    default: colorPalette.blue,
    medium: '#5E95B5',
    light: '#9EBFD2',
    lightest: '#B9D1DF',
  },

  info: {
    dark: '#1e274d', // Dark blue
    default: '#2b3870', // Blue
    light: '#7b8bcc', // Light blue
    lightest: '#dee2f2', // Extra light blue
  },

  warning: {
    dark: '#955a02', // Dark orange
    default: '#dd8500', // Orange
    light: '#ff9900', // Light orange
    lightest: '#ffe5bd', // Extra light orange
  },

  success: '#7BA015',

  highlight: {
    light: '#fff2de',
    lightest: '#fdf9f2',
  },

  white: colorPalette.white, // White
  lightestGray: colorPalette.lightestGray, // Light gray
  lightGray: colorPalette.lightGray,
  mediumGray: colorPalette.mediumGray,
  gray: colorPalette.gray,
  darkGray: colorPalette.darkGray, // Darker gray
  black: colorPalette.black, // Blackish

  defaultBackground: '#f5f5f5', // Light gray

  inputPlaceholder: '#a8a8a8',

  dbsPositive: '#f71939', // Red
  dbsNegative: '#222', // Dark gray
  dbsUndefined: '#D3D3D3', // Light Gray

  // Tooltip colors
  tooltipBackground: colorPalette.white,
  tooltipFontColor: colorPalette.darkGray,
  tooltipBorderColor: '#d7d7d7',

  // Trending icon colors
  trendingNegative: '#f71739', // Red
  trendingNegativeBackground: '#f8dce3', // Light red
  trendingNeutral: '#ae6d07', // Orange
  trendingNeutralBackground: '#f7e4b7', // Light orange
  trendingPositive: '#435e01', // Green
  trendingPositiveBackground: '#dfeeb8', // Light green

  // Infobox colors
  infoboxBackground: '#eaf7ff', // Light blue
  infoboxBorder: '#a7d4fe', // Darker blue
  infoboxIcon: '#548ffc', // Other light blue

  graph: {
    primaryColor: colorPalette.lightBlue,
    secondaryColor: colorPalette.blue,

    axis: colorPalette.gray,
    text: colorPalette.gray,
    path: colorPalette.darkBlue,
    point: colorPalette.darkBlue,
    paleBlue: '#f2f3f8',
  },

  myms: {
    primary: '#2D7B83',
    light: '#2D7B83E6', // 90%
    lightest: '#DBE9EA',
  },

  /**
   * Color ranges used is MS statistics modal's charts.
   */
  msStats: {
    district: ['#4366B0', '#A0B5E2', '#D2DFFB'],
    national: ['#27B8C5', '#B9DEE2', '#D1EEF1'],
  },
};

export default colors;
