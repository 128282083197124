import * as React from 'react';
import { styled } from '@mui/system';
import { TrendingDown, TrendingFlat, TrendingUp } from '@mui/icons-material/';
import colors from '../../../config/theme/colors';
import { Container, Item } from 'Components/Grid';

const StyledTrendBox = styled('div', {
  shouldForwardProp: (prop) => prop !== 'trend',
})(({ trend }: { trend: string }) => ({
  height: '3rem',
  textAlign: 'center',
  color: `${colors.primary}`,
  border: `1px ${colors.primary} solid`,
  cursor: 'pointer',
  '&:hover': {
    color:
      trend === 'decreased'
        ? colors.trendingPositive
        : trend === 'same'
          ? colors.trendingNeutral
          : trend === 'increased'
            ? colors.trendingNegative
            : undefined,
    backgroundColor:
      trend === 'decreased'
        ? 'rgb(223, 238, 184, 0.38)'
        : trend === 'same'
          ? 'rgb(247, 228, 183, 0.38)'
          : trend === 'increased'
            ? 'rgb(248, 220, 227, 0.38)'
            : undefined,
  },
}));

const TrendingIcon = ({ trend, isChecked, isInverted }: ITrendingIcon): JSX.Element | null => {
  return trend === 'decreased' ? (
    <TrendingDown
      style={{ color: isChecked ? colors[isInverted ? 'trendingNegative' : 'trendingPositive'] : undefined }}
    />
  ) : trend === 'same' ? (
    <TrendingFlat style={{ color: isChecked ? colors.trendingNeutral : undefined }} />
  ) : trend === 'increased' ? (
    <TrendingUp
      style={{ color: isChecked ? colors[isInverted ? 'trendingPositive' : 'trendingNegative'] : undefined }}
    />
  ) : null;
};

const radioOnChange =
  (name: string, clickedValue: string, currentValue?: string, onChange?: IInputBasics['onChange']) => (): void => {
    const value =
      clickedValue === currentValue
        ? null
        : clickedValue === 'true'
          ? true
          : clickedValue === 'false'
            ? false
            : clickedValue;
    onChange && onChange({ [name]: value });
  };

const RadioTrendRows = ({
  editing = false,
  name,
  onChange,
  value,
  options,
  invert,
}: IInputBasics & IRadioTrendRow): JSX.Element | JSX.Element[] => {
  const trendInverter = (option: string): string => {
    return invert ? (option === 'decreased' ? 'increased' : option === 'increased' ? 'decreased' : option) : option;
  };
  const backgroundColorPicker = (option: string): string | undefined => {
    return value === option
      ? trendInverter(option) === 'decreased'
        ? colors.trendingPositiveBackground
        : trendInverter(option) === 'same'
          ? colors.trendingNeutralBackground
          : trendInverter(option) === 'increased'
            ? colors.trendingNegativeBackground
            : undefined
      : undefined;
  };

  return (
    <Container>
      {options.map((o, i) => (
        <Item key={o}>
          <StyledTrendBox
            trend={trendInverter(o)}
            style={{
              backgroundColor: backgroundColorPicker(o),
              margin: i % 2 ? '0 -0.1rem' : undefined,
              cursor: editing ? undefined : 'default',
            }}
            onClick={editing ? radioOnChange(name, o, value, onChange) : (): string => ''}
          >
            <Container style={{ height: '100%' }} alignItems="center" justifyContent="center">
              <Item style={{ margin: '0.3rem 1rem' }}>
                <TrendingIcon trend={o} isChecked={value === o ? true : false} isInverted={invert} />
              </Item>
            </Container>
          </StyledTrendBox>
        </Item>
      ))}
    </Container>
  );
};

interface ITrendingIcon {
  trend: string;
  isChecked?: boolean;
  isInverted?: boolean;
}

export default RadioTrendRows;
