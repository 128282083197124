import { IDiseaseHistoryData } from 'Components/sq-graphics';
import { calculateFBIMODScore } from 'Routes/DiagnosticSurvey/utils';
import { atcCodesByPlatform } from 'Routes/Medication/Document/config';

const parkinsonData = (docs: Array<IControlProps>, fm: (id: string) => string): IDiseaseHistoryData => {
  const clinicalMeters = fm('graph.clinicalMeters');
  const patientSelfReport = fm('graph.patientSelfReport');

  const filledFbiModDocsFound = docs.some((d) => d._type === 'fbimod' && typeof calculateFBIMODScore(d) === 'number');

  const baseData: IDiseaseHistoryData = {
    graphData: {
      common: {
        ledd: {
          data: [],
          title: 'LEDD',
          scale: {
            type: 'linear',
            id: 'ledd',
          },
        },
        updrsIII: {
          data: [],
          title: 'UPDRS III',
          scale: {
            type: 'linear',
            id: 'updrs',
            linearScale: [0, 108],
          },
          additionalScale: { type: 'linear', id: 'mdsupdrs', linearScale: [0, 132] },
        },
        dbsEffect: {
          data: [],
          title: fm('updrs.dbsEffect'),
          scale: {
            type: 'linear',
            id: 'updrs',
            linearScale: [0, 108],
          },
          additionalScale: { type: 'linear', id: 'mdsupdrs', linearScale: [0, 132] },
        },
        moca: {
          data: [],
          title: 'MOCA',
          scale: {
            type: 'hybridCL',
            id: 'moca',
            linearScale: [0, 30],
            customScale: ['❌'],
          },
        },
        mmse: {
          data: [],
          title: 'MMSE',
          scale: {
            type: 'hybridCL',
            id: 'mmse',
            linearScale: [0, 30],
            customScale: ['❌'],
          },
        },
        tmt: {
          data: [],
          title: 'TMT',
          scale: {
            type: 'hybridLC',
            id: 'tmt',
            linearScale: [0, 240],
            customScale: ['❌'],
          },
        },
        sdmt: {
          data: [],
          title: 'SDMT',
          scale: {
            type: 'linear',
            id: 'sdmt',
            linearScale: [0, 110],
          },
        },
        cdr: {
          data: [],
          title: fm('cognition.cdr.cdr'),
          scale: {
            type: 'custom',
            id: 'cdr',
            customScale: ['0', '0.5', '1', '2', '3'],
          },
        },
        fbiMod: {
          data: [],
          title: 'FBI-Mod',
          scale: {
            type: 'linear',
            id: 'fbiMod',
            linearScale: [0, 66],
          },
        },
      },
      selfReporting: {
        /*pdq8: {
        data: [],
        title: 'PDQ-8',
      },
      pdq39: {
        data: [],
        title: 'PDQ-39',
      },
      '15d': {
        data: [],
        title: '15D',
      },*/
      },
    },
    graphDataGroups: {
      common: {
        groupHeader: clinicalMeters,
        open: true,
      },
      selfReporting: {
        open: false,
        groupHeader: patientSelfReport,
        theme: 'myms',
      },
    },
    addonData: {
      hoehnYahr: {
        id: 'hoehnYahr',
        type: 'single',
        name: 'Hoehn & Yahr',
        addons: [],
      },
      dbs: {
        id: 'dbs',
        type: 'single',
        name: fm('dbs.title'),
        addons: [],
      },
      thalamotomy: {
        id: 'thalamotomy',
        type: 'single',
        name: fm('thalamotomyHifu.thalamotomy.title'),
        addons: [],
      },
      hifu: {
        id: 'hifu',
        type: 'single',
        name: fm('thalamotomyHifu.hifu.title'),
        addons: [],
      },
      medications: {
        id: 'medications',
        type: 'expansionPanel',
        name: fm('medication.title'),
        expansionPanelTitleDescription: [
          {
            title: fm('graph.atc-codes'),
            style: { fontWeight: 600 },
          },
        ].concat(
          atcCodesByPlatform['parkinson'].map((code) => {
            return { title: `${code} - ${fm(`graph.atcOpts.parkinson.${code}`)}` };
          }) as [],
        ),
        addons: [],
        expansionPanelShowAlways: true,
      },
      endedMedications: {
        id: 'endedMedications',
        type: 'expansionPanel',
        name: fm('medication.endedMedications'),
        addons: [],
      },
      studies: {
        id: 'studies',
        type: 'expansionPanel',
        name: fm('graph.studies'),
        addons: [],
      },
      diagnosis: {
        id: 'diagnosis',
        type: 'single',
        name: fm('diagnosis.title'),
        addons: [],
      },
      appointments: {
        id: 'appointments',
        type: 'single',
        name: fm('appointments.appointments'),
        addons: [],
      },
      topdata: {
        id: 'topdata',
        type: 'single',
        name: 'topdata',
        addons: [],
      },
    },
  };
  const returnableData = structuredClone(baseData);
  if (!filledFbiModDocsFound) {
    delete returnableData.graphData.common.fbiMod;
  }
  return returnableData;
};

export default parkinsonData;
