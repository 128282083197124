import { fetchWithOptions } from 'Utility/fetch';
import { getJWTData, parseJWTFromCookie } from 'Utility/jwtAuthTools';
import { makeLog } from 'Utility/logger';

export const fetchOrganizationSettings = async (): Promise<IOrgSettings | null> => {
  const { orgid } = getJWTData() || { orgid: null };
  if (!orgid) return null;
  return fetchWithOptions(`/api/settings/org/${orgid}/neuro`, { neurojwt: parseJWTFromCookie() }, { method: 'GET' })
    .then((res: Response) => {
      if (res.status === 200) {
        return res.json();
      } else {
        throw new Error(`Org settings API server responded with not-ok status ${res.status}`);
      }
    })
    .catch((err: Error) => {
      makeLog('Error', err);
      return null;
    });
};

export const fetchUserSettings = async (): Promise<IUserSettings | null> => {
  const { orgid, useruuid } = getJWTData() || { orgid: null, useruuid: null };
  if (!orgid || !useruuid) return null;
  return fetchWithOptions(`/api/settings/user/${useruuid}/neuro`, { neurojwt: parseJWTFromCookie() }, { method: 'GET' })
    .then((res: Response) => {
      if (res.status === 200) {
        return res.json();
      } else {
        throw new Error(`User settings API server responded with not-ok status ${res.status}`);
      }
    })
    .catch((err: Error) => {
      makeLog('Error', err);
      return null;
    });
};

export const postOrgSettings = async (data: IOrgSettings): Promise<void> => {
  const { orgid } = getJWTData() || { orgid: null };
  if (!orgid) return Promise.resolve();
  return await fetchWithOptions(
    `/api/settings/org/${orgid}/neuro`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'POST', body: JSON.stringify(data) },
  )
    .then((res) => {
      if (res.status !== 200) throw new Error(`User settings API server responded with not-ok status ${res.status}`);
    })
    .catch((err) => {
      makeLog('Error', err, { name: 'Post organization settings', message: 'Post failed' });
    });
};

export const postUserSettings = (data: IUserSettings): Promise<void> => {
  const { orgid, useruuid } = getJWTData() || { orgid: null, useruuid: null };
  if (!orgid || !useruuid) return Promise.resolve();
  return fetchWithOptions(
    `/api/settings/user/${useruuid}/neuro`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'POST', body: JSON.stringify(data) },
  )
    .then((res) => {
      if (res.status !== 200) throw new Error(`User settings API server responded with not-ok status ${res.status}`);
    })
    .catch((err) => {
      makeLog('Error', err, { name: 'Post user settings', message: 'Post failed' });
    });
};

export const fetchUserSettingsVault = async (): Promise<IUserSettings | null> => {
  const { orgid, useruuid } = getJWTData() || { orgid: null, useruuid: null };
  if (!orgid || !useruuid) return null;
  return fetchWithOptions(
    `api/vault/v2/user/`,
    { neurojwt: parseJWTFromCookie(), requestorigin: 'neuro-ui' },
    { method: 'GET' },
  )
    .then((res: Response) => {
      if (res.status === 200) {
        return res.json();
      } else {
        throw new Error(`User settings API server responded with not-ok status ${res.status}`);
      }
    })
    .then((data) => {
      return { uiLanguage: data.uILanguage };
    })
    .catch((err: Error) => {
      makeLog('Error', err);
      return null;
    });
};

export const postUserSettingsVault = async (data: IUserSettings): Promise<boolean | null> => {
  const { orgid, useruuid } = getJWTData() || { orgid: null, useruuid: null };
  if (!orgid || !useruuid) return null;

  const { uiLanguage, ...rest } = data;

  return fetchWithOptions(
    'api/vault/v2/user/',
    { neurojwt: parseJWTFromCookie(), requestorigin: 'neuro-ui' },
    { method: 'PATCH', body: JSON.stringify({ ...rest, uiLang: uiLanguage }) },
  )
    .then((res) => {
      if (res.status === 200) return true;
      else throw 'Failed, status not 200';
    })
    .catch((err) => {
      makeLog('Error', { name: 'User settings patch', message: 'Patching settings failed' }, err);
      return false;
    });
};
