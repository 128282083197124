import { Settings } from 'luxon';
import browserLanguage from 'Utility/browserLanguage';
import { TDispatch } from '..';
import {
  fetchOrganizationSettings,
  fetchUserSettings,
  fetchUserSettingsVault,
  postOrgSettings,
  postUserSettings,
  postUserSettingsVault,
} from './apiFetchers';
import { createAction, createReducer } from '@reduxjs/toolkit';

const LOAD_ORGSETTINGS = 'neuro-ui/settings/LOAD_ORGSETTINGS';
const LOAD_USERSETTINGS = 'neuro-ui/settings/LOAD_USERSETTINGS';
const LOAD_USERSETTINGS_FROM_VAULT = 'neuro-ui/settings/LOAD_USERSETTINGS_FROM_VAULT';
const UPDATE_USERSETTINGS_VAULT = 'neuro-ui/settings/UPDATE_USERSETTINGS_VAULT';

const loadOrgSettingsAction = createAction(LOAD_ORGSETTINGS, (orgSettings: IOrgSettings) => {
  return {
    payload: { orgSettings },
  };
});

const loadUserSettingsAction = createAction(LOAD_USERSETTINGS, (userSettings: IUserSettings) => {
  return {
    payload: { userSettings },
  };
});

const loadUserSettingsFromVaultAction = createAction(LOAD_USERSETTINGS_FROM_VAULT, (userSettings: IUserSettings) => {
  return {
    payload: { userSettings },
  };
});

const updateUserSettingsVaultAction = createAction(UPDATE_USERSETTINGS_VAULT, (userSettings: IUserSettings) => {
  return {
    payload: { userSettings },
  };
});

const loadOrgSettings =
  () =>
  (dispatch: TDispatch): void => {
    fetchOrganizationSettings()
      .then((data) => data && dispatch(loadOrgSettingsAction(data)))
      .catch(() => {
        return;
      });
  };

const loadUserSettings =
  () =>
  (dispatch: TDispatch): void => {
    fetchUserSettings()
      .then((data) => data && dispatch(loadUserSettingsAction(data)))
      .catch(() => {
        return;
      });
    fetchUserSettingsVault()
      .then((data) => data && dispatch(loadUserSettingsFromVaultAction(data)))
      .catch(() => {
        return;
      });
  };

const createOrgSetting = (data: IOrgSettings) => async (): Promise<void> => {
  return await postOrgSettings(data);
};

const createUserSetting = (data: IUserSettings) => async (): Promise<void> => {
  return await postUserSettings(data);
};

const createUserSettingVault =
  (data: IUserSettings) =>
  async (dispatch: TDispatch): Promise<void> => {
    return await postUserSettingsVault(data).then((res) => {
      if (res) dispatch(updateUserSettingsVaultAction(data));
    });
  };

export const actions = {
  loadOrgSettings,
  loadUserSettings,
  createOrgSetting,
  createUserSetting,
  createUserSettingVault,
};

const initialState: ISettingsStore = { orgSettings: {}, userSettings: {} };

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loadOrgSettingsAction, (state, action) => {
      state.orgSettings = action.payload.orgSettings;
    })
    .addCase(loadUserSettingsAction, (state, action) => {
      state.userSettings = { ...state.userSettings, ...action.payload.userSettings };
    })
    .addCase(loadUserSettingsFromVaultAction, (state, action) => {
      const uiLanguage = action.payload.userSettings.uiLanguage || browserLanguage;
      // Set locale for luxon
      Settings.defaultLocale = uiLanguage;
      state.userSettings = { ...state.userSettings, ...action.payload.userSettings };
    })
    .addCase(updateUserSettingsVaultAction, (state, action) => {
      const uiLanguage = action.payload.userSettings.uiLanguage;
      if (uiLanguage) Settings.defaultLocale = uiLanguage;
      state.userSettings = { ...state.userSettings, ...action.payload.userSettings };
    })
    .addDefaultCase((state) => {
      return state;
    });
});

export default reducer;
