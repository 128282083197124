import { styled } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { take } from 'ramda';
import * as React from 'react';

import { RenderValue } from './common/commonComponents';
import { border, dateCell, ellipsis, height, table, titleCell, valueCell } from './common/commonStyles';
import { IData } from './definitions';

const DataTable = styled('table')(table);

const DataRow = styled('tr')({
  height: height,
  maxHeight: height,
});

const DataCellDate = styled('td', { shouldForwardProp: (prop) => prop !== 'topAlign' })(
  ({ theme, topAlign }: { theme?: Theme; topAlign: boolean }) => ({
    ...dateCell(theme),
    borderTop: border(theme),
    verticalAlign: topAlign ? 'top' : undefined,
    paddingTop: topAlign ? '0.6rem' : undefined,
  }),
);

const DataCellTitle = styled('td', {
  shouldForwardProp: (prop) => prop !== 'hasValue' && prop !== 'doubleRow',
})(({ theme, hasValue, doubleRow }: { theme?: Theme; hasValue: boolean; doubleRow: boolean }) => ({
  ...titleCell(theme, true, hasValue),
  ...ellipsis(),
  borderTop: border(theme),
  paddingTop: doubleRow ? '0.6rem' : undefined,
  '& > div': doubleRow
    ? {
        lineHeight: '1.6rem',
        marginBottom: '0.6rem',
        marginTop: '0.2rem',
      }
    : undefined,
}));

const DataCellValue = styled('td', {
  shouldForwardProp: (prop) => prop !== 'doubleRow',
})(({ theme, doubleRow }: { theme?: Theme; doubleRow: boolean }) => ({
  ...valueCell(theme),
  borderTop: border(theme),
  paddingTop: doubleRow ? '0.6rem' : undefined,
  '& > div': doubleRow
    ? {
        lineHeight: '1.6rem',
        marginBottom: '0.6rem',
        marginTop: '0.2rem',
      }
    : undefined,
}));

const SpecificList = ({ data }: ISpecificList) => {
  const itemsCanFitAmount = 6;
  const shownItems = take(itemsCanFitAmount, data);
  const renderValueCol = data.some((d) => d.endDate || d.value);
  return (
    <DataTable>
      <tbody>
        {shownItems.map((d) => {
          const doubleRow: boolean =
            React.isValidElement<{ children: any }>(d.value) &&
            !!(d.value.props && d.value.props.children && React.Children.count(d.value.props.children) > 1);

          return (
            <DataRow key={d.key}>
              <DataCellDate topAlign={doubleRow}>{d.date}</DataCellDate>
              <DataCellTitle hasValue={!!d.value} doubleRow={doubleRow}>
                {d.title}
              </DataCellTitle>
              {renderValueCol && (
                <DataCellValue doubleRow={doubleRow}>
                  <RenderValue value={d.value} endDate={d.endDate} />
                </DataCellValue>
              )}
            </DataRow>
          );
        })}
      </tbody>
    </DataTable>
  );
};

export interface ISpecificList {
  type: 'specificList';
  data: Array<IData>;
}

export default SpecificList;
