import * as React from 'react';
import { Collapse } from '@mui/material';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Container, Item } from '../Grid';
import colors from '../../../src/config/theme/colors';

const StyledButton = styled.a`
  font-size: 1.6rem;
  font-weight: 600;
  cursor: pointer;
  color: ${colors.primary};
  text-transform: uppercase;
`;

const CollapseElem = ({
  children,
  localeIDs,
  amount,
  open,
  openHandler,
  collapseButtonPosition = 'center',
}: IOwnProps): JSX.Element => {
  const [showList, setShowList] = React.useState(false);
  const toggleList = (): void => setShowList(!showList);

  return (
    <React.Fragment>
      <Collapse in={open || showList} timeout={500}>
        {children}
      </Collapse>
      <Container
        style={{
          margin: '2rem 0 1rem 0',
          textAlign: 'center',
          paddingLeft: collapseButtonPosition === 'flex-start' ? '1rem' : undefined,
        }}
        justifyContent={collapseButtonPosition}
      >
        <Item>
          <StyledButton onClick={openHandler || toggleList}>
            {!(open || showList) ? (
              <FormattedMessage id={localeIDs.showMessage} values={{ N: amount }} />
            ) : (
              <FormattedMessage id={localeIDs.hideMessage} />
            )}
          </StyledButton>
        </Item>
      </Container>
    </React.Fragment>
  );
};

interface IOwnProps {
  children: JSX.Element[] | JSX.Element;
  localeIDs: { showMessage: string; hideMessage: string };
  amount?: number;
  open?: boolean;
  openHandler?: () => void;
  collapseButtonPosition?:
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'left'
    | 'right'
    | 'normal'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
    | 'safe center'
    | 'unsafe center'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'revert-layer'
    | 'unset';
}

export default CollapseElem;
