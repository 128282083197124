import { IDiseaseHistoryData } from 'Components/sq-graphics';
import { range } from 'ramda';
import { atcCodesByPlatform } from 'Routes/Medication/Document/config';
import { statusSectionKeys } from './DataConversion/myapp';

const msData = (ageMonths: number | undefined, fm: (id: string) => string): IDiseaseHistoryData => {
  const clinicalMeters = fm('graph.clinicalMeters');
  const patientSelfReport = fm('graph.patientSelfReport');
  return {
    graphData: {
      common: {
        edss: {
          data: [],
          title: 'EDSS',
          scale: {
            id: 'edss',
            type: 'linear',
            linearScale: [0, 10],
          },
        },
        mri: {
          data: [],
          title: fm('graph.mri'),
          scale: {
            id: 'mri',
            type: 'custom',
            customScale: [...range(0, 10).map((n) => n.toString()), '10-20', '21-40'],
          },
        },
        sdmt: {
          data: [],
          title: 'SDMT',
          scale: {
            id: 'sdmt',
            type: 'linear',
            linearScale: [0, 110],
          },
        },
      },
      selfReporting: {
        predss: {
          data: [],
          title: 'PREDSS',
          scale: {
            id: 'predss',
            type: 'linear',
            linearScale: [0, 9],
          },
        },
        status: {
          data: [],
          title: fm('graph.selfReport.status.title'),
          scale: {
            id: 'status',
            type: 'custom',
            customScale: statusSectionKeys.map((s) => fm(`graph.selfReport.status.opts.${s}`)),
          },
        },
        eq5d: {
          data: [],
          title: 'EQ-5D',
          scale: {
            id: 'eq5d',
            type: 'hybridCL',
            linearScale: [0, 1],
            customScale: ['< 0'],
          },
        },
        msis29: {
          data: [],
          title: 'MSIS-29',
          scale: {
            id: 'msis29',
            type: 'linear',
            linearScale: [0, 145],
          },
        },
        fss: {
          data: [],
          title: 'FSS',
          scale: {
            id: 'fss',
            type: 'linear',
            linearScale: [0, 7],
          },
        },
        msnq: {
          data: [],
          title: 'MSNQ',
          scale: {
            id: 'msnq',
            type: 'linear',
            linearScale: [0, 60],
          },
        },
        fsmc: {
          data: [],
          title: 'FSMC',
          scale: {
            id: 'fsmc',
            type: 'linear',
            linearScale: [0, 100],
          },
        },
      },
    },
    graphDataGroups: {
      common: {
        groupHeader: clinicalMeters,
        open: true,
      },
      selfReporting: {
        open: true,
        groupHeader: patientSelfReport,
        theme: 'myms',
      },
    },
    addonData: {
      diagnosis: {
        id: 'diagnosis',
        type: 'single',
        name: fm('graph.diagnosis'),
        addons: [],
      },
      relapse: {
        id: 'relapse',
        type: 'single',
        name: fm('graph.relapseOrEarlySymptom'),
        addons: [],
      },
      diseaseActivity: {
        id: 'diseaseActivity',
        type: 'single',
        name: fm('inflammatoryDiseaseActivity.title'),
        addons: [],
      },
      imaging: {
        id: 'imaging',
        type: 'single',
        name: fm('imaging.title'),
        addons: [],
      },
      medications: {
        id: 'medications',
        type: 'expansionPanel',
        name: fm('graph.medications'),
        expansionPanelTitleDescription: [
          {
            title: fm('graph.atc-codes'),
            style: { fontWeight: 600 },
          },
        ].concat(
          atcCodesByPlatform['ms'].map((code) => {
            return { title: `${code} - ${fm(`graph.atcOpts.ms.${code}`)}` };
          }) as [],
        ),
        addons: [],
        expansionPanelShowAlways: true,
      },
      appointments: {
        id: 'appointments',
        type: 'single',
        name: fm('appointments.appointments'),
        addons: [],
      },
      pregnancies: {
        id: 'pregnancies',
        type: 'single',
        name: fm('graph.pregnancies'),
        addons: [],
      },
      patientSelfReport: {
        id: 'patientSelfReport',
        type: 'single',
        name: fm('graph.patientSelfReport'),
        addons: [],
      },
    },
  };
};

export default msData;
