import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container, Item } from '../../../../components/Grid';

const StyledLabel = styled.div`
  font-weight: 600;
`;

const instruction = (locale: string): JSX.Element => (
  <React.Fragment>
    <Container style={{ marginBottom: '-2.5rem' }}>
      <Item xs={2}>
        <StyledLabel>Instructions related to the question</StyledLabel>
      </Item>
      <Item xs={10} style={{ fontStyle: 'italic', paddingLeft: '1rem' }}>
        <FormattedMessage id={'functionalPerformance.labels.uhdrsFunctionalAssessment.' + locale} />
      </Item>
    </Container>
  </React.Fragment>
);

export const steps = [
  'engageInGainfulEmploymentInAccustomedWork',
  'engageInGainfulEmploymentInAnyWork',
  'engageInVolunteerOrNonGainfulWork',
  'manageFinancesWithoutHelp',
  'shopForGroceriesWithoutHelp',
  'handleMoneyInSimpleTransaction',
  'superviseChildrenWithoutHelp',
  'operateAutomobileSafelyAndIndependently',
  'doOwnHouseworkWithoutHelp',
  'doOwnLaundryWithoutHelp',
  'prepareOwnMealsWithoutHelp',
  'useTelephoneWithoutHelp',
  'takeOwnMedicationsWithoutHelp',
  'feedWithoutHelp',
  'dressWithoutHelp',
  'batheWithoutHelp',
  'usePublicTransportationWithoutHelp',
  'walkToPlacesWithoutHelp',
  'walkWithoutFalling',
  'walkWithoutHelp',
  'combHairWithoutHelp',
  'transferBetweenChairsWithoutHelp',
  'getInAndOutOfBedWithoutHelp',
  'useToiletWithoutHelp',
  'careProvidedAtHome',
];

const fieldsWithNoInstructions = [
  'combHairWithoutHelp',
  'transferBetweenChairsWithoutHelp',
  'getInAndOutOfBedWithoutHelp',
  'useToiletWithoutHelp',
];

export const getStepContent = (
  stepName: string,
): {
  opts: Array<number | string>;
  optsLocale: string;
  info?: string;
  assets?: number[];
  height?: number;
  instruction?: JSX.Element;
} => {
  switch (stepName) {
    case 'operateAutomobileSafelyAndIndependently': {
      return {
        opts: ['N', 0, 1],
        optsLocale: 'uhdrsFunctionalAssessmentOpts',
        instruction: !fieldsWithNoInstructions.includes(stepName) ? instruction(`${stepName}Instruction`) : undefined,
      };
    }
    default: {
      return {
        opts: [0, 1],
        optsLocale: 'uhdrsFunctionalAssessmentOpts',
        instruction: !fieldsWithNoInstructions.includes(stepName) ? instruction(`${stepName}Instruction`) : undefined,
      };
    }
  }
};
